import React from 'react'

import ContentMapper from 'lib/contentMapper'
import PropTypes from 'prop-types'

import HighlightedContentComponent from './component'

const HighlightedContentContainer = ({
  content,
  highlightType,
  id,
  pageComponentIndex,
  title,
}) => {
  const contentMapper = new ContentMapper()
  const contentJsx = contentMapper.mapToComponents(content)

  return (
    <HighlightedContentComponent
      highlightType={highlightType}
      id={id}
      pageComponentIndex={pageComponentIndex}
      title={title}
    >
      {contentJsx}
    </HighlightedContentComponent>
  )
}

HighlightedContentContainer.propTypes = {
  content: PropTypes.array,
  highlightType: PropTypes.string,
  id: PropTypes.string,
  pageComponentIndex: PropTypes.string,
  title: PropTypes.string,
}

HighlightedContentContainer.defaultProps = {
  pageComponentIndex: '0',
}

export default HighlightedContentContainer

import { payloads as localPayloads } from 'lib/api/local'

import { default as appActions } from './actions'
import { APP_SAVING_STATUS } from './constants'

const update = data => async dispatch => {
  try {
    const payload = localPayloads.updating(data)

    dispatch(appActions.updating(payload))
    const payloadSuccess = localPayloads.updatingSuccess(data)

    dispatch(appActions.updatingSuccess(payloadSuccess))
  } catch (errorMessage) {
    console.warn(`Error: App data update failed - ${errorMessage}`)

    const payloadError = localPayloads.updatingError(data, errorMessage)

    dispatch(appActions.updatingError(payloadError))
  }
}

const savingApp = () => update({ appSavingStatus: APP_SAVING_STATUS.SAVING })
const savedApp = () => update({ appSavingStatus: APP_SAVING_STATUS.SAVED })
const unableToSaveApp = () =>
  update({ appSavingStatus: APP_SAVING_STATUS.UNABLE_TO_SAVE })
const failedToSave = () =>
  update({ appSavingStatus: APP_SAVING_STATUS.FAILED_TO_SAVE })
const lock = () => update({ isAppLocked: true })
const unlock = () => update({ isAppLocked: false })

export default {
  failedToSave,
  lock,
  savedApp,
  savingApp,
  unableToSaveApp,
  unlock,
  update,
}

import React from 'react'

const CheckMarkIcon = () => (
  <svg
    data-testid="chechmark-icon"
    height="20px"
    viewBox="0 0 20 20"
    width="20px"
  >
    <g
      fill="none"
      fillRule="evenodd"
      id="MAC_checkmark"
      stroke="none"
      strokeWidth="1"
    >
      <g id="Group-3">
        <circle cx="10" cy="10" fill="#00A3A0" id="Oval" r="10" />
        <polyline
          id="Path-4"
          points="5.8898853 10.8 9.1080056 14.6940752 14.2823429 6.26993208"
          stroke="#FFFFFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.17391304"
        />
      </g>
    </g>
  </svg>
)

export default CheckMarkIcon

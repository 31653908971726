import { NavSaved, NavSaving, NavUnabletoSave } from 'secondstep-components'
import { APP_SAVING_STATUS } from 'store/app/constants'

export const BACK_TEXT = 'Back'
export const SAVED = 'Saved'
export const SAVING = 'Saving...'
export const UNABLE_TO_SAVE = 'Unable to Save'

export const appSavingStatus = [
  APP_SAVING_STATUS.SAVED,
  APP_SAVING_STATUS.SAVING,
  APP_SAVING_STATUS.UNABLE_TO_SAVE,
  APP_SAVING_STATUS.FAILED_TO_SAVE,
]

export const statusLabelMap = {
  [APP_SAVING_STATUS.SAVED]: SAVED,
  [APP_SAVING_STATUS.UNABLE_TO_SAVE]: UNABLE_TO_SAVE,
  [APP_SAVING_STATUS.FAILED_TO_SAVE]: UNABLE_TO_SAVE,
  [APP_SAVING_STATUS.SAVING]: SAVING,
}

export const statusIconMap = {
  [APP_SAVING_STATUS.SAVED]: NavSaved,
  [APP_SAVING_STATUS.UNABLE_TO_SAVE]: NavUnabletoSave,
  [APP_SAVING_STATUS.FAILED_TO_SAVE]: NavUnabletoSave,
  [APP_SAVING_STATUS.SAVING]: NavSaving,
}

import React from 'react'

import PropTypes from 'prop-types'

const LinkIcon = ({ color = '#000', size = '20px' }) => {
  return (
    <svg data-testid="link-icon" height={size} viewBox="0 0 44 44.58">
      <path
        d="M6.09,11.52v27h27V24.57a2.92,2.92,0,0,1,5.82-.46,3.85,3.85,0,0,1,0,.48q0,8.36,0,16.69a2.91,2.91,0,0,1-2.31,3,4.14,4.14,0,0,1-.79.06H3.32a2.9,2.9,0,0,1-3.1-3.1q0-16.23,0-32.45A2.93,2.93,0,0,1,3.37,5.68H20.11a2.93,2.93,0,0,1,3,2.39,2.87,2.87,0,0,1-1.73,3.2,3.73,3.73,0,0,1-1.37.24c-4.45,0-8.89,0-13.33,0Z"
        fill={color}
      />
      <path
        d="M34.22,6h-5.6a2.93,2.93,0,1,1,0-5.85H40.82a2.9,2.9,0,0,1,3,3q0,6.15,0,12.3a2.91,2.91,0,0,1-5.7.82,3.36,3.36,0,0,1-.14-1c0-1.46,0-2.92,0-4.38,0-.08,0-.17,0-.33l-.43.4L22.19,26.54a2.92,2.92,0,0,1-4.54-3.68,5.37,5.37,0,0,1,.51-.56L33.81,6.46Z"
        fill={color}
      />
    </svg>
  )
}

LinkIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}

export default LinkIcon

import { useEffect, useState } from 'react'

const useMediaQuery = query => {
  const [matches, setMatches] = useState(false)
  useEffect(
    () => {
      const mediaQuery = window.matchMedia(query)
      setMatches(mediaQuery.matches)
      const handler = event => setMatches(event.matches)
      mediaQuery.addEventListener('change', handler)
      return () => mediaQuery.removeEventListener('change', handler)
    },
    [], // Empty array ensures effect is only run on mount and unmount
  )
  return matches
}

export const useBreakpoints = () => {
  const breakpoints = {
    isSm: useMediaQuery('(max-width: 768px)'),
  }
  if (breakpoints.isSm) breakpoints.active = 'sm'
  return breakpoints
}

import defaultSvg from 'components/SelaVideoPlayButtonDefault/playButtonDefault.svg'
import playButtonHover from 'components/SelaVideoPlayButtonHover/playButtonHover.svg'
import styled from 'styled-components'

export const VideoWrapper = styled.div.attrs(
  ({ hasMargin, transcriptRendered }) => ({
    margin: hasMargin ? '2.5rem 0' : '0',
    height: transcriptRendered ? '80%' : '100%',
    marginBottom: transcriptRendered ? '0' : '2.5rem',
  }),
)`
  margin: ${({ margin }) => margin};
  width: 100%;
  display: flex;
  flex-direction: row;
  height: ${({ height }) => height};
  ${({ theme }) => theme?.breakpoints?.tablet} {
    margin-bottom: ${({ marginBottom }) => marginBottom};
    height: 100%;
  }
  ${({ theme }) => theme?.breakpoints?.medium} {
    height: 100%;
  }
`

export const VideoContentWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .vjs-big-play-button::before {
    max-height: 3.75rem;
    min-height: 3.75rem;
    max-width: 3.75rem;
    min-width: 3.75rem;
  }

  .vjs-big-play-button {
    max-height: 3.75rem;
    min-height: 3.75rem;
    max-width: 3.75rem;
    min-width: 3.75rem;
  }

  .azuremediaplayer .vjs-tech {
    height: 99%;
  }

  .amp-logo {
    display: none !important;
  }

  .vjs-poster img {
    height: 100%;
    width: 100%;
    border-radius: 0.625rem;
  }

  .azuremediaplayer {
    border-radius: 0.625rem;
  }

  .vjs-tech {
    border-radius: 0.625rem;
  }

  .amp-flush-skin.amp-big-play-centered:hover .vjs-big-play-button,
  .amp-flush-skin.amp-big-play-centered .vjs-big-play-button,
  .amp-flush-skin.amp-big-play-centered .vjs-big-play-button:focus {
    transform: none !important;
  }

  .amp-flush-skin .vjs-big-play-button {
    transition: none !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    margin: auto !important;
  }

  .vjs-big-play-button,
  .vjs-big-play-button:hover::before,
  .vjs-big-play-button::before {
    content: url(${playButtonHover});
  }

  .vjs-big-play-button::before {
    content: url(${defaultSvg});
  }

  .vjs-big-play-button {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    :focus {
      outline: 3px groove ${({ theme }) => theme?.colors?.transcript?.outline};
    }
  }
  .vjs-control-bar {
    border-radius: 0 0 0.625rem 0.625rem;
    bottom: -1px;

    div {
      .vjs-play-control {
        -webkit-transition-property: none;
        -moz-transition-property: none;
        -o-transition-property: none;
        transition-property: none;

        &:hover {
          border-radius: 0.625rem;
        }
      }
    }
    .amp-controlbaricons-right {
      .vjs-fullscreen-control {
        -webkit-transition-property: none;
        -moz-transition-property: none;
        -o-transition-property: none;
        transition-property: none;

        &:hover {
          border-radius: 0.625rem;
        }
      }
    }
  }

  // Fixes floating progress bar
  .amp-flush-skin .vjs-progress-control {
    position: static;
  }

  // Fixes width of progress bar at small breakpoint
  .amp-flush-skin.amp-size-s
    .vjs-control-bar
    .amp-controlbaricons-middle
    .vjs-progress-control {
    padding: 0px;
  }

  // Fixes disappearing play button at small breakpoint
  .amp-flush-skin.amp-size-s
    .vjs-control-bar
    .amp-controlbaricons-left
    .vjs-play-control {
    display: inline-block !important;
  }
`

export const TranscriptOutterWrapper = styled.div.attrs(({ hasMargin }) => ({
  margin: hasMargin ? '2.5rem 0 0' : '0',
}))`
  margin: ${({ margin }) => margin};
  display: block;
  flex-basis: 19.875rem;
  background-color: #e4f3f3;
  height: 38.5rem;
  flex-basis: 40%;
  overflow: scroll;
  border-radius: 5px;
  .description .transcript-line {
    color: ${({ theme }) => theme?.colors?.transcript?.color};
  }
  .transcript {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  ${({ theme }) => theme?.breakpoints?.tablet} {
    margin-top: 1rem;
    max-height: 18.75rem;
  }
  ${({ theme }) => theme?.breakpoints?.medium} {
    max-height: 28.125rem;
  }
`
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  ${({ theme }) => theme?.breakpoints?.tablet} {
    flex-direction: column;
  }
`
export const Spacer = styled.div.attrs(({ transcriptRendered }) => ({
  display: transcriptRendered ? 'flex' : 'none',
}))`
  display: ${({ display }) => display};
  flex-basis: 1rem;
`
export const ToggleWrapper = styled.div.attrs(({ transcriptRendered }) => ({
  bottom: transcriptRendered ? '0' : '2rem',
}))`
  height: 2rem;
  width: 100%;
  display: flex;
  vertical-align: top;
  justify-content: flex-end;
  position: relative;
  padding-top: 0.625rem;
  bottom: ${({ bottom }) => bottom};
  & button {
    margin-left: 0.625rem;
  }
`
export const TranscriptTooltip = styled.span`
  margin-bottom: 4.5px;
  box-sizing: border-box;
  height: 1.938rem;
  width: 9.188rem;
  border: 0.063rem solid #ced8df;
  border-radius: 0.375rem;
  background-color: #ffffff;
  box-shadow: 0 0 0.75rem 0 rgba(0, 0, 0, 0.1);
  color: #555555;
  line-height: 1.6rem;
  font-family: Molde;
  font-size: 1rem;
  letter-spacing: 0;
  text-align: center;
`
export const AudioDescTooltip = styled.span.attrs(({ isAudioDescActive }) => ({
  width: isAudioDescActive ? '18.313rem' : '16.688rem',
}))`
  margin-bottom: 4.5px;
  box-sizing: border-box;
  height: 1.938rem;
  width: ${({ width }) => width};
  border: 0.063rem solid #ced8df;
  border-radius: 0.375rem;
  background-color: #ffffff;
  box-shadow: 0 0 0.75rem 0 rgba(0, 0, 0, 0.1);
  color: #555555;
  line-height: 1.6rem;
  font-family: Molde;
  font-size: 1rem;
  letter-spacing: 0;
  text-align: center;
`

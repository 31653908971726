import React, { useLayoutEffect, useRef, useState } from 'react'

import PropTypes from 'prop-types'

const ContainerDimensions = ({ children, watchResize = false }) => {
  const styles = { width: '100%', height: '100%' }
  const [dimensions, setDimensions] = useState({ width: null, height: null })
  const containerRef = useRef(null)

  useLayoutEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: containerRef.current?.clientWidth,
        height: containerRef.current?.clientHeight,
      })
    }

    // Watch/React to window resizing
    if (watchResize) {
      window.addEventListener('resize', handleResize)
    }

    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const childrenProps = {
    ...dimensions,
    screenWidth: window.screen.width,
    screenHeight: window.screen.height,
  }

  return (
    <div ref={containerRef} style={styles}>
      {children(childrenProps)}
    </div>
  )
}

ContainerDimensions.propTypes = {
  children: PropTypes.node.isRequired,
  watchResize: PropTypes.bool,
}

export default ContainerDimensions

import { TextButton } from 'secondstep-components'
import styled from 'styled-components'

export const StyledTextButton = styled(TextButton).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  color: ${({ theme }) => theme?.colors?.textButton?.color};
  font-family: ${({ theme }) => theme?.fonts?.textButton?.text?.family};
  font-weight: ${({ theme }) => theme?.fonts?.textButton?.text?.weight};
  font-size: inherit;
  letter-spacing: 0rem;
  line-height: 1.375rem;
  max-width: none;
  text-decoration: underline;
  text-transform: none;
  transition: all 0.2s ease-in-out;

  &:focus {
    color: ${({ theme }) => theme?.colors?.textButton?.hoverColor};
  }

  &:hover {
    color: ${({ theme }) => theme?.colors?.textButton?.hoverColor};
  }
`

export const TextButtonWrapper = styled.div``

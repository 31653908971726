import { ENDPOINTS, HTTP_METHODS } from 'lib/api/constants'
import { payloads as lmsPayloads } from 'lib/api/lms'
import { DEFAULT_ERROR } from 'lib/api/lms/constants'
import LmsConnectionsManager from 'lib/LmsConnectionsManager'
import LmsHydrationStatusManager from 'lib/LmsHydrationStatusManager'
import {
  createDiagnostic,
  createErrorFromMessage,
  getFirstErrorDiagnosticFromLmsResponses,
} from 'utils/api'
import { updateCmiData } from 'utils/state'

import { default as cmiActions } from './actions'
import { CMI_KEYS } from './constants'
import { default as cmiTypes } from './types'

// Hydrate redux cmi data from state persisted on LMS.
const hydrate = microLearningEntryId => async (dispatch, getState) => {
  const appState = getState()

  try {
    const fetchingPayloadArgs = {
      data: {},
      microLearningEntryId,
    }
    const payload = lmsPayloads.fetching(fetchingPayloadArgs)

    dispatch(cmiActions.fetching(payload))

    const api = LmsConnectionsManager.getApiConnection(
      microLearningEntryId,
      appState,
    )
    const [
      completionStatusResponse,
      progressMeasureResponse,
    ] = await Promise.all([
      await api.getValue(CMI_KEYS.COMPLETION_STATUS),
      await api.getValue(CMI_KEYS.PROGRESS_MEASURE),
    ])
    const { data: completionStatus } = completionStatusResponse || {}
    const { data: progressMeasure } = progressMeasureResponse || {}
    const firstErrorDiagnostic = getFirstErrorDiagnosticFromLmsResponses([
      completionStatusResponse,
      progressMeasureResponse,
    ])
    // If retrieving any of the cmi values failed, throw the first cmi error received
    const { error: firstError } = firstErrorDiagnostic || {}

    if (firstError) {
      throw firstError
    }

    const microLearningData = {
      completionStatus,
      progressMeasure,
    }
    const fetchingSuccessData = updateCmiData(
      microLearningEntryId,
      microLearningData,
    )
    const fetchingSuccessPayloadArgs = {
      data: fetchingSuccessData,
      microLearningEntryId,
    }
    const payloadSuccess = lmsPayloads.fetchingSuccess(
      fetchingSuccessPayloadArgs,
    )

    dispatch(cmiActions.fetchingSuccess(payloadSuccess))
    LmsHydrationStatusManager.setCmiHydrated(dispatch, microLearningEntryId)
  } catch (error) {
    const fetchingErrorData = updateCmiData(microLearningEntryId, {})
    // Error can be a string (system exception) or an object (server response
    // with a status code and status)
    const isApiError = typeof error === 'object'
    const errorObject = isApiError
      ? error
      : createErrorFromMessage(errorMessage, DEFAULT_ERROR)
    const errorMessage = isApiError ? error?.statusText : error
    const diagnostic = createDiagnostic(
      ENDPOINTS.LMS,
      HTTP_METHODS.GET,
      errorObject,
    )
    const fetchingErrorPayloadArgs = {
      data: fetchingErrorData,
      diagnostic,
      microLearningEntryId,
    }
    const payloadError = lmsPayloads.fetchingError(fetchingErrorPayloadArgs)

    dispatch(cmiActions.fetchingError(payloadError))

    console.warn(`Error: CMI hydration failed - ${errorMessage}`)

    throw errorMessage
  }
}

// Update an individual cmi key, such as completion_status, progress_measure, etc., in redux and LMS.
const update = (microLearningEntryId, data) => dispatch => {
  const updatingWithRetriesPayloadArgs = {
    type: cmiTypes.UPDATING_WITH_RETRIES,
    data,
    microLearningEntryId,
  }
  const updatingWithRetriesPayload = lmsPayloads.updatingWithRetries(
    updatingWithRetriesPayloadArgs,
  )

  dispatch(cmiActions.updatingWithRetries(updatingWithRetriesPayload))
}

export default {
  hydrate,
  update,
}

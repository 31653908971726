const contentful = ({ contentful }) => contentful
const entry = ({ contentful }, entryId) =>
  contentful && contentful[entryId]?.data?.entry
const error = ({ contentful }, entryId) =>
  contentful && contentful[entryId]?.diagnostic?.error
const errors = ({ contentful }, entryIds = []) => {
  return entryIds.reduce((accumulator, entryId) => {
    if (!entryId) {
      return accumulator
    }

    const contentfulError = error({ contentful }, entryId)

    if (contentfulError) {
      accumulator[entryId] = contentfulError
    }

    return accumulator
  }, {})
}

export default {
  contentful,
  entry,
  error,
  errors,
}

import { combineReducers } from 'redux'

import app from './app'
import cmi from './cmi'
import contentful from './contentful'
import interactives from './interactives'

const reducers = {
  app,
  cmi,
  contentful,
  interactives,
}
const rootReducer = combineReducers(reducers)

export default rootReducer

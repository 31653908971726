// Number suffix at end of color name denotes darkness.  1 equals lightest and darkness intensifies as number increases.
const palette = {
  black1: '#393939',
  black2: '#000',
  blue: '#9fd5fd',
  gray1: '#f1f0f5',
  gray2: '#dfdfdf',
  gray3: '#ccc',
  gray4: 'rgba(113, 135, 149, 0.5)',
  gray6: '#555',
  gray7: '#EEF0F2',
  gray8: '#7e92a0',
  gray9: '#EEF0F2',
  purple1: '#6a5f94',
  red1: '#a50021',
  teal1: '#e4f3f3',
  teal2: '#b1dcdd',
  teal3: '#98dad9',
  teal4: '#00a3a0',
  teal5: '#009996',
  teal6: '#007980',
  teal7: '#00B2AF',
  white1: '#FFFFFF',
  accent1: '#6FFFB0',
}

export default palette

import React from 'react'

import { selectors as interactiveSelectors } from 'store/interactives'
import { filterNullish } from 'utils/array'

import { collector as contentCollector } from './'
import {
  AUTOPOPULATE,
  AUTOPOPULATE_TYPES,
  COLLECTION_TYPES,
  CONTENT_TYPES,
  HEADER_BODY_COPY,
  HEADER_BODY_COPY_CONTENT_TYPES,
} from './constants'
import { errorNoComponent } from './data'

// ContentMapper takes a Contentful microlearning and transforms it into React components.
const ContentMapper = () => {
  let collection = contentCollector.resetCollection()
  // Component index is a number that tracks which nth number component this is
  // on the page
  let onComponentIndex = 0

  // Get a component if it is supported, a placeholder if not.
  const getComponentOrPlaceholder = content => {
    let contentType = interactiveSelectors.contentType(content)
    let ContentComponent = CONTENT_TYPES[contentType]

    if (!ContentComponent) {
      const warning = errorNoComponent(contentType)

      console.warn(warning)

      return null
    }

    const isAutoPopulate = contentType === AUTOPOPULATE
    let contentProps = content

    if (isAutoPopulate) {
      const interactiveContent = content?.interactive
      const autoPopulateContentType = interactiveSelectors.contentType(
        interactiveContent,
      )
      contentProps = content?.interactive
      contentProps.sourceMicroLearning = content?.sourceMicrolearning
      ContentComponent = AUTOPOPULATE_TYPES[autoPopulateContentType]
    }

    const { id } = content || {}
    const pageComponentIndex = onComponentIndex.toString()
    contentProps.pageComponentIndex = pageComponentIndex
    contentProps.key = `${id}-${pageComponentIndex}`
    const component = <ContentComponent {...contentProps} />

    return component
  }

  // Handle a "generic" component - one that can be mapped one-to-one from Contentful to a
  // component via contentType property.
  const handleGenericComponent = content => {
    if (!collection.isCollecting) {
      const MappedComponent = getComponentOrPlaceholder(content)

      return [MappedComponent]
    }

    collection.content.push(content)
  }

  const handleCollectionStart = content => {
    const { accordionHeader, highlightHeader, highlightType } = content || {}
    const title = accordionHeader || highlightHeader
    collection = contentCollector.startCollection(title, highlightType)
  }

  const handleCollectionEnd = content => {
    const { content: collectedContent, highlightType, title } = collection || {}
    const { redacted } = content || {}
    const contentProps = {
      ...content,
      content: collectedContent,
      highlightType,
      title,
    }
    const MappedComponent = getComponentOrPlaceholder(contentProps)
    collection = contentCollector.resetCollection()

    // Don't return anything if the collection is redacted
    return redacted ? null : [MappedComponent]
  }

  // headerbodycopy Contentful object can contain up to one of each of h2, h3, and bodytext types.
  const mapHeaderBodyCopyToComponents = content => {
    const { bodyCopy, h2, h3 } = content || {}
    const headerBodyCopyComponents = []
    // Since the Contentful component `HeaderBodyCopy` can come with a payload of at least one of H2, H3 and body copy,
    // we are using the boolean variable incrementIndex to determine if onComponent index should be incremented.
    let incrementIndex = false

    if (h2) {
      const copyContent = Object.assign({}, content)
      delete copyContent.h3
      delete copyContent.bodyCopy
      copyContent.contentType.id = HEADER_BODY_COPY_CONTENT_TYPES.H2
      const component = handleGenericComponent(copyContent)
      headerBodyCopyComponents.push(component)
      incrementIndex = true
    }

    if (h3) {
      const copyContent = Object.assign({}, content)
      onComponentIndex += incrementIndex ? 1 : 0
      delete copyContent.h2
      delete copyContent.bodyCopy
      copyContent.contentType.id = HEADER_BODY_COPY_CONTENT_TYPES.H3
      const component = handleGenericComponent(copyContent)
      headerBodyCopyComponents.push(component)
      incrementIndex = true
    }

    if (bodyCopy) {
      const copyContent = Object.assign({}, content)
      onComponentIndex += incrementIndex ? 1 : 0
      delete copyContent.h2
      delete copyContent.h3
      copyContent.contentType.id = HEADER_BODY_COPY_CONTENT_TYPES.BODY_COPY
      const component = handleGenericComponent(copyContent)
      headerBodyCopyComponents.push(component)
    }

    return headerBodyCopyComponents
  }

  const handleHeaderBodyCopy = content => {
    if (collection.isCollecting) {
      collection.content.push(content)

      return
    }

    const mappedContent = mapHeaderBodyCopyToComponents(content)

    return mappedContent
  }

  // Get the appropriate content component and return it to PageLayout component
  const mapToComponent = content => {
    const contentType = interactiveSelectors.contentType(content)
    const isHeaderBodyCopyGroup = contentType === HEADER_BODY_COPY
    const headerBodyCopySingleTypes = Object.values(
      HEADER_BODY_COPY_CONTENT_TYPES,
    )
    const isHeaderBodyCopySingle = headerBodyCopySingleTypes.includes(
      contentType,
    )
    const isHeaderBodyCopy = isHeaderBodyCopyGroup || isHeaderBodyCopySingle
    const isCollection = COLLECTION_TYPES.includes(contentType)
    const isCollectionStart = isCollection && !collection.isCollecting
    const isCollectionEnd = isCollection && collection.isCollecting
    let contentHandler = handleGenericComponent

    if (isCollectionStart) {
      contentHandler = handleCollectionStart
    }

    if (isCollectionEnd) {
      contentHandler = handleCollectionEnd
    }

    if (isHeaderBodyCopy) {
      contentHandler = handleHeaderBodyCopy
    }

    return contentHandler(content)
  }

  const mapToComponents = contents => {
    if (!contents || !Array.isArray(contents) || contents.length === 0) {
      console.error('Content data missing')

      return []
    }

    const mappedComponents = contents.map(content => {
      onComponentIndex += 1
      const MappedComponent = mapToComponent(content)

      //If gathering a collection, don't return anything until the whole collection is ready.
      if (!collection.isCollecting) {
        return MappedComponent
      }
    })
    const filteredMappedComponents = filterNullish(mappedComponents)

    return filteredMappedComponents
  }

  return {
    mapToComponents,
  }
}

export default ContentMapper

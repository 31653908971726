import React from 'react'

import { useSelector } from 'react-redux'
import { selectors as appSelectors } from 'store/app'
import { selectors as contentfulSelectors } from 'store/contentful/'

import MicrolearningDetailsComponent from './component'

const MicrolearningDetailsContainer = () => {
  const currentUnit = useSelector(appSelectors.currentUnit)
  const currentLocation = useSelector(appSelectors.contentfulId)
  const microLearning = useSelector(state =>
    contentfulSelectors.entry(state, currentLocation),
  )
  const showUnit = currentUnit?.index !== 0 ? currentUnit?.index : null
  return (
    <MicrolearningDetailsComponent
      id={microLearning?.id}
      time={microLearning?.time}
      title={currentUnit?.title}
      unit={showUnit}
    />
  )
}

export default MicrolearningDetailsContainer

import React from 'react'

import { H3 } from 'components/Header/component.styles'
import PropTypes from 'prop-types'

import {
  HighlightContent,
  HighlightWrapper,
  TitleHeightWrapper,
  TitleWrapper,
} from './component.styles'
import { DATA_TEST_ID_PREFIX } from './constants'

const HighlightedContentComponent = ({
  children,
  highlightType,
  id,
  pageComponentIndex,
  title,
}) => {
  const dataTestId = `${DATA_TEST_ID_PREFIX}${pageComponentIndex}-${id}`

  return (
    <HighlightWrapper dataTestId={dataTestId} highlightType={highlightType}>
      <TitleHeightWrapper>
        <TitleWrapper highlightType={highlightType}>
          <H3>{title}</H3>
        </TitleWrapper>
      </TitleHeightWrapper>
      <HighlightContent>{children}</HighlightContent>
    </HighlightWrapper>
  )
}

HighlightedContentComponent.propTypes = {
  children: PropTypes.node,
  highlightType: PropTypes.string,
  id: PropTypes.string,
  pageComponentIndex: PropTypes.string,
  title: PropTypes.string,
}

HighlightedContentComponent.defaultProps = {
  highlightType: '',
  pageComponentIndex: '0',
  title: '',
}

export default HighlightedContentComponent

import { default as appSelectors } from 'store/app/selectors'

import lmsApi from '../api/lms/lmsApi'

// LmsConnectionsManager manages all lmsApi connections needed to display one microlearning.

class LmsConnectionsManager {
  secondaryConnections = {} // secondary apis created using the createApiConnection parent function
  primaryConnection = {} // main parent API_1484_11 api connector

  resetConnections = () => {
    this.secondaryConnections = {}
    this.primaryConnection = {}
  }

  initializeApiConnections = async (
    secondaryMicroLearningIdsAndScos = {},
    callback = () => {},
  ) => {
    // assign the main microlearning connection. Does not require a scoId because that is already part of the parent connection
    this.primaryConnection = await new lmsApi()

    // setup the secondary connections by scoid
    const secondaryScoIds = Object.values(secondaryMicroLearningIdsAndScos)
    const apiClientPromises = secondaryScoIds.map(
      async scoId => await new lmsApi(scoId),
    )
    const initializedConnections = await Promise.all(apiClientPromises)

    secondaryScoIds.forEach(
      (scoId, index) =>
        (this.secondaryConnections[scoId] = initializedConnections[index]),
    )

    callback()
  }

  getApiConnection = (microLearningEntryId, appState = {}) => {
    const primaryMicroLearningId = appSelectors.contentfulId(appState)
    const isPrimaryMicroLearning =
      !microLearningEntryId || microLearningEntryId === primaryMicroLearningId

    if (isPrimaryMicroLearning) {
      return this.primaryConnection
    }

    const autoPopSourceIds = appSelectors.autoPopSourceIds(appState)
    const connectionScoId = autoPopSourceIds?.[microLearningEntryId]
    const apiConnection = this.secondaryConnections[connectionScoId]

    return apiConnection
  }
}

export default new LmsConnectionsManager()

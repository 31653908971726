import env from 'config/build'

export const APP_VERSION = env?.appVersion
export const APP_SAVING_STATUS = {
  SAVED: 'SAVED',
  SAVING: 'SAVING',
  UNABLE_TO_SAVE: 'UNABLE_TO_SAVE',
  FAILED_TO_SAVE: 'FAILED_TO_SAVE',
}

export const APP_MULTIMEDIA_STATUS = {
  ENDED: 'ENDED',
  STOPPED: 'STOPPED',
  PLAYING: 'PLAYING',
}
export const LAYOUT_TYPES = {
  SMALL_GROUP_MEETING: 'small-group',
  LEADER_GROUP_MEETING: 'leader-group',
  INDIVIDUAL: 'individual',
}
export const MEETING_TYPES_LABELS = {
  LEADER_TEAM: 'Leader Team Meeting',
  SMALL_GROUP: 'Small-Group Meeting',
}
export const MICROLEARNING = 'microlearning'
export const MICROLEARNING_TYPES = {
  GROUP: 'Group (shared)',
  INDIVIDUAL: 'individual',
}

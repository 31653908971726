import React from 'react'

import PropTypes from 'prop-types'

import ScorecardComponent from './component'
import { getScoreFooterText } from './data'

const ScorecardContainer = ({
  categories,
  isAutoPop,
  maxPossibleScores,
  pageComponentIndex,
  scorecardIntroText,
  totalAnswered,
  totalQuestions,
}) => {
  const footerText = getScoreFooterText(totalAnswered, totalQuestions)
  const isSingleCategory = Object.keys(categories).length === 1

  return (
    <ScorecardComponent
      categories={categories}
      footerText={footerText}
      introText={scorecardIntroText}
      isAutoPop={isAutoPop}
      isSingleCategory={isSingleCategory}
      maxPossibleScores={maxPossibleScores}
      pageComponentIndex={pageComponentIndex}
      totalAnswered={totalAnswered}
      totalQuestions={totalQuestions}
    />
  )
}

ScorecardContainer.propTypes = {
  categories: PropTypes.object,
  isAutoPop: PropTypes.bool,
  isSingleCategory: PropTypes.bool,
  maxPossibleScores: PropTypes.object,
  pageComponentIndex: PropTypes.string,
  scorecardIntroText: PropTypes.string,
  totalAnswered: PropTypes.number,
  totalQuestions: PropTypes.number,
}

ScorecardContainer.defaultProps = {
  categories: {},
  pageComponentIndex: '0',
  totalQuestions: 0,
}

export default ScorecardContainer

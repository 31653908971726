import { useDispatch } from 'react-redux'
import { operations as appOperations } from 'store/app'

// Use this hook to set the current location of the app. Location
// is represented by a contentful ID of a microlearning.
const useLocation = () => {
  const dispatch = useDispatch()

  const actions = {
    updateLocation(entryId) {
      entryId && dispatch(appOperations.update({ contentfulId: entryId }))
    },
  }

  return actions
}

export default {
  useLocation,
}

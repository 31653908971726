import { getActionData } from 'utils/state'

import { INITIAL_STATE } from '../constants'

import { default as interactiveTypes } from './types'

export default (state = INITIAL_STATE, action) => {
  const { data, diagnostic, type, microLearningEntryId } =
    getActionData(action) || {}
  const microLearningData = data
  const stateMicroLearningData = state[microLearningEntryId]?.data

  switch (type) {
    case interactiveTypes.FETCHING: {
      const optimisticMicroLearningData = microLearningData

      return {
        ...state,
        [microLearningEntryId]: {
          data: {
            ...stateMicroLearningData,
            ...optimisticMicroLearningData,
          },
          diagnostic,
        },
      }
    }
    case interactiveTypes.FETCHING_ERROR: {
      return {
        ...state,
        [microLearningEntryId]: {
          data: {
            ...stateMicroLearningData,
          },
          diagnostic,
        },
      }
    }
    case interactiveTypes.FETCHING_SUCCESS: {
      return {
        ...state,
        [microLearningEntryId]: {
          data: {
            ...microLearningData,
          },
          diagnostic,
        },
      }
    }
    case interactiveTypes.UPDATING: {
      const optimisticMicroLearning = microLearningData

      return {
        ...state,
        [microLearningEntryId]: {
          data: {
            ...stateMicroLearningData,
            ...optimisticMicroLearning,
          },
          diagnostic,
        },
      }
    }
    case interactiveTypes.UPDATING_ERROR: {
      return {
        ...state,
        [microLearningEntryId]: {
          data: {
            ...stateMicroLearningData,
          },
          diagnostic,
        },
      }
    }
    case interactiveTypes.UPDATING_SUCCESS: {
      return {
        ...state,
        [microLearningEntryId]: {
          data: {
            ...stateMicroLearningData,
          },
          diagnostic,
        },
      }
    }
    default:
      return state
  }
}

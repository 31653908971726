import { APP_MULTIMEDIA_STATUS } from 'store/app/constants'
import { default as appOperations } from 'store/app/operations'

import { GET_DEFAULT_PLAYER_SETTINGS } from './data'

const getVttField = module => {
  const { vttFile } = module || {}
  return vttFile
}

const getPauseField = module => {
  const { vttPauseFile } = module || {}
  return vttPauseFile
}

export const getVideoModuleAzureVideoSource = module => {
  const { azureVideoUrl } = module || {}
  return azureVideoUrl
}

export const getVideoModuleVttSource = module => {
  const vttFile = getVttField(module) || []

  if (vttFile.length === 0) {
    return
  }
  const vttSource = vttFile[0].file?.url
  return vttSource
}

export const getVideoModuleVttPauseSource = module => {
  const vttPauseFile = getPauseField(module)
  const pauseSource = vttPauseFile?.file?.url
  return pauseSource
}

export const getSettings = module => {
  const vttPauseSource = getVideoModuleVttPauseSource(module)
  const settings = GET_DEFAULT_PLAYER_SETTINGS()

  if (vttPauseSource) {
    settings.plugins = {
      cfcInteractiveVideo: {
        vtt: vttPauseSource,
      },
    }
  }

  return settings
}

export const onVideoPlay = ({ dispatch, id }) => () => {
  dispatch(
    appOperations.update({
      appMultimediaStatus: {
        [id]: APP_MULTIMEDIA_STATUS.PLAYING,
      },
    }),
  )
}

export const onVideoStopped = ({ dispatch, id }) => () => {
  dispatch(
    appOperations.update({
      appMultimediaStatus: {
        [id]: APP_MULTIMEDIA_STATUS.STOPPED,
      },
    }),
  )
}

export const onVideoEnded = ({ dispatch, id }) => () => {
  dispatch(
    appOperations.update({
      appMultimediaStatus: {
        [id]: APP_MULTIMEDIA_STATUS.ENDED,
      },
    }),
  )
}

export const getCaptionTrackWithSource = (source = '') => {
  let modifiedSource = source
  if (modifiedSource.startsWith('//')) {
    modifiedSource = modifiedSource.slice(2)
    modifiedSource = `https://${modifiedSource}`
  }
  const formattedCaptionObject = {
    kind: 'captions',
    label: 'english',
    src: modifiedSource,
    srclang: 'en-US',
  }
  const holder = []
  holder.push(formattedCaptionObject)
  return holder
}

import React from 'react'

import PropTypes from 'prop-types'

import { DEFAULT_MESSAGE } from './constants'

const ErrorBoundaryComponent = ({
  children,
  disabled,
  error,
  message,
  stackInfo,
}) => {
  if (disabled) {
    return children
  }

  return (
    <div>
      <pre>
        {message}
        <details style={{ whiteSpace: 'pre-wrap' }}>
          {error}
          <br />
          {stackInfo}
        </details>
      </pre>
    </div>
  )
}

ErrorBoundaryComponent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  disabled: PropTypes.bool,
  error: PropTypes.string,
  message: PropTypes.string,
  stackInfo: PropTypes.string,
}

ErrorBoundaryComponent.defaultProps = {
  message: DEFAULT_MESSAGE,
}

export default ErrorBoundaryComponent

import { getTypes, default as types } from 'store/types'

const actionTypeFetching = types?.ACTION_TYPES?.FETCHING
const actionTypeUpdating = types?.ACTION_TYPES?.UPDATING
const actionTypeUpdatingWithRetries = types?.ACTION_TYPES?.UPDATING_WITH_RETRIES
const baseType = types.BASE_TYPES.LMS
const subType = types.SUBTYPES.CMI
const cmiFetchingTypes = getTypes(actionTypeFetching, baseType, subType)
const cmiUpdatingTypes = getTypes(actionTypeUpdating, baseType, subType)
const cmiUpdatingWithRetriesTypes = getTypes(
  actionTypeUpdatingWithRetries,
  baseType,
  subType,
)

export default {
  ...cmiFetchingTypes,
  ...cmiUpdatingTypes,
  ...cmiUpdatingWithRetriesTypes,
}

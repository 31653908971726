import styled from 'styled-components'

export const Wrapper = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  margin-top: 2.5rem;
`

export const Row = styled.div`
  display: flex;

  ${({ theme }) => theme?.breakpoints?.mobile} {
    flex-direction: column;
  }
`

export const Column = styled.div`
  width: 50%;
  &:first-child {
    padding-top: 0.3rem;
  }
  &:last-child {
    margin-left: 1.75rem;
  }

  ${({ theme }) => theme?.breakpoints?.mobile} {
    &:last-child {
      margin-top: 0.8rem;
      margin-left: 0;
    }

    width: 100%;
  }
`

const apiConnectionsInitialized = ({ app }) => app?.apiConnectionsInitialized
const app = ({ app }) => app
const isAppLocked = ({ app }) => app?.isAppLocked
const autoPopSourceIds = ({ app }) => app?.contentfulToScoTree?.autoPopSourceIds
const contentfulId = ({ app }) => app?.contentfulId
const contentfulToScoTree = ({ app }) => app?.contentfulToScoTree
const currentModule = ({ app }) => app?.contentfulToScoTree?.currentModule
const currentUnit = ({ app }) => app?.contentfulToScoTree?.currentUnit
const getAppSavingStatus = ({ app }) => app?.appSavingStatus
const getAppMultimediaStates = ({ app }) =>
  Object.values(app?.appMultimediaStatus || {})
const getMicrolearningType = ({ app }) => app?.microlearningType
const interactivesCompleted = ({ app }) => app?.interactivesCompleted
const isContentfulHydrated = ({ app }) => app?.isContentfulHydrated
const isLmsHydrated = ({ app }) => app?.isLmsHydrated
const nextLesson = ({ app }) => app?.contentfulToScoTree?.nextLesson
const previousLesson = ({ app }) => app?.contentfulToScoTree?.previousLesson
const scoId = ({ app }) => app?.scoId

export default {
  apiConnectionsInitialized,
  app,
  autoPopSourceIds,
  contentfulId,
  contentfulToScoTree,
  currentModule,
  currentUnit,
  getAppSavingStatus,
  getAppMultimediaStates,
  getMicrolearningType,
  interactivesCompleted,
  isAppLocked,
  isContentfulHydrated,
  isLmsHydrated,
  nextLesson,
  previousLesson,
  scoId,
}

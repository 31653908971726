import React from 'react'

import Audio from 'components/Audio'
import Video from 'components/Video'
import PropTypes from 'prop-types'
import { BodyCopy } from 'secondstep-components'

import { Column, Row, Wrapper } from './component.styles'

const MediaBodyCopyComponent = ({
  media,
  bodyCopy,
  pageComponentIndex,
  id,
}) => {
  const { videoRef } = media || {}
  return (
    <Wrapper dataTestId={`media-copy-body-${pageComponentIndex}-${id}`}>
      <Row>
        <Column>
          {videoRef ? (
            <Video {...media} hasMargin={false} />
          ) : (
            <Audio {...media} hasMargin={false} />
          )}
        </Column>

        <Column>
          <BodyCopy>{bodyCopy}</BodyCopy>
        </Column>
      </Row>
    </Wrapper>
  )
}

MediaBodyCopyComponent.defaultProps = {
  bodyCopy: '',
  id: 'media-body-copy',
  pageComponentIndex: '0',
}

MediaBodyCopyComponent.propTypes = {
  bodyCopy: PropTypes.string,
  id: PropTypes.string,
  media: PropTypes.object,
  pageComponentIndex: PropTypes.string,
}

export default MediaBodyCopyComponent

import { operations as cmiOperations } from 'store/cmi'
import { operations as interactivesOperations } from 'store/interactives'

// Hydrate redux for given microLearning ids with persisted cmi data retrieved from the lms
const hydrate = (
  primaryMicroLearningEntryId,
  secondaryMicroLearningEntryIds,
) => async dispatch => {
  console.info(
    `Hydrating lms data for 1 primary microlearning and ${secondaryMicroLearningEntryIds.length} secondary microlearnings...`,
  )

  try {
    // Awaits are used here only in order for errors to be caught and not swallowed.
    // There is no expectation here that one dispatch will wait for earlier steps to
    // fulfill their entire redux cycle from start to finish.

    // Hydrate cmi data (except for suspend_data)
    await dispatch(cmiOperations.hydrate(primaryMicroLearningEntryId))

    // Hydrate the primary microlearning's interactives
    await dispatch(interactivesOperations.hydrate(primaryMicroLearningEntryId))

    // Hydrate secondary microlearnings' interactives
    // Secondary microlearnings contain data that the primary microlearning
    // needs in order to reference saved data from other microlearnings.
    await Promise.all(
      secondaryMicroLearningEntryIds.map(
        async secondaryMicroLearningEntryId => {
          await dispatch(
            interactivesOperations.hydrate(secondaryMicroLearningEntryId),
          )
        },
      ),
    )
  } catch (errorMessage) {
    console.warn(`Error: LMS hydration failed - ${errorMessage}`)
  }
}

export default {
  hydrate,
}

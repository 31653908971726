import styled from 'styled-components'

export const ModalInnerWrapper = styled.div`
  text-align: center;
  margin: 2.5rem 0;

  ${({ theme }) => theme?.breakpoints?.mobile} {
    margin: 1.75rem 0;
  }
`

export const ModalHeader = styled.div`
  font-family: ${({ theme }) => theme?.fonts?.modal?.header?.family};
  font-weight: ${({ theme }) => theme?.fonts?.modal?.header?.weight};
  text-align: center;
  letter-spacing: 0.016rem;
  margin: 0;
  font-size: 1.25rem;
  ${({ theme }) => theme?.breakpoints?.mobile} {
    font-size: 1.125rem;
  }
`
export const ModalBodyCopy = styled.div`
  margin-top: 1.75rem;
  text-align: center;
  font-size: 1.125rem;
  ${({ theme }) => theme?.breakpoints?.mobile} {
    font-size: 1rem;
  }
`
export const ModalRefreshButton = styled.div`
  font-family: ${({ theme }) => theme?.fonts?.modal?.refreshButton?.family};
  font-weight: ${({ theme }) => theme?.fonts?.modal?.refreshButton?.weight};
  text-align: center;
  letter-spacing: 0.031rem;
  text-align: center;
  margin-top: 1.75rem;
  cursor: pointer;
  align-items: center;
  display: inline-flex;
  color: ${({ theme }) => theme?.colors?.modal?.modalButton?.color};
  padding: 0.188rem 0.625rem;
  border-radius: 1.344rem;
  background-color: ${({ theme }) =>
    theme?.colors?.modal?.modalButton?.background};
`

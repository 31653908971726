import React from 'react'

import RatioWrapper from 'components/RatioWrapper'
import { Audio } from 'secondstep-components'

import { AudioContentWrapper, AudioWrapper } from './component.styles'
import { DATA_TEST_ID_PREFIX } from './constants'

const AudioComponent = props => {
  const {
    streamingLocator,
    tracks,
    settings,
    poster,
    hasAudioSource,
    hasMargin,
    onEnded,
    onPlay,
    onStopped,
    pageComponentIndex,
    id,
  } = props || {}

  const dataTestId = pageComponentIndex
    ? `${DATA_TEST_ID_PREFIX}${pageComponentIndex}-${id}`
    : `${DATA_TEST_ID_PREFIX}${id}`

  const audio = hasAudioSource ? (
    <Audio
      dataTestId={dataTestId}
      onEnded={onEnded}
      onPlay={onPlay}
      onStopped={onStopped}
      poster={poster}
      settings={settings}
      streamingLocator={streamingLocator}
      tracks={tracks}
    />
  ) : null

  return (
    <AudioWrapper hasMargin={hasMargin}>
      <RatioWrapper>
        <AudioContentWrapper>{audio}</AudioContentWrapper>
      </RatioWrapper>
    </AudioWrapper>
  )
}

AudioComponent.propTypes = {}

export default AudioComponent

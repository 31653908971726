import { getActionData, transformAppReducerData } from 'utils/state'

import { INITIAL_STATE } from '../constants'

import { APP_VERSION } from './constants'
import { default as appTypes } from './types'

export default (state = INITIAL_STATE, action) => {
  const { data, type } = getActionData(action) || {}

  switch (type) {
    case appTypes.UPDATING: {
      const optimisticData = transformAppReducerData(state, data)

      return {
        ...state,
        ...optimisticData,
        version: APP_VERSION,
      }
    }
    case appTypes.UPDATING_ERROR: {
      return state
    }
    case appTypes.UPDATING_SUCCESS: {
      const transformedData = transformAppReducerData(state, data)

      return {
        ...state,
        ...transformedData,
        version: APP_VERSION,
      }
    }
    default:
      return state
  }
}

import React, { useEffect } from 'react'

import { MAXLENGTH } from 'components/TextEntry/constants'
import { default as interactiveCompletedHooks } from 'hooks/useInteractiveComplete'
import { default as selectHooks } from 'hooks/useSelect'
import { default as textResponseHooks } from 'hooks/useTextResponse'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { selectors as appSelectors } from 'store/app'
import { selectors as interactiveSelectors } from 'store/interactives'
import { multiSelectSelectors } from 'store/interactives/selectors'

import MultiSelectQuestionComponent from './component'
import { CONTENT_TYPE } from './constants'

const MultiSelectQuestionContainer = ({
  answerChoices,
  contentType,
  id,
  questionText,
  restrictSelection: maxNumOfSelections,
  pageComponentIndex,
  sourceMicroLearning,
}) => {
  const interactiveType = interactiveSelectors.contentType({ contentType })
  const primaryMicroLearningId = useSelector(appSelectors.contentfulId)
  const microLearningId = sourceMicroLearning || primaryMicroLearningId
  const selections = useSelector(
    state => multiSelectSelectors.selections(state, microLearningId, id) || [],
  )
  const savedResponse = useSelector(
    state => multiSelectSelectors.response(state, microLearningId, id) || '',
  )
  const { updateSelections } = selectHooks.useSelect(
    interactiveType,
    microLearningId,
    id,
  )
  const { response, updateTextResponse } = textResponseHooks.useTextResponse(
    interactiveType,
    microLearningId,
    id,
  )
  const {
    setInteractivesCompleted,
  } = interactiveCompletedHooks.useInteractiveComplete()
  const formattedAnswers = answerChoices.map(choice => ({
    answerText: choice.answerText,
    hasTextField: choice.textField || false,
    id: choice.id,
  }))

  useEffect(() => {
    setInteractivesCompleted(id, selections?.length)
  }, [])

  useEffect(() => {
    if (savedResponse) {
      updateTextResponse(savedResponse)
    }
  }, [savedResponse])

  const onCheck = e => {
    const isMaxSelected = selections
      ? maxNumOfSelections && selections.length >= maxNumOfSelections
      : false
    const { target } = e || {}
    const { checked: isChecked, value: userSelection } = target || {}

    if (isChecked && isMaxSelected) {
      return
    }

    const newSelections = isChecked
      ? [...selections, userSelection]
      : selections.filter(answer => answer !== userSelection)

    updateSelections(newSelections)
    setInteractivesCompleted(id, newSelections.length)
  }

  const onTextChange = e => {
    const inputResponse = e.target.value

    updateTextResponse(inputResponse)
  }

  const handleShowAlertStatus = response.length === MAXLENGTH
  const handleShowMultiSelectTextAreaStatus = response.length > MAXLENGTH - 20

  return (
    <MultiSelectQuestionComponent
      answers={formattedAnswers}
      checkedAnswers={selections}
      handleChange={onTextChange}
      handleCheck={onCheck}
      id={id}
      pageComponentIndex={pageComponentIndex}
      questionText={questionText}
      response={response}
      showMultiSelectTextAreaStatus={handleShowMultiSelectTextAreaStatus}
      showStatusAlert={handleShowAlertStatus}
    />
  )
}

MultiSelectQuestionContainer.propTypes = {
  answerChoices: PropTypes.array,
  contentType: PropTypes.object,
  id: PropTypes.string,
  pageComponentIndex: PropTypes.string,
  questionText: PropTypes.string,
  restrictSelection: PropTypes.number,
  sourceMicroLearning: PropTypes.string,
}

MultiSelectQuestionContainer.defaultProps = {
  answerChoices: [],
  contentType: CONTENT_TYPE,
}

export default MultiSelectQuestionContainer

export const GET_CAPTION_TRACK_WITH_SOURCE = (source = '') => ({
  kind: 'captions',
  label: 'English',
  src: source,
  srclang: 'en',
})
export const GET_DEFAULT_PLAYER_SETTINGS = () => ({
  controls: true,
  height: '100%',
  width: '100%',
})

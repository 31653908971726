import React from 'react'

import { useSelector } from 'react-redux'
import { selectors as appSelectors } from 'store/app'
import { LAYOUT_TYPES } from 'store/app/constants'

import PullQuote from './component'

const PullQuoteContainer = props => {
  const microlearningType = useSelector(appSelectors.getMicrolearningType)
  let gradient
  if (microlearningType === LAYOUT_TYPES.SMALL_GROUP_MEETING) {
    gradient = 'gradient-small'
  }

  if (microlearningType === LAYOUT_TYPES.LEADER_GROUP_MEETING) {
    gradient = 'gradient-leader'
  }

  return <PullQuote {...props} gradient={gradient} />
}

export default PullQuoteContainer

import { GET_DEFAULT_PLAYER_SETTINGS } from './data'

const getPosterField = module => {
  const { posterImage } = module || {}

  return posterImage
}

const getAudioModuleAzureVideoSource = module => {
  const { locator } = module || {}

  return locator
}

export const getAudioModulePosterSource = module => {
  const posterImage = getPosterField(module)
  const posterSource = posterImage?.file?.url

  return posterSource
}

export const getAudioModuleVttSource = module => {
  const { vttFile } = module || {}
  const { file } = vttFile || {}
  const vttSource = file?.url
  return vttSource
}

export const getSettings = () => {
  const settings = GET_DEFAULT_PLAYER_SETTINGS()

  return settings
}

export const hasAudioSource = module => {
  const source = getAudioModuleAzureVideoSource(module)

  return source != null
}

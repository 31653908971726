import { ENDPOINTS, HTTP_METHODS } from 'lib/api/constants'
import { createDiagnostic } from 'utils/api'

import { DEFAULT_ERROR } from './constants'

const fetching = ({
  data = {},
  diagnostic = createDiagnostic(ENDPOINTS.LMS, HTTP_METHODS.GET),
  microLearningEntryId,
}) => ({
  data,
  diagnostic,
  microLearningEntryId,
})

const fetchingSuccess = ({
  data = {},
  diagnostic = createDiagnostic(ENDPOINTS.LMS, HTTP_METHODS.GET),
  microLearningEntryId,
}) => ({
  data,
  diagnostic,
  microLearningEntryId,
})

const fetchingError = ({
  data = {},
  diagnostic = createDiagnostic(ENDPOINTS.LMS, HTTP_METHODS.GET, DEFAULT_ERROR),
  microLearningEntryId,
}) => ({ data, diagnostic, microLearningEntryId })

const updating = ({
  data = {},
  diagnostic = createDiagnostic(ENDPOINTS.LMS, HTTP_METHODS.POST),
  microLearningEntryId,
}) => ({
  data,
  diagnostic,
  microLearningEntryId,
})

const updatingSuccess = ({
  data = {},
  diagnostic = createDiagnostic(ENDPOINTS.LMS, HTTP_METHODS.POST),
  microLearningEntryId,
}) => ({
  data,
  diagnostic,
  microLearningEntryId,
})

const updatingError = ({
  data = {},
  diagnostic = createDiagnostic(
    ENDPOINTS.LMS,
    HTTP_METHODS.POST,
    DEFAULT_ERROR,
  ),
  microLearningEntryId,
}) => ({
  microLearningEntryId,
  data,
  diagnostic,
})

//withRetries payloads are payloads that go through the full lifecycle and checks. (queued, retried, connected to visual saving status, etc)
const updatingWithRetries = ({
  data = {},
  diagnostic = createDiagnostic(ENDPOINTS.LMS, HTTP_METHODS.POST),
  interactiveId,
  microLearningEntryId,
}) => ({
  microLearningEntryId,
  data,
  diagnostic,
  interactiveId,
})

const updatingWithRetriesSuccess = ({
  data = {},
  diagnostic = createDiagnostic(ENDPOINTS.LMS, HTTP_METHODS.POST),
  interactiveId,
  microLearningEntryId,
}) => ({
  interactiveId,
  microLearningEntryId,
  data,
  diagnostic,
})

const updatingWithRetriesError = ({
  data = {},
  diagnostic = createDiagnostic(
    ENDPOINTS.LMS,
    HTTP_METHODS.POST,
    DEFAULT_ERROR,
  ),
  interactiveId,
  microLearningEntryId,
}) => ({ data, diagnostic, interactiveId, microLearningEntryId })

export default {
  fetching,
  fetchingSuccess,
  fetchingError,
  updating,
  updatingSuccess,
  updatingError,
  updatingWithRetries,
  updatingWithRetriesSuccess,
  updatingWithRetriesError,
}

import { Markdown } from 'grommet'
import styled from 'styled-components'

export const Callout = styled.div.attrs(({ theme }) => ({
  background: theme?.colors?.survey?.calloutOuter || '#e4f3f3',
  pad: { bottom: '1.75rem', horizontal: '1.75rem', top: '2.5rem' },
}))`
  background-color: ${({ background }) => background};
  border-radius: 0.688rem;
  margin: 1.75rem 0;
  padding: 2.5rem 1.75rem;

  ${({ theme }) => theme?.breakpoints?.mobile} {
    margin: 1.25rem 0;
    padding: 1.75rem 1.25rem;
  }
`

export const Header = styled.h4.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  font-family: ${({ theme }) => theme?.fonts?.survey?.header?.family};
  font-weight: ${({ theme }) => theme?.fonts?.survey?.header?.weight};
  font-size: 1.25rem;
  margin: 0;

  ${({ theme }) => theme?.breakpoints?.mobile} {
    font-size: 1.125rem;
  }
`

export const Question = styled.div.attrs(({ theme }) => ({
  background: theme?.colors?.survey?.calloutInner || '#fff',
}))`
  background-color: ${({ background }) => background};
  border-radius: 0.688rem;
  display: flex;
  flex-direction: column;
  margin: 1.75rem 0;
  padding: 0 1.75rem;

  ${({ theme }) => theme?.breakpoints?.mobile} {
    margin: 1.25rem 0;
    padding: 0 1.25rem;
  }
`

export const SurveyHorizRule = styled.hr`
  border: none;
  border-bottom: 0.125rem solid ${({ theme }) => theme?.colors?.survey?.hr};
  margin: 0.3125rem 0;
`

export const Text = styled(Markdown).attrs(({ datatestid }) => ({
  'data-testid': datatestid,
}))``

export const Wrapper = styled.div`
  margin-bottom: 2.5rem;
`

import { useDispatch } from 'react-redux'
import { operations as cmiOperations } from 'store/cmi'

// Use this hook to update micro learning completion status and persist to LMS.
const useCompletionStatus = microLearningEntryId => {
  const dispatch = useDispatch()
  const actions = {
    updateCompletionStatus(completionStatus) {
      dispatch(cmiOperations.update(microLearningEntryId, { completionStatus }))
    },
  }

  return actions
}

export default {
  useCompletionStatus,
}

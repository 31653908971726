import React from 'react'

import PropTypes from 'prop-types'

import { Body, Wrapper } from './component.styles'
import { DATA_TEST_ID_PREFIX } from './constants'

const BodyCopyComponent = ({ bodyCopy, id, pageComponentIndex }) => {
  const dataTestId = `${DATA_TEST_ID_PREFIX}${pageComponentIndex}-${id}`

  const formatBodyCopy = bodyCopy => {
    if (bodyCopy.includes('\n<br>')) {
      // this combo causes some markdown elements to render incorrectly within grommet:
      return bodyCopy.replaceAll('\n<br>', '\n')
    }
    return bodyCopy
  }

  return (
    <Wrapper dataTestId={dataTestId} key={dataTestId}>
      {bodyCopy && <Body>{formatBodyCopy(bodyCopy)}</Body>}
    </Wrapper>
  )
}

BodyCopyComponent.propTypes = {
  bodyCopy: PropTypes.string,
  id: PropTypes.string,
  pageComponentIndex: PropTypes.string,
}

export default BodyCopyComponent

import React from 'react'

import ErrorBoundary from 'components/ErrorBoundary'
import Env from 'config/build'
import { FlagsProvider, FlagsSingleton } from 'flags'
import { Grommet } from 'grommet'
import theme from 'lib/theme'
import { Logger } from 'logging'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { initAmpAndElmo } from 'secondstepVideo'
import store, { history } from 'store'
import { ThemeProvider } from 'styled-components'

import flagsAppLevelSubscribes from '../src/config/flagsAppLevelSubscribes'
import flagsLocalDevFlagOverride from '../src/config/flagsLocalDevFlagOverride'
import './index.css'

// import 'react-hot-loader/patch'
// import { AppContainer } from 'react-hot-loader'

import App from './App'
import { APP_VERSION, NODE_ENV } from './config/constants'

console.info(`Running app version ${APP_VERSION} in ${NODE_ENV}`)

Logger.init(history)

initAmpAndElmo()

const render = async Component => {
  const { user, clientSideID } = Env.ldCreds
  FlagsSingleton.initialize({
    clientOptions: {
      user,
      options: {},
      clientID: clientSideID,
    },
    appLevelSubscribedFlags: flagsAppLevelSubscribes,
    localDevFlagOverride: flagsLocalDevFlagOverride,
  })

  if (window.__GOOGLE_ANALYTICS_FILTER_PROPERTIES__) {
    const currentPage = `/player/${window.__GOOGLE_ANALYTICS_FILTER_PROPERTIES__.title}`
    Logger.logPageView(currentPage)
  }

  await FlagsSingleton.waitForFlagSystemReady()

  if (window.parent.__CFC_PLAYER_DATA__?.learner) {
    const updatedUserConfigForLaunchDarkly = {
      userEmail: window.parent.__CFC_PLAYER_DATA__.learner,
      roles: ['Teacher'],
    }
    await FlagsSingleton.updateUserContext(updatedUserConfigForLaunchDarkly)
  }

  ReactDOM.render(
    // <AppContainer> must only wrap a single React component.
    // <AppContainer>
    <FlagsProvider softReload={true}>
      <Provider store={store}>
        <React.StrictMode>
          <Grommet plain={true} theme={theme}>
            <ThemeProvider theme={theme}>
              <ErrorBoundary>
                <Component />
              </ErrorBoundary>
            </ThemeProvider>
          </Grommet>
        </React.StrictMode>
      </Provider>
    </FlagsProvider>,
    // </AppContainer>,
    document.getElementById('root'),
  )
}

// Production render
render(App)

// TODO: Hot reload currently disabled in order to troubleshoot issues with redux in staging.
// Dev render
// if (module.hot && NODE_ENV === 'development') {
//   console.info('Using hotloader...')
//
//   module.hot.accept('./App.jsx', () => {
//     const NextApp = require('./App').default
//     render(NextApp)
//   })
// } else {
console.info('Not using hotloader.')
// }

import { NavArrow } from 'secondstep-components'
import styled from 'styled-components'

export const NavHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) =>
    theme?.colors?.navHeader?.navHeaderWrapper?.background};
  height: 3.125rem;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  border-bottom: 2px solid
    ${({ theme }) => theme?.colors?.navHeader?.borderBottom};
`

export const BackButton = styled.button.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 5.938rem;

  // TODO: This gets overridden somewhere to #ffffff
  background-color: ${({ theme }) => theme?.colors?.navHeader?.background};
  border: none;
  font-family: ${({ theme }) => theme?.fonts?.heading?.level[2]?.family};
  font-weight: ${({ theme }) => theme?.fonts?.heading?.level[2]?.weight};
  font-size: 16px;
  color: ${({ theme }) => theme?.colors?.navHeader?.color};
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme?.colors?.navHeader?.color};
    color: ${({ theme }) => theme?.colors?.navHeader?.background};
    polyline {
      stroke: ${({ theme }) => theme?.colors?.navHeader?.hover?.polyline};
    }
  }

  :disabled {
    cursor: initial;
    background-color: ${({ theme }) => theme?.colors?.disabledButtons?.color};
    color: ${({ theme }) => theme?.colors?.disabledButtons?.primaryBackground};
    polyline {
      stroke: ${({ theme }) =>
        theme?.colors?.disabledButtons?.primaryBackground};
    }
  }

  ${({ theme }) => theme?.breakpoints?.mobile} {
    width: 3rem;
  }
`

export const StyledNavArrow = styled(NavArrow).attrs(({ theme }) => ({
  strokeColor: theme?.colors?.navHeader?.color,
}))``

export const BackText = styled.div`
  margin-left: 0.4rem;

  ${({ theme }) => theme?.breakpoints?.mobile} {
    display: none;
  }
`

export const MlNumber = styled.div`
  color: ${({ theme }) => theme?.colors?.page?.color};
  margin-left: 1.25rem;
  font-size: 18px;
  font-family: ${({ theme }) => theme?.fonts?.scoreCard?.header?.family};
  font-weight: ${({ theme }) => theme?.fonts?.scoreCard?.header?.weight};
  line-height: 2.188rem;
  letter-spacing: 0.016rem;
  display: inline-block;

  ${({ theme }) => theme?.breakpoints?.mobile} {
    display: none;
  }
`

export const MlTitle = styled.div`
  color: ${({ theme }) => theme?.colors?.page?.color};
  margin-left: 0.25rem;
  font-size: 18px;
  font-family: ${({ theme }) => theme?.fonts?.navHeader?.displayTitleFont};
  line-height: 2.188rem;
  letter-spacing: 0.016rem;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ theme }) => theme?.breakpoints?.mobile} {
    margin-left: 1.25rem;
    font-family: ${({ theme }) => theme?.fonts?.scoreCard?.header?.family};
    font-weight: ${({ theme }) => theme?.fonts?.scoreCard?.header?.weight};
  }
`

export const StyledStatusLabel = styled.div`
  margin-right: 1.3rem;
  font-style: italic;
  font-size: 18px;
  color: ${({ theme }) => theme?.colors?.navHeader?.secondaryItemsColor};
  opacity: 0.7;
  line-height: 2.188rem;
  letter-spacing: normal;

  ${({ theme }) => theme?.breakpoints?.mobile} {
    display: none;
  }
`

export const SecondaryItemsWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme?.colors?.navHeader?.secondaryItemsColor};
`

export const SaveSvg = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 0.5rem;

    ${({ theme }) => theme?.breakpoints?.mobile} {
      margin-right: 1.2rem;
    }
  }
`
export const NavHeaderTitle = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;

  ${({ theme }) => theme?.breakpoints?.navHeaderTruncate} {
    margin-right: 1.2rem;
  }
`

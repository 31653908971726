import styled from 'styled-components'

export const Wrapper = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  text-align: right;
  height: 2.5rem;

  ${({ theme }) => theme?.breakpoints?.tablet} {
    height: 1.875rem;
  }

  ${({ theme }) => theme?.breakpoints?.mobile} {
    font-size: 16px;
    line-height: 1.5rem;
    height: 3.5rem;
  }
`

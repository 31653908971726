import { Markdown } from 'grommet'
import { Accordion } from 'secondstep-components'
import styled from 'styled-components'

export const StyledAccordion = styled(Accordion).attrs(
  ({ dataTestId, theme }) => ({
    isActive: true,
    contentPadding: '0rem',
    dataTestId: dataTestId,
    panelColor: theme?.colors?.autopopSurveyAccordion?.panelColor || '#fff',
  }),
)`
  margin: 1.75rem 0;

  ${({ theme }) => theme?.breakpoints?.mobile} {
    margin: 1.25rem 0;
  }

  button {
    color: ${({ theme }) => theme?.colors?.accordion?.title};
    font-family: ${({ theme }) => theme?.fonts?.accordion?.header?.family};
    font-weight: ${({ theme }) => theme?.fonts?.accordion?.header?.weight};
  }

  svg {
    color: ${({ theme }) => theme?.colors?.accordion?.icon};
  }
`

export const QuestionsWrapper = styled.div`
  background-color: #e4f3f3;
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  margin: 1.75rem 0 2.5rem;
  padding: 0 1.75rem 2.5rem;

  ${({ theme }) => theme?.breakpoints?.mobile} {
    margin: 1.25rem 0 1.25rem;
    padding: 0rem 1.25rem 1.75rem;
  }
`

export const Text = styled(Markdown).attrs(({ datatestid }) => ({
  'data-testid': datatestid,
}))``

export const Question = styled.div`
  align-items: center;
  display: flex;
  font-family: ${({ theme }) => theme?.fonts?.autopopSurvey?.question?.family};
  font-weight: ${({ theme }) => theme?.fonts?.autopopSurvey?.question?.weight};
  justify-content: space-between;
  margin-top: 2.5rem;

  ${({ theme }) => theme?.breakpoints?.mobile} {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    &:first-child {
      margin-top: 1.25rem;
    }
    margin-top: 1.75rem;
  }
`

export const Score = styled.div`
  align-items: center;
  background-color: white;
  border-radius: 0.375rem;
  display: flex;
  height: 2.1875rem;
  justify-content: center;
  margin-left: 2.5rem;
  min-width: 4.4375rem;
  max-width: 4.4375rem;

  ${({ theme }) => theme?.breakpoints?.mobile} {
    margin: 1rem 0 0.5rem;
  }
`

export const ContentWrapper = styled.div`
  padding: 0 1.75rem 2.5rem 1.75rem;

  ${({ theme }) => theme?.breakpoints?.mobile} {
    padding: 0 1.25rem 1.75rem 1.25rem;
  }
`

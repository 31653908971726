import { H3Wrapper } from 'components/Header/component.styles'
import { Button } from 'grommet'
import { Modal, ModalContentWrapper } from 'secondstep-components'
import styled from 'styled-components'

const gutterWidths = {
  desktop: '2.5rem',
  mobile: '1.25rem',
  tablet: '2.5rem',
}

export const StyledOverlay = styled(Modal).attrs(() => ({
  h3MarginLeft: '-' + gutterWidths.desktop,
  h3MarginLeftMobile: '-' + gutterWidths.mobile,
  h3PaddingLeft: gutterWidths.desktop,
  h3PaddingLeftMobile: gutterWidths.mobile,
}))`
  .ReactModal__Overlay {
    z-index: 12;
    opacity: 0;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
    transition: all 100ms ease-in;
  }
  position: relative;

  ${H3Wrapper} {
    margin-left: ${({ h3MarginLeft }) => h3MarginLeft};
    padding-left: ${({ h3PaddingLeft }) => h3PaddingLeft};

    ${({ theme }) => theme?.breakpoints?.mobile} {
      margin-left: ${({ h3MarginLeftMobile }) => h3MarginLeftMobile};
      padding-left: ${({ h3PaddingLeftMobile }) => h3PaddingLeftMobile};
    }
  }

  ${ModalContentWrapper} {
    background-color: ${({ theme }) => theme?.colors?.modal?.backgroundColor};
    border-radius: 0;
    box-sizing: border-box;
    padding: 2.5rem;
    width: 100%;
    height: 100%;
    ${({ theme }) => theme?.breakpoints?.tablet} {
      max-width: none;
    }
  }

  .Overlay {
    background-color: ${({ theme }) =>
      theme?.colors?.modal?.overlay?.backgroundColor};
  }

  h2 {
    margin-top: 0;
  }
`

export const OverlayInnerWrapper = styled.div`
  margin: auto 0;
  * + * {
    margin: 1.75rem 0 0;
  }
`

export const StyledCloseButton = styled(Button).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))``

import styled from 'styled-components'

export const VideoWrapper = styled.div.attrs(
  ({ hasMargin, transcriptRendered }) => ({
    margin: hasMargin ? '2.5rem 0' : '0',
    height: transcriptRendered ? '80%' : '100%',
    marginBottom: transcriptRendered ? '0' : '2.5rem',
  }),
)`
  margin: ${({ margin }) => margin};
  width: 100%;
  display: flex;
  flex-direction: row;
  height: ${({ height }) => height};
  ${({ theme }) => theme?.breakpoints?.tablet} {
    margin-bottom: ${({ marginBottom }) => marginBottom};
    height: 100%;
  }
  ${({ theme }) => theme?.breakpoints?.medium} {
    height: 100%;
  }
`

export const VideoContentWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .video-js,
  video {
    border-radius: 0.625rem;
    .vjs-big-play-button {
      width: 3.75rem;
      height: 3.75rem;
      :focus {
        outline: 3px groove ${({ theme }) => theme?.colors?.transcript?.outline} !important;
        border-radius: 0;
      }
    }
  }

  .vjs-poster img {
    border-radius: 0.625rem;
    object-fit: cover;
  }
  .vjs-control-bar {
    border-radius: 0 0 0.625rem 0.625rem;
  }

  .vjs-fullscreen {
    .vjs-control-bar {
      border-radius: 0;
    }
  }
`

export const TranscriptOutterWrapper = styled.div.attrs(({ hasMargin }) => ({
  margin: hasMargin ? '2.5rem 0 0' : '0',
}))`
  margin: ${({ margin }) => margin};
  display: block;
  flex-basis: 19.875rem;
  background-color: #e4f3f3;
  height: 38.5rem;
  flex-basis: 40%;
  overflow: scroll;
  border-radius: 5px;
  .description .transcript-line {
    color: ${({ theme }) => theme?.colors?.transcript?.color};
  }
  .transcript {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  ${({ theme }) => theme?.breakpoints?.tablet} {
    margin-top: 1rem;
    max-height: 18.75rem;
  }
  ${({ theme }) => theme?.breakpoints?.medium} {
    max-height: 28.125rem;
  }
`
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  ${({ theme }) => theme?.breakpoints?.tablet} {
    flex-direction: column;
  }
`
export const Spacer = styled.div.attrs(({ transcriptRendered }) => ({
  display: transcriptRendered ? 'flex' : 'none',
}))`
  display: ${({ display }) => display};
  flex-basis: 1rem;
`
export const ToggleWrapper = styled.div.attrs(({ transcriptRendered }) => ({
  bottom: transcriptRendered ? '0' : '2rem',
}))`
  height: 2rem;
  width: 100%;
  display: flex;
  vertical-align: top;
  justify-content: flex-end;
  position: relative;
  padding-top: 0.625rem;
  bottom: ${({ bottom }) => bottom};
  & button {
    margin-left: 0.625rem;
  }
`
export const TranscriptTooltip = styled.span`
  margin-bottom: 4.5px;
  box-sizing: border-box;
  height: 1.938rem;
  width: 9.188rem;
  border: 0.063rem solid #ced8df;
  border-radius: 0.375rem;
  background-color: #ffffff;
  box-shadow: 0 0 0.75rem 0 rgba(0, 0, 0, 0.1);
  color: #555555;
  line-height: 1.6rem;
  font-family: Molde;
  font-size: 1rem;
  letter-spacing: 0;
  text-align: center;
`
export const AudioDescTooltip = styled.span.attrs(({ isAudioDescActive }) => ({
  width: isAudioDescActive ? '18.313rem' : '16.688rem',
}))`
  margin-bottom: 4.5px;
  box-sizing: border-box;
  height: 1.938rem;
  width: ${({ width }) => width};
  border: 0.063rem solid #ced8df;
  border-radius: 0.375rem;
  background-color: #ffffff;
  box-shadow: 0 0 0.75rem 0 rgba(0, 0, 0, 0.1);
  color: #555555;
  line-height: 1.6rem;
  font-family: Molde;
  font-size: 1rem;
  letter-spacing: 0;
  text-align: center;
`

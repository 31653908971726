import { getActionData } from 'utils/state'

import { INITIAL_STATE } from '../constants'

import { default as contentfulTypes } from './types'

export default (state = INITIAL_STATE, action) => {
  const { data, diagnostic, type } = getActionData(action) || {}
  const { entryId } = data || {}

  switch (type) {
    case contentfulTypes.FETCHING: {
      const optimisticData = data

      return {
        ...state,
        [entryId]: {
          data: {
            entryId: undefined,
            entry: undefined,
            ...optimisticData,
          },
          diagnostic,
        },
      }
    }
    case contentfulTypes.FETCHING_ERROR: {
      return {
        ...state,
        [entryId]: {
          data: {
            entry: undefined,
          },
          diagnostic,
        },
      }
    }
    case contentfulTypes.FETCHING_SUCCESS: {
      return {
        ...state,
        [entryId]: {
          data,
          diagnostic,
        },
      }
    }
    default:
      return state
  }
}

import { H3Wrapper } from 'components/Header/component.styles'
import { Accordion } from 'secondstep-components'
import styled from 'styled-components'

const gutterWidths = {
  desktop: '1.5rem',
  mobile: '1.5rem',
  tablet: '1.5rem',
}

export const StyledAccordion = styled(Accordion).attrs(
  ({ dataTestId, theme }) => ({
    dataTestId: dataTestId,
    h3MarginLeft: '-' + gutterWidths.desktop,
    h3MarginLeftMobile: '-' + gutterWidths.mobile,
    h3PaddingLeft: gutterWidths.desktop,
    h3PaddingLeftMobile: gutterWidths.mobile,
    iconColor: theme?.colors?.accordion?.icon,
    padding: gutterWidths.desktop,
    panelColor: theme?.colors?.accordion?.panelColor,
  }),
)`
  margin: 2.5rem 0;

  ${({ theme }) => theme?.breakpoints?.mobile} {
    margin: 1.25rem 0;
  }

  ${H3Wrapper} {
    margin-left: ${({ h3MarginLeft }) => h3MarginLeft};
    padding-left: ${({ h3PaddingLeft }) => h3PaddingLeft};
    background: ${({ theme }) =>
      theme?.colors?.heading?.level[3]?.backgroundColor};

    ${({ theme }) => theme?.breakpoints?.mobile} {
      margin-left: ${({ h3MarginLeftMobile }) => h3MarginLeftMobile};
      padding-left: ${({ h3PaddingLeftMobile }) => h3PaddingLeftMobile};
    }
  }

  button {
    color: ${({ theme }) => theme?.colors?.accordion?.title};
    font-family: ${({ theme }) => theme?.fonts?.accordion?.header?.family};
    font-weight: ${({ theme }) => theme?.fonts?.accordion?.header?.weight};
  }

  ol {
    margin: 0;
    padding: 0 0 0 1.5rem;
  }
`

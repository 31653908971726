import { useSelector } from 'react-redux'
import { selectors as appSelectors } from 'store/app'
import { selectors as contentfulSelectors } from 'store/contentful'

// Use this hook to retrieve all errors from diagnostics objects
const useDiagnosticsValidator = () => {
  const currentLocation = useSelector(appSelectors.contentfulId)
  const nextLessonId = useSelector(appSelectors.nextLesson)?.contentfulId
  const previousLessonId = useSelector(appSelectors.previousLesson)
    ?.contentfulId

  const entriesToValidate = [currentLocation]
  nextLessonId && entriesToValidate.push[nextLessonId]
  previousLessonId && entriesToValidate.push[previousLessonId]

  const contentfulErrors = useSelector(state =>
    contentfulSelectors.errors(state, entriesToValidate),
  )

  const validate = () => ({
    contentfulErrors,
    // when we need it we can return lms errors, too
  })

  return {
    validate,
  }
}

export default {
  useDiagnosticsValidator,
}

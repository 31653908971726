import { getTypes, default as types } from 'store/types'

const actionType = types?.ACTION_TYPES?.UPDATING
const baseType = types?.BASE_TYPES?.APP
const appTypes = getTypes(actionType, baseType)

export default {
  ...appTypes,
  SET_MICROLEARNING_TYPE: 'SET_MICROLEARNING_TYPE',
}

import { decode, encode } from 'utils/api'

import { APP_VERSION } from './constants'

// CmiDataMapper transforms lms api cmi data to redux format, or vice versa.

const CmiDataMapper = () => {
  // Map redux state json to an encoded string for saving to lms.
  const mapInteractivesToLms = (reduxInteractivesData = {}) => {
    const interactivesKeys =
      reduxInteractivesData && Object.keys(reduxInteractivesData)
    const microLearningId = interactivesKeys.length > 0 && interactivesKeys[0]
    const interactives = reduxInteractivesData[microLearningId]
    const cmiData = {
      contentfulId: microLearningId,
      interactives,
      version: APP_VERSION,
    }
    const encodedCmiData = encode(cmiData)

    return encodedCmiData
  }

  // Decode data from lms to a json object to be inserted into redux.
  const mapInteractivesToRedux = lmsCmiData => {
    const decodedCmiData = decode(lmsCmiData)
    const { interactives: lmsInteractives = {} } = decodedCmiData || {}

    return lmsInteractives
  }

  return {
    mapInteractivesToLms,
    mapInteractivesToRedux,
  }
}

export default CmiDataMapper

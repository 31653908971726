import React from 'react'

import CloseableOverlay from 'components/CloseableOverlay'
import PropTypes from 'prop-types'

import {
  OverlayBodyCopy,
  OverlayHeader,
  OverlayInnerWrapper,
  OverlayResumeButton,
} from './component.styles'
import {
  BODY_TEXT,
  DATA_TEST_ID,
  HEADER_TEXT1,
  RESUME_BUTTON_LABEL,
} from './constants'

const ResumeOverlayComponent = ({ onClose, isOpen }) => {
  return (
    <CloseableOverlay dataTestId={DATA_TEST_ID} onClose={onClose} show={isOpen}>
      <OverlayInnerWrapper>
        <OverlayHeader>{HEADER_TEXT1}</OverlayHeader>
        <OverlayBodyCopy>{BODY_TEXT}</OverlayBodyCopy>
        <OverlayResumeButton onClick={onClose}>
          {RESUME_BUTTON_LABEL}
        </OverlayResumeButton>
      </OverlayInnerWrapper>
    </CloseableOverlay>
  )
}

ResumeOverlayComponent.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onPageRefresh: PropTypes.func.isRequired,
}

export default ResumeOverlayComponent

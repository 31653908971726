import styled from 'styled-components'

export const SkipToMainWrapper = styled.a`
  background: ${({ theme }) => theme?.colors?.navHeader?.background};
  margin-right: 1rem;
  text-decoration: none;
  border-radius: 0.313rem;
  padding: 0.188rem 1rem 0.313rem 1rem;
  color: ${({ theme }) => theme?.colors?.navHeader?.color};
  position: relative;
  top: -4rem;
  z-index: 100;
  line-height: 1.063rem;
  &:focus {
    top: 0;
  }
  ${({ theme }) => theme?.breakpoints?.mobile} {
    display: none;
  }
`

import React, { Fragment } from 'react'

import { MAXLENGTH, PLACEHOLDERTEXT } from 'components/TextEntry/constants'
import { Markdown } from 'grommet'
import PropTypes from 'prop-types'

import {
  SingleSelectQuestionText,
  SingleSelectRadioButton,
  SingleSelectTextArea,
  SingleSelectWrapper,
} from './component.styles'
import { DATA_TEST_ID_PREFIX, RADIO_BUTTON, TEXT_AREA } from './constants'

const SingleSelectQuestionComponent = ({
  answers,
  handleChange,
  handleCheck,
  id,
  pageComponentIndex,
  questionText,
  response,
  selectedAnswer,
}) => {
  const identifier = `single-select-question-${pageComponentIndex}-${id}`
  return (
    <SingleSelectWrapper ariaLabelledBy={identifier} data-testid={identifier}>
      {questionText && (
        <SingleSelectQuestionText>
          <Markdown data-testid="single-select-question-text" id={identifier}>
            {questionText}
          </Markdown>
        </SingleSelectQuestionText>
      )}
      {answers?.map(answer => {
        const { id: answerId, answerText, answerValue } = answer
        const isChecked = selectedAnswer === answerText
        const dataTestIdSuffix = `${pageComponentIndex}-${id}-${answerId}`
        const radioButtonDataTestId = `${DATA_TEST_ID_PREFIX}${RADIO_BUTTON}${dataTestIdSuffix}`
        const textAreaDataTestId = `${DATA_TEST_ID_PREFIX}${TEXT_AREA}${dataTestIdSuffix}`

        return (
          <Fragment>
            <SingleSelectRadioButton
              answerText={answerText}
              checked={isChecked}
              dataTestId={radioButtonDataTestId}
              id={radioButtonDataTestId}
              key={radioButtonDataTestId}
              name={`single-select-group-${id}`}
              onChange={e => handleCheck(e, answerValue)}
              value={answerText}
            />
            {answer?.hasTextField && (
              <SingleSelectTextArea
                dataTestId={textAreaDataTestId}
                disabled={!isChecked}
                id={radioButtonDataTestId}
                maxLength={MAXLENGTH}
                onChange={handleChange}
                placeholder={PLACEHOLDERTEXT}
                value={response}
              />
            )}
          </Fragment>
        )
      })}
    </SingleSelectWrapper>
  )
}

SingleSelectQuestionComponent.propTypes = {
  answers: PropTypes.array,
  handleChange: PropTypes.func,
  handleCheck: PropTypes.func,
  id: PropTypes.string,
  pageComponentIndex: PropTypes.string,
  questionText: PropTypes.string,
  response: PropTypes.string,
  selectedAnswer: PropTypes.string,
}

SingleSelectQuestionComponent.defaultProps = {
  handleChange() {},
  handleCheck() {},
  pageComponentIndex: '',
}

export default SingleSelectQuestionComponent

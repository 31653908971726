import React from 'react'

import { SERVICE_CODES } from 'config/constants'
import PropTypes from 'prop-types'
import { navigate, refreshPage } from 'utils/browser'

import ErrorMessageComponent from './component'
import {
  DASHBOARD_URL,
  ERROR_CODE,
  FRIENDLY_MESSAGE,
  SERVICE_CODE,
} from './constants'
import { httpErrorMessage } from './data'

const ErrorMessageContainer = ({
  additionalDetails,
  serviceName,
  statusCode,
}) => {
  const { statusMessage, shouldTryAgain } = httpErrorMessage(statusCode)
  const diagnosticMessage = `**${ERROR_CODE}** *${statusCode} ${statusMessage}*`
  const serviceCode = SERVICE_CODES[serviceName]
  const serviceDetails = `**${SERVICE_CODE}** *${serviceCode}*`
  const navigateToDashboard = () => navigate(DASHBOARD_URL)
  const tryAgain = () => refreshPage()

  return (
    <ErrorMessageComponent
      additionalDetails={additionalDetails}
      diagnosticMessage={diagnosticMessage}
      friendlyMessage={FRIENDLY_MESSAGE}
      navigateToDashboard={navigateToDashboard}
      serviceDetails={serviceDetails}
      serviceName={serviceName}
      shouldTryAgain={shouldTryAgain}
      tryAgain={tryAgain}
    />
  )
}

ErrorMessageContainer.propTypes = {
  additionalDetails: PropTypes.string,
  serviceName: PropTypes.string,
  statusCode: PropTypes.number,
}

ErrorMessageContainer.defaultProps = {
  additionalDetails: '',
}

export default ErrorMessageContainer

import React, { Fragment } from 'react'

import CloseableModal from 'components/CloseableModal'
import CheckMark from 'components/Icons/CheckMarkIcon'
import PropTypes from 'prop-types'

import {
  BodyContent,
  ButtonWrapper,
  CheckMarkWrapper,
  ContentArea,
  FeedbackContainer,
  FeedbackLink,
  FeedbackLinkBreak,
  GoBackButton,
  HeaderContent,
  MarkAsCompleteOuterWrapper,
  MarkAsCompleteWrapper,
  MarkCompleteButton,
  ModalBodyContent,
  ModalContentArea,
  ModalHeaderContent,
  StyledLinkIcon,
} from './component.styles'
import {
  BACK_TEXT,
  BACK_TO_MODULE_TEXT,
  FEEDBACK_LINK_TEXT,
  FEEDBACK_LINK_TEXT_BREAK,
  MARK_AS_COMPLETE_BUTTON,
  MARK_COMPLETE_TEXT,
  MARKED_COMPLETE_HEADER,
  MODAL_HEADER_TEXT,
  MODAL_TEXT,
  NOT_MARKED_COMPLETE_HEADER,
  NOT_MARKED_COMPLETE_TEXT,
} from './constants'

const MarkAsCompleteComponent = ({
  closeModal,
  disabled,
  feedbackUrl,
  isComplete,
  isModalOpen,
  isReadyToComplete,
  markComplete,
  navigateToModule,
  openModal,
}) => {
  return (
    <MarkAsCompleteOuterWrapper dataTestId={`mark-as-complete-outer-wrapper`}>
      <MarkAsCompleteWrapper
        dataTestId={`mark-as-complete-wrapper`}
        isComplete={isComplete}
      >
        {isComplete ? (
          <Fragment>
            <CheckMarkWrapper>
              <CheckMark />
            </CheckMarkWrapper>
            <ContentArea>
              <HeaderContent isComplete={isComplete}>
                {MARKED_COMPLETE_HEADER}
              </HeaderContent>
            </ContentArea>
          </Fragment>
        ) : (
          <ContentArea>
            <HeaderContent>{NOT_MARKED_COMPLETE_HEADER}</HeaderContent>
            <BodyContent>{NOT_MARKED_COMPLETE_TEXT}</BodyContent>
          </ContentArea>
        )}
        {isComplete && (
          <Fragment>
            <MarkCompleteButton
              dataTestId={`back-to-module-button`}
              disabled={disabled}
              isComplete={isComplete}
              isReadyToComplete={isReadyToComplete}
              onClick={navigateToModule}
            >
              {BACK_TO_MODULE_TEXT}
            </MarkCompleteButton>
            <FeedbackContainer>
              <FeedbackLink url={feedbackUrl}>
                {FEEDBACK_LINK_TEXT}
              </FeedbackLink>
              <FeedbackLinkBreak url={feedbackUrl}>
                {FEEDBACK_LINK_TEXT_BREAK}
                <StyledLinkIcon />
              </FeedbackLinkBreak>
            </FeedbackContainer>
          </Fragment>
        )}
        {!isComplete && !isReadyToComplete && (
          <Fragment>
            <MarkCompleteButton
              dataTestId={MARK_AS_COMPLETE_BUTTON}
              disabled={disabled}
              isComplete={isComplete}
              isReadyToComplete={isReadyToComplete}
              onClick={openModal}
            >
              {MARK_COMPLETE_TEXT}
            </MarkCompleteButton>
            <CloseableModal
              isComplete={isComplete}
              onClose={closeModal}
              show={isModalOpen}
            >
              <ModalContentArea>
                <ModalHeaderContent>{MODAL_HEADER_TEXT}</ModalHeaderContent>
                <ModalBodyContent>{MODAL_TEXT}</ModalBodyContent>
                <ButtonWrapper>
                  <GoBackButton
                    dataTestId={`modal-back-button`}
                    disabled={disabled}
                    onClick={closeModal}
                  >
                    {BACK_TEXT}
                  </GoBackButton>
                  <MarkCompleteButton
                    dataTestId={`mark-as-complete-modal-button`}
                    disabled={disabled}
                    isComplete={isComplete}
                    isReadyToComplete={isReadyToComplete}
                    onClick={markComplete}
                  >
                    {MARK_COMPLETE_TEXT}
                  </MarkCompleteButton>
                </ButtonWrapper>
              </ModalContentArea>
            </CloseableModal>
          </Fragment>
        )}
        {isReadyToComplete && !isComplete && (
          <MarkCompleteButton
            dataTestId={MARK_AS_COMPLETE_BUTTON}
            disabled={disabled}
            isComplete={isComplete}
            isReadyToComplete={isReadyToComplete}
            onClick={markComplete}
          >
            {MARK_COMPLETE_TEXT}
          </MarkCompleteButton>
        )}
      </MarkAsCompleteWrapper>
    </MarkAsCompleteOuterWrapper>
  )
}

MarkAsCompleteComponent.defaultProps = {
  closeModal() {},
  markComplete() {},
  navigateToModule() {},
  openModal() {},
}

MarkAsCompleteComponent.propTypes = {
  closeModal: PropTypes.func,
  disabled: PropTypes.bool,
  feedbackUrl: PropTypes.string,
  isComplete: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  isReadyToComplete: PropTypes.bool,
  markComplete: PropTypes.func,
  navigateToModule: PropTypes.func,
  openModal: PropTypes.func,
}

export default MarkAsCompleteComponent

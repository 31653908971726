import React from 'react'

import CloseableModal from 'components/CloseableModal'
import PropTypes from 'prop-types'

import {
  ModalBodyCopy,
  ModalHeader,
  ModalInnerWrapper,
  ModalRefreshButton,
} from './component.styles'
import {
  BODY_TEXT,
  DATA_TEST_ID,
  HEADER_TEXT1,
  HEADER_TEXT2,
  REFRESH_BUTTON_LABEL,
} from './constants'

const ErrorModalComponent = ({ onPageRefresh, onClose, isOpen }) => {
  return (
    <CloseableModal dataTestId={DATA_TEST_ID} onClose={onClose} show={isOpen}>
      <ModalInnerWrapper>
        <ModalHeader>
          {HEADER_TEXT1} <br /> {HEADER_TEXT2}
        </ModalHeader>
        <ModalBodyCopy>{BODY_TEXT}</ModalBodyCopy>
        <ModalRefreshButton onClick={onPageRefresh}>
          {REFRESH_BUTTON_LABEL}
        </ModalRefreshButton>
      </ModalInnerWrapper>
    </CloseableModal>
  )
}

ErrorModalComponent.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onPageRefresh: PropTypes.func.isRequired,
}

export default ErrorModalComponent

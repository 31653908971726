import React from 'react'

import PropTypes from 'prop-types'
import { BodyCopy } from 'secondstep-components'

import Image from '../Image'

import { Column, Row, Wrapper } from './component.styles'

const ImageBodyCopyComponent = ({ image, bodyCopy, id }) => {
  return (
    <Wrapper dataTestId={`image-copy-body-${id}`}>
      <Row>
        <Column>
          <Image {...image} />
        </Column>
        <Column>
          <BodyCopy>{bodyCopy}</BodyCopy>
        </Column>
      </Row>
    </Wrapper>
  )
}

ImageBodyCopyComponent.defaultProps = {
  bodyCopy: '',
  id: 'test-id',
}

ImageBodyCopyComponent.propTypes = {
  bodyCopy: PropTypes.string,
  id: PropTypes.string,
  image: PropTypes.object.isRequired,
}

export default ImageBodyCopyComponent

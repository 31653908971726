import React from 'react'

import env from 'config/build'
import { default as completionStatusHooks } from 'hooks/useCompletionStatus'
import { default as modalHooks } from 'hooks/useModal'
import { useSelector } from 'react-redux'
import { selectors as appSelectors } from 'store/app'
import { selectors as cmiSelectors } from 'store/cmi'
import { selectors as contentfulSelectors } from 'store/contentful'
import { isFieldTestMode } from 'utils/player'

import MarkAsCompleteComponent from './component'

const MarkAsCompleteContainer = () => {
  const interactivesCompleted = useSelector(
    state => appSelectors.interactivesCompleted(state) || {},
  )
  const appLockStatus = useSelector(appSelectors.isAppLocked)

  const areThereInteractives = Object.values(interactivesCompleted).length > 0
  const isReadyToComplete = areThereInteractives
    ? Object.values(interactivesCompleted).every(completed => {
        return completed
      })
    : !areThereInteractives

  const { openModal, closeModal, isModalOpen } = modalHooks.useModal()
  const microLearningId = useSelector(state => appSelectors.contentfulId(state))

  const isComplete =
    useSelector(state =>
      cmiSelectors.completionStatus(state, microLearningId),
    ) === 'completed'
  const { updateCompletionStatus } = completionStatusHooks.useCompletionStatus(
    microLearningId,
  )

  const moduleId = useSelector(
    state =>
      appSelectors.contentfulToScoTree(state)?.currentModule?.contentfulId,
  )

  const feedbackUrl = useSelector(
    state =>
      contentfulSelectors.entry(state, microLearningId)?.lessonFeedbackUrl,
  )

  const navigateToModule = () => {
    const slug = isFieldTestMode()
      ? 'sel-for-adults-field-test'
      : 'sel-for-adults'
    parent.window.open(
      `${env.domains.LEARN_DOMAIN}/product/${slug}/modules/${moduleId}`,
      '_self',
    )
  }
  const markComplete = () => {
    updateCompletionStatus('completed')
  }

  return (
    <MarkAsCompleteComponent
      closeModal={closeModal}
      disabled={appLockStatus}
      feedbackUrl={feedbackUrl}
      isComplete={isComplete}
      isModalOpen={isModalOpen}
      isReadyToComplete={isReadyToComplete}
      markComplete={markComplete}
      navigateToModule={navigateToModule}
      openModal={openModal}
    />
  )
}

export default MarkAsCompleteContainer

import React from 'react'

import PropTypes from 'prop-types'
import { Image } from 'secondstep-components'

import ContainerDimensions from '../ContainerDimensions'

const ImageComponent = ({ alt, src, ...props }) => {
  return (
    <ContainerDimensions>
      {({ screenWidth }) => (
        <Image {...props} alt={alt} src={`${src}?w=${screenWidth}`} />
      )}
    </ContainerDimensions>
  )
}

ImageComponent.defaultProps = {
  alt: '""',
}

ImageComponent.propTypes = {
  alt: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
}

export default ImageComponent

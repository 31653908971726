import React, { Fragment } from 'react'

import { MAXLENGTH, PLACEHOLDERTEXT } from 'components/TextEntry/constants'
import { Markdown } from 'grommet'
import PropTypes from 'prop-types'

import {
  MultiSelectCheckBox,
  MultiSelectQuestionText,
  MultiSelectTextArea,
  MultiSelectTextAreaStatus,
  MultiSelectWrapper,
} from './component.styles'
import { CHECKBOX, DATA_TEST_ID_PREFIX, TEXT_AREA } from './constants'

const MultiSelectQuestionComponent = ({
  answers,
  checkedAnswers,
  handleChange,
  handleCheck,
  id,
  pageComponentIndex,
  questionText,
  response,
  showMultiSelectTextAreaStatus,
  showStatusAlert,
}) => {
  const identifier = `multi-select-question-text-${pageComponentIndex}-${id ||
    '0'}`
  return (
    <MultiSelectWrapper ariaLabelledBy={identifier}>
      {questionText && (
        <MultiSelectQuestionText datatestid={identifier}>
          <Markdown>{questionText}</Markdown>
        </MultiSelectQuestionText>
      )}
      {answers?.map(answer => {
        const answerId = answer?.id
        const answerKey = `multi-select-answer-${pageComponentIndex}-${id}-${answerId}`
        const answerText = answer?.answerText
        const isChecked = checkedAnswers?.includes(answerText)
        const dataTestIdSuffix = `${pageComponentIndex}-${id}-${answerId}`
        const checkBoxDataTestId = `${DATA_TEST_ID_PREFIX}${CHECKBOX}${dataTestIdSuffix}`
        const textAreaDataTestId = `${DATA_TEST_ID_PREFIX}${TEXT_AREA}${dataTestIdSuffix}`

        return (
          <Fragment key={answerKey}>
            <MultiSelectCheckBox
              answerText={answerText}
              checked={isChecked}
              dataTestId={checkBoxDataTestId}
              id={checkBoxDataTestId}
              onChange={handleCheck}
              value={answerText}
            />

            {answer?.hasTextField && (
              <>
                <MultiSelectTextArea
                  dataTestId={textAreaDataTestId}
                  disabled={!isChecked}
                  label={`text-area-for-${answerText}`}
                  maxLength={MAXLENGTH}
                  onChange={handleChange}
                  placeholder={PLACEHOLDERTEXT}
                  value={response}
                />
                {showMultiSelectTextAreaStatus && (
                  <MultiSelectTextAreaStatus
                    dataTestId={`${textAreaDataTestId}${dataTestIdSuffix}`}
                    showStatusAlert={showStatusAlert}
                  >
                    {`${response.length} / ${MAXLENGTH}`}
                  </MultiSelectTextAreaStatus>
                )}
              </>
            )}
          </Fragment>
        )
      })}
    </MultiSelectWrapper>
  )
}

MultiSelectQuestionComponent.propTypes = {
  answers: PropTypes.array,
  checkedAnswers: PropTypes.array,
  handleChange: PropTypes.func,
  handleCheck: PropTypes.func,
  id: PropTypes.string,
  pageComponentIndex: PropTypes.string,
  questionText: PropTypes.string,
  response: PropTypes.string,
  showMultiSelectTextAreaStatus: PropTypes.bool,
  showStatusAlert: PropTypes.bool,
}

MultiSelectQuestionComponent.defaultProps = {
  handleChange: () => {},
  handleCheck: () => {},
  pageComponentIndex: '0',
}

export default MultiSelectQuestionComponent

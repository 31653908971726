import { useDispatch, useSelector } from 'react-redux'
import {
  operations as interactivesOperations,
  selectors as interactivesSelectors,
} from 'store/interactives'

// Use this hook in components where selection(s) are made.
// ex. Single Select Question, Multi Select Question
const useSelect = (interactiveType, microLearningId, interactiveId) => {
  const dispatch = useDispatch()
  const interactive = useSelector(state =>
    interactivesSelectors.interactive(state, microLearningId, interactiveId),
  )
  const actions = {
    updateSelections(selections, answerValue) {
      if (!selections) {
        return
      }

      const newInteractiveState = {
        ...interactive,
        selections,
        answerValue,
        type: interactiveType,
      }

      dispatch(
        interactivesOperations.update(
          microLearningId,
          interactiveId,
          newInteractiveState,
        ),
      )
    },
  }

  return actions
}

export default {
  useSelect,
}

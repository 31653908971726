import env from 'config/build'
import { ERROR_TYPES } from 'config/constants'

const noOp = () => {}
const defaultErrorMessage =
  'Error: There was a problem performing the LMS API action.'
const defaultStatus = '101'
const defaultStatusText = 'General LMS API exception'
const errorType = ERROR_TYPES.LMS_API_ERROR

export const CREATE_API_CONNECTION = env?.createApiConnection || noOp
export const CODE_SUCCESS = '0'
export const DEFAULT_ERROR = {
  message: defaultErrorMessage,
  name: errorType,
  status: defaultStatus,
  statusText: defaultStatusText,
}
export const IS_STANDALONE = env?.isStandalone
export const INITIAL_STATE = {
  instance: null,
  hasRequestedInitialization: false,
  isStandalone: IS_STANDALONE,
  scoId: undefined,
}
export const LMS_API_BASE_ADDRESS = 'scorm api'

import React, { useEffect, useRef } from 'react'

import { TIMEOUT_INTERVAL } from 'config/constants'
import { default as modalHooks } from 'hooks/useModal'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { selectors as appSelectors } from 'store/app'
import { APP_MULTIMEDIA_STATUS } from 'store/app/constants'

import ResumeOverlayComponent from '../ResumeOverlay'

import IdleTimer from './IdleTimer'

const TimeoutGuardContainer = ({ timeout }) => {
  const { openModal, closeModal, isModalOpen } = modalHooks.useModal()
  const appMultimediaStates = useSelector(appSelectors.getAppMultimediaStates)
  const idleTimerRef = useRef(null)
  const isPlaying = () =>
    appMultimediaStates.indexOf(APP_MULTIMEDIA_STATUS.PLAYING) >= 0

  useEffect(() => {
    if (idleTimerRef.current && isPlaying()) {
      idleTimerRef.current.pause()
    }

    if (idleTimerRef.current && !isPlaying()) {
      idleTimerRef.current.reset()
    }
  }, [appMultimediaStates])

  const onIdle = () => {
    openModal()
  }

  const onClose = () => {
    closeModal()
    idleTimerRef.current.reset()
  }

  return (
    <div>
      <IdleTimer
        crossTab={{
          emitOnAllTabs: true,
        }}
        onIdle={onIdle}
        ref={idleTimerRef}
        timeout={timeout}
      />
      <ResumeOverlayComponent isOpen={isModalOpen} onClose={onClose} />
    </div>
  )
}

TimeoutGuardContainer.defaultProps = {
  timeout: TIMEOUT_INTERVAL,
}

TimeoutGuardContainer.propTypes = {
  timeout: PropTypes.number,
}

export default TimeoutGuardContainer

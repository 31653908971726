import React from 'react'

import Scorecard from 'components/Scorecard'
import { SurveyHorizRule } from 'components/Survey/component.styles'
import PropTypes from 'prop-types'
import {
  getDataTestIdHeader,
  getDataTestIdIntroText,
  getMaxPossibleScores,
} from 'utils/survey'

import {
  ContentWrapper,
  Question,
  QuestionsWrapper,
  Score,
  StyledAccordion,
  Text,
} from './component.styles'
import { ANSWER_VALUE_UNANSWERED } from './constants'

const AutoPopulateSurveyComponent = ({
  answerValues,
  categories,
  header,
  id,
  introText,
  pageComponentIndex,
  questionItems,
  scorecardIntroText,
  totalAnswered,
  totalQuestions,
}) => {
  const headerDataTestId = getDataTestIdHeader(pageComponentIndex, id)
  const introTextDataTestId = getDataTestIdIntroText(pageComponentIndex, id)
  const maxPossibleScores = getMaxPossibleScores(questionItems)
  return (
    <StyledAccordion dataTestId={headerDataTestId} title={header}>
      <ContentWrapper>
        {header && <SurveyHorizRule />}
        {introText && <Text datatestid={introTextDataTestId}>{introText}</Text>}
        <QuestionsWrapper>
          {questionItems?.map((question, index) => {
            const { id: questionId, questionText } = question || {}
            const score = answerValues[index] || ANSWER_VALUE_UNANSWERED

            return (
              <Question key={questionId}>
                <Text>{questionText}</Text>
                <Score>{score}</Score>
              </Question>
            )
          })}
        </QuestionsWrapper>
        <Scorecard
          categories={categories}
          isAutoPop
          maxPossibleScores={maxPossibleScores}
          pageComponentIndex={pageComponentIndex}
          scorecardIntroText={scorecardIntroText}
          totalAnswered={totalAnswered}
          totalQuestions={totalQuestions}
        />
      </ContentWrapper>
    </StyledAccordion>
  )
}

AutoPopulateSurveyComponent.propTypes = {
  answerValues: PropTypes.array,
  categories: PropTypes.object,
  header: PropTypes.string,
  id: PropTypes.string,
  introText: PropTypes.string,
  pageComponentIndex: PropTypes.string,
  questionItems: PropTypes.array,
  scorecardIntroText: PropTypes.string,
  totalAnswered: PropTypes.number,
  totalQuestions: PropTypes.number,
}

AutoPopulateSurveyComponent.defaultProps = {
  answerValues: [],
  header: '',
  introText: '',
  pageComponentIndex: '0',
  questionItems: [],
}

export default AutoPopulateSurveyComponent

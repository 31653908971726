import styled from 'styled-components'

const ASPECT_RATIO = '56.25%'

export const RatioWrapper = styled.div`
  overflow: hidden;
  padding-top: ${ASPECT_RATIO};
  position: relative;
  width: 100%;
`

import { SUBDOMAIN } from 'config/constants'

import {
  CONTENTFUL_PREVIEW_DOMAIN,
  CONTENTFUL_PROD_DOMAIN,
  NODE_ENV,
  PROD_NODE_ENVIRONMENT_NAME,
} from './constants'

// Use this file to define any access controls needed within the app. Returns an object
// containing current permissions based on various factors and context.

//Only allow access to the contentful CDN if running in 'production' node environment
const permissionedContentfulEnvironments = () => {
  const isProdBuild =
    NODE_ENV === PROD_NODE_ENVIRONMENT_NAME || SUBDOMAIN.endsWith('-staging')
  const permissionedEnvironments = [CONTENTFUL_PREVIEW_DOMAIN] //default is just contentful preview

  isProdBuild && permissionedEnvironments.push(CONTENTFUL_PROD_DOMAIN) //prod builds can access contentful preview and prod

  return permissionedEnvironments
}

export default {
  contentfulEnvironments: permissionedContentfulEnvironments(),
}

import React from 'react'

import BodyCopy from 'components/BodyCopy'
import HeaderContainer from 'components/Header'
import ContentMapper from 'lib/contentMapper'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { selectors as appSelectors } from 'store/app'

import PageLayoutComponent from './component'

const PageLayoutContainer = ({ content, title, label }) => {
  const currentUnit = useSelector(appSelectors.currentUnit)
  const currentUnitTitle = currentUnit?.title
  const contentMapper = new ContentMapper()
  const contentJsx = contentMapper.mapToComponents(content)

  return (
    <PageLayoutComponent
      currentUnitTitle={currentUnitTitle}
      label={label}
      title={title}
    >
      <RenderLayoutSections content={contentJsx} />
    </PageLayoutComponent>
  )
}

function createLayoutPartitions(contentJsx) {
  let reachedStartOfMainContent = false
  const headingChildren = []
  const mainChildren = []

  React.Children.forEach(contentJsx, child => {
    if (reachedStartOfMainContent) {
      mainChildren.push(child)
      return
    }
    const isComponent = child && typeof child.type !== 'string'
    const isHeader =
      isComponent && HeaderContainer.prototype === child.type.prototype
    const isBodyCopy =
      isComponent && BodyCopy.prototype === child.type.prototype

    if (isHeader || isBodyCopy) {
      reachedStartOfMainContent = true
    }

    if (reachedStartOfMainContent) {
      mainChildren.push(child)
    } else {
      headingChildren.push(child)
    }
  })

  return {
    heading: headingChildren,
    main: mainChildren,
  }
}

function RenderLayoutSections(contentJsx) {
  const { main, heading } = createLayoutPartitions(contentJsx.content)

  return (
    <>
      {heading}
      {main}
    </>
  )
}

PageLayoutContainer.defaultProps = {
  content: [],
  label: '',
  title: '',
}

PageLayoutContainer.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.string,
  title: PropTypes.string,
}

export default PageLayoutContainer

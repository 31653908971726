import { useEffect } from 'react'

// import env from 'config/build'
// import { fetchUserContextWithToken } from 'auth/stores/userContext/operations'
import { default as locationHooks } from 'hooks/useLocation'
import LmsConnectionsManager from 'lib/LmsConnectionsManager'
import LmsHydrationStatusManager from 'lib/LmsHydrationStatusManager'
import { useDispatch, useSelector } from 'react-redux'
import {
  operations as appOperations,
  selectors as appSelectors,
} from 'store/app'
import {
  operations as contentfulOperations,
  selectors as contentfulSelectors,
} from 'store/contentful/'
import { operations as lmsOperations } from 'store/lms'
import { getAutoPopSourceIdsFromMicroLearning } from 'utils/contentful'
import {
  createContentfulToScoTree,
  createSourceIdsDictionary,
} from 'utils/state'
import { getUrlParams } from 'utils/url'

// Initialize the app data and api connectors.
// useEffects are listed in order that they run.

export const Initialize = () => {
  const dispatch = useDispatch()
  const { updateLocation } = locationHooks.useLocation()
  const apiConnectionsInitialized = useSelector(
    appSelectors.apiConnectionsInitialized,
  )
  const autoPopSourceIds = useSelector(appSelectors.autoPopSourceIds)
  const contentful = useSelector(contentfulSelectors.contentful)
  const contentfulToScoTree = useSelector(appSelectors.contentfulToScoTree)
  const isLmsHydrated = useSelector(appSelectors.isLmsHydrated)
  const microLearningId = useSelector(appSelectors.contentfulId)
  const microLearningEntry = useSelector(state =>
    contentfulSelectors.entry(state, microLearningId),
  )
  const nextLesson = useSelector(appSelectors.nextLesson)
  const previousLesson = useSelector(appSelectors.previousLesson)

  // On first render, update the current microlearning location to the contentful id in the url.
  useEffect(() => {
    const { entry: contentfulEntryId } = getUrlParams()

    updateLocation(contentfulEntryId)

    // This is my best guess of as to where the call for user context ought to be
    // await fetchUserContextWithToken(dispatch, env?.domains.USER_CONTEXT_DOMAIN, env?.userJwt)
  }, [])

  // When the current microlearning location changes, trigger retrieval of the microlearning contentful entry
  useEffect(() => {
    microLearningId && dispatch(contentfulOperations.getEntry(microLearningId))
  }, [dispatch, microLearningId])

  // When the microlearning is loaded, populate redux values dependent on the
  // contentful entry and parent window values.
  useEffect(() => {
    if (!microLearningEntry) {
      return
    }

    const referencedMicroLearningIds = getAutoPopSourceIdsFromMicroLearning(
      microLearningEntry,
    )
    const contentfulToScoTree = createContentfulToScoTree(microLearningId)
    contentfulToScoTree.autoPopSourceIds = createSourceIdsDictionary(
      referencedMicroLearningIds,
      [microLearningId],
    )
    const { currentLesson } = contentfulToScoTree || {}
    const { scoID: scoId } = currentLesson || {}
    const { microlearningType, label: microlearningLabel } =
      microLearningEntry || {}

    dispatch(
      appOperations.update({
        contentfulToScoTree,
        scoId,
        microlearningType,
        microlearningLabel,
      }),
    )
  }, [dispatch, microLearningEntry])

  // Once autoPopSourceIds are populated, initialize all necessary api connections in order to run the microlearning.
  useEffect(() => {
    const callback = () =>
      dispatch(appOperations.update({ apiConnectionsInitialized: true }))

    autoPopSourceIds &&
      LmsConnectionsManager.initializeApiConnections(autoPopSourceIds, callback)
  }, [autoPopSourceIds, dispatch])

  // Once apiConnections are initialized and contentfulToScoTree is populated,
  // get the previous and next lesson contentful entries for navigational uses.
  useEffect(() => {
    if (!apiConnectionsInitialized) {
      return
    }

    const previousContentfulId = previousLesson?.contentfulId
    const nextContentfulId = nextLesson?.contentfulId

    previousContentfulId &&
      dispatch(contentfulOperations.getEntry(previousContentfulId))
    nextContentfulId &&
      dispatch(contentfulOperations.getEntry(nextContentfulId))
  }, [apiConnectionsInitialized, contentfulToScoTree, dispatch])

  // Once apiConnections are initialized and autoPopSourceIds are populated,
  // hydrate redux with saved data from the LMS.
  useEffect(() => {
    const canHydrate =
      !isLmsHydrated && autoPopSourceIds && apiConnectionsInitialized

    if (!canHydrate) {
      return
    }

    const autoPopSourceIdsKeys = Object.keys(autoPopSourceIds)
    LmsHydrationStatusManager.numOfSecondaryMicroLearnings =
      autoPopSourceIdsKeys.length

    const secondaryMicroLearningEntryIds = Object.keys(autoPopSourceIds)
    dispatch(
      lmsOperations.hydrate(microLearningId, secondaryMicroLearningEntryIds),
    )
  }, [apiConnectionsInitialized, autoPopSourceIds, dispatch])

  // Once contentfulScoTree is populated and contentful hydrations have completed, update isContentfulHydrated status.
  useEffect(() => {
    if (!contentfulToScoTree) {
      return
    }

    const numOfPrimaryContentfulEntries = 1
    const numOfPreviousContentfulEntries = previousLesson?.contentfulId ? 1 : 0
    const numOfNextContentfulEntries = nextLesson?.contentfulId ? 1 : 0
    const numOfSecondaryContentfulEntries =
      numOfPreviousContentfulEntries + numOfNextContentfulEntries
    const totalNumOfContentfulEntries =
      numOfPrimaryContentfulEntries + numOfSecondaryContentfulEntries
    const numOfHydratedContentfulEntriesReducer = (
      accumulator,
      contentfulEntry,
    ) => {
      accumulator += contentfulEntry?.data?.entry ? 1 : 0

      return accumulator
    }
    const initialNumOfHydratedContentfulEntries = 0
    const numOfHydratedContentfulEntries = Object.values(contentful).reduce(
      numOfHydratedContentfulEntriesReducer,
      initialNumOfHydratedContentfulEntries,
    )
    const isContentfulHydrated =
      numOfHydratedContentfulEntries === totalNumOfContentfulEntries

    isContentfulHydrated &&
      dispatch(appOperations.update({ isContentfulHydrated: true }))
  }, [contentful, contentfulToScoTree])
}

import { getActionData } from 'utils/state'

import { INITIAL_STATE } from '../constants'

import { default as cmiTypes } from './types'

export default (state = INITIAL_STATE, action) => {
  const { data, diagnostic, type, microLearningEntryId } = getActionData(action)
  const microLearningData = data && data[microLearningEntryId]
  const stateMicroLearningData = state[microLearningEntryId]?.data

  switch (type) {
    case cmiTypes.FETCHING: {
      const optimisticData = data
      const optimisticMicroLearningData = microLearningData
      const microLearning = microLearningEntryId
        ? {
            [microLearningEntryId]: {
              data: {
                ...stateMicroLearningData,
                ...optimisticMicroLearningData,
              },
              diagnostic,
            },
          }
        : undefined

      return {
        ...state,
        ...optimisticData,
        ...microLearning,
      }
    }
    case cmiTypes.FETCHING_ERROR: {
      return {
        ...state,
        [microLearningEntryId]: {
          data: {
            ...stateMicroLearningData,
          },
          diagnostic,
        },
      }
    }
    case cmiTypes.FETCHING_SUCCESS: {
      return {
        ...state,
        [microLearningEntryId]: {
          data: {
            ...stateMicroLearningData,
            ...microLearningData,
          },
          diagnostic,
        },
      }
    }

    case cmiTypes.UPDATING: {
      const optimisticData = microLearningData

      return {
        ...state,
        [microLearningEntryId]: {
          data: {
            ...stateMicroLearningData,
            ...optimisticData,
          },
          diagnostic,
        },
      }
    }
    case cmiTypes.UPDATING_ERROR: {
      return {
        ...state,
        [microLearningEntryId]: {
          data: {
            ...stateMicroLearningData,
          },
          diagnostic,
        },
      }
    }
    case cmiTypes.UPDATING_SUCCESS: {
      return {
        ...state,
        [microLearningEntryId]: {
          data: {
            ...stateMicroLearningData,
            ...microLearningData,
          },
          diagnostic,
        },
      }
    }
    default:
      return state
  }
}

import { Markdown } from 'grommet'
import { FormInputTextArea, FormInputTextInput } from 'secondstep-components'
import styled from 'styled-components'

export const TextEntryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.75rem 0;

  ${({ theme }) => theme?.breakpoints?.mobile} {
    margin: 1.25rem 0;
  }
`

export const TextEntryLabel = styled(Markdown)`
  font-family: ${({ theme }) => theme?.fonts?.textEntry?.label?.family};
  font-weight: ${({ theme }) => theme?.fonts?.textEntry?.label?.weight};
  line-height: 1.5rem;

  p {
    margin: 0;
    max-width: none;
  }
`

export const TextEntryTextArea = styled(FormInputTextArea).attrs(
  ({ maxLength, placeholder, onChange }) => ({
    onChange,
    maxLength,
    placeholder,
  }),
)`
  margin-top: 0.5rem;
`

export const TextEntryTextInput = styled(FormInputTextInput).attrs(
  ({ maxLength, placeholder, onChange }) => ({
    onChange,
    maxLength,
    placeholder,
  }),
)`
  margin-top: 0.5rem;
`

export const TextEntryStatusArea = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  display: flex;
  font-size: 0.875rem;
  color: ${({ showStatusAlert, theme }) =>
    showStatusAlert
      ? theme?.colors?.input?.textarea?.statusAlert
      : theme?.colors?.input?.textarea?.status};
  height: 0.8rem;
  justify-content: flex-end;
  margin-top: 0.25rem;
`

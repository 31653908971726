import React from 'react'

import CloseableModal from 'components/CloseableModal'
import PropTypes from 'prop-types'

import { StyledTextButton, TextButtonWrapper } from './component.styles'
import { getDataTestIdButton } from './data'

const ContentModalComponent = ({
  children,
  closeModal,
  isModalOpen,
  linkText,
  openModal,
  pageComponentIndex,
}) => {
  const dataTestIdButton = getDataTestIdButton(pageComponentIndex)
  const modal = isModalOpen ? (
    <CloseableModal onClose={closeModal} show>
      {children}
    </CloseableModal>
  ) : null
  const modalButton = (
    <TextButtonWrapper>
      <StyledTextButton
        dataTestId={dataTestIdButton}
        label={linkText}
        onClick={openModal}
      />
    </TextButtonWrapper>
  )

  return [modalButton, modal]
}

ContentModalComponent.propTypes = {
  children: PropTypes.node,
  closeModal: PropTypes.func,
  isModalOpen: PropTypes.bool,
  linkText: PropTypes.string,
  openModal: PropTypes.func,
  pageComponentIndex: PropTypes.string,
}

ContentModalComponent.defaultProps = {
  closeModal() {},
  linkText: '',
  openModal() {},
  pageComponentIndex: '0',
}

export default ContentModalComponent

export const BACK_TEXT = 'Go Back'
export const BACK_TO_MODULE_TEXT = 'Back to Module Overview'
export const FEEDBACK_LINK_TEXT = 'Provide feedback about this'
export const FEEDBACK_LINK_TEXT_BREAK = 'microlearning '
export const MARK_COMPLETE_TEXT = 'Mark Complete'
export const MODAL_HEADER_TEXT = 'Mark complete with empty responses?'
export const MODAL_TEXT = 'Some questions are unanswered.'
export const NOT_MARKED_COMPLETE_HEADER =
  "You've reached the end of this microlearning."
export const NOT_MARKED_COMPLETE_TEXT =
  "When you've finished the questions, mark it complete."
export const MARKED_COMPLETE_HEADER = 'Microlearning Complete'
export const MARK_AS_COMPLETE_BUTTON = 'mark-as-complete-button'

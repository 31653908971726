import React from 'react'

import PropTypes from 'prop-types'
import { MeetingIcon } from 'secondstep-components'

import {
  H1,
  H2,
  H3,
  H3HeightWrapper,
  H3Wrapper,
  H4,
  Wrapper,
} from './component.styles'
import {
  DATA_TEST_ID_PREFIX,
  DATA_TEST_ID_PREFIX_H3_WRAPPER,
  DATA_TEST_ID_PREFIX_ICON,
} from './constants'

const HeaderComponent = ({
  h1,
  h2,
  h3,
  h4,
  id,
  pageComponentIndex,
  displayForMeeting,
  h1Background,
  h3Background,
}) => {
  const dataTestId = `${DATA_TEST_ID_PREFIX}${pageComponentIndex}-${id}`
  const h3WrapperDataTestId = `${DATA_TEST_ID_PREFIX_H3_WRAPPER}${pageComponentIndex}-${id}`
  const iconDataTestId = `${DATA_TEST_ID_PREFIX_ICON}${pageComponentIndex}-${id}`
  return (
    <Wrapper dataTestId={dataTestId} key={dataTestId}>
      {h1 && (
        <H1
          backgroundColor={h1Background}
          className={displayForMeeting ? 'highlighted' : ''}
        >
          {h1}
          {displayForMeeting && <MeetingIcon dataTestId={iconDataTestId} />}
        </H1>
      )}
      {h2 && <H2>{h2}</H2>}
      {h3 && (
        <H3HeightWrapper>
          <H3Wrapper
            backgroundColor={h3Background}
            dataTestId={h3WrapperDataTestId}
          >
            <H3>{h3}</H3>
          </H3Wrapper>
        </H3HeightWrapper>
      )}
      {h4 && <H4>{h4}</H4>}
    </Wrapper>
  )
}

HeaderComponent.propTypes = {
  displayForMeeting: PropTypes.bool,
  h1: PropTypes.string,
  h1Background: PropTypes.string,
  h2: PropTypes.string,
  h3: PropTypes.string,
  h3Background: PropTypes.string,
  h4: PropTypes.string,
  id: PropTypes.string,
  pageComponentIndex: PropTypes.string,
}

HeaderComponent.defaultProps = {
  pageComponentIndex: '0',
}

export default HeaderComponent

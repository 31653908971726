import React from 'react'

import PropTypes from 'prop-types'

import {
  BodyCopyStyled,
  ButtonsWrapper,
  ErrorBodyCopy,
  Header,
  HeaderWrapper,
  InnerWrapper,
  StyledContainedButton,
  StyledYieldIcon,
  Wrapper,
} from './component.styles'
import {
  BACK,
  DATA_TEST_ID_BACK_BUTTON,
  DATA_TEST_ID_DIAGNOSTIC,
  DATA_TEST_ID_FRIENDLY_MESSAGE,
  DATA_TEST_ID_HEADER,
  DATA_TEST_ID_ICON,
  DATA_TEST_ID_TRY_AGAIN_BUTTON,
  HEADER,
  HEADER_NO_RETRY,
  TRY_AGAIN,
} from './constants'

const ErrorMessage = ({
  diagnosticMessage,
  friendlyMessage,
  navigateToDashboard,
  serviceDetails,
  serviceName,
  shouldTryAgain,
  tryAgain,
}) => {
  return (
    <Wrapper>
      <InnerWrapper>
        <HeaderWrapper>
          <StyledYieldIcon dataTestId={DATA_TEST_ID_ICON} />
          <Header dataTestId={DATA_TEST_ID_HEADER}>
            {shouldTryAgain ? HEADER : HEADER_NO_RETRY}
          </Header>
        </HeaderWrapper>
        <ButtonsWrapper>
          {shouldTryAgain && (
            <StyledContainedButton
              dataTestId={DATA_TEST_ID_TRY_AGAIN_BUTTON}
              onClick={tryAgain}
            >
              {TRY_AGAIN}
            </StyledContainedButton>
          )}
          <StyledContainedButton
            dataTestId={DATA_TEST_ID_BACK_BUTTON}
            onClick={navigateToDashboard}
          >
            {BACK}
          </StyledContainedButton>
        </ButtonsWrapper>
        <BodyCopyStyled data-testid={DATA_TEST_ID_FRIENDLY_MESSAGE}>
          {friendlyMessage}
        </BodyCopyStyled>
        <ErrorBodyCopy data-testid={DATA_TEST_ID_DIAGNOSTIC}>
          {diagnosticMessage}
        </ErrorBodyCopy>
        {serviceName && <ErrorBodyCopy>{serviceDetails}</ErrorBodyCopy>}
      </InnerWrapper>
    </Wrapper>
  )
}

ErrorMessage.propTypes = {
  diagnosticMessage: PropTypes.string,
  friendlyMessage: PropTypes.string,
  navigateToDashboard: PropTypes.func,
  serviceDetails: PropTypes.string,
  serviceName: PropTypes.string,
  shouldTryAgain: PropTypes.bool,
  tryAgain: PropTypes.func,
}

ErrorMessage.defaultProps = {
  navigateToDashboard() {},
  tryAgain() {},
}

export default ErrorMessage

import actions from 'store/actions'

import { default as cmiTypes } from './types'

const fetchingActions = actions.createFetchingActions(cmiTypes)
const updatingActions = actions.createUpdatingActions(cmiTypes)
const updatingWithRetriesActions = actions.createUpdatingWithRetriesActions(
  cmiTypes,
)

export default {
  ...fetchingActions,
  ...updatingActions,
  ...updatingWithRetriesActions,
}

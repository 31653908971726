import { default as interactivesSelectors } from '.'

const answerValue = ({ interactives }, microLearningId, interactiveId) =>
  interactivesSelectors.interactive(
    { interactives },
    microLearningId,
    interactiveId,
  )?.answerValue
const selections = ({ interactives }, microLearningId, interactiveId) =>
  interactivesSelectors.interactive(
    { interactives },
    microLearningId,
    interactiveId,
  )?.selections
const response = ({ interactives }, microLearningId, interactiveId) =>
  interactivesSelectors.interactive(
    { interactives },
    microLearningId,
    interactiveId,
  )?.response

export default {
  answerValue,
  response,
  selections,
}

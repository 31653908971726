/**
 * A custom fetch function that interprets network errors as if they
 * were HTTP errors. See https://github.com/agraboso/redux-api-middleware/issues/257
 * @param {RequestInfo} input
 * @param {RequestInit} init
 * @returns {Promise<Response>}
 */
export async function fetchWithNetworkError(input, init = undefined) {
  try {
    const realResponse = await fetch(input, init)
    return realResponse
  } catch (error) {
    return {
      ok: false,
      status: -1,
      statusText: 'Network Error',
    }
  }
}

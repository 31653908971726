import palette from './palette'

export default {
  // Grommet themeGet backwards compatibility
  accordion: {
    icon: palette.teal4,
    panelColor: palette.teal1,
    title: palette.black1,
  },
  autopopSurveyAccordion: {
    panelColor: palette.white1,
  },
  bodyCopy: palette.black1,
  bulletMarkerColor: palette.teal7,
  buttons: {
    primaryBackground: palette.white1,
  },
  disabledButtons: {
    color: palette.white1,
    primaryBackground: palette.gray8,
  },
  errorMessage: {
    backgroundColor: palette.gray9,
    button: {
      border: palette.teal4,
      color: palette.white1,
    },
    hover: {
      background: palette.black1,
      color: palette.white1,
    },
  },
  header: {
    backgroundColor: palette.gray7,
  },
  heading: {
    level: {
      1: {
        color: palette.black1,
      },
      2: {
        color: palette.black1,
      },
      3: {
        backgroundColor: palette.teal6,
        color: palette.white1,
      },
      4: {
        color: palette.black1,
      },
    },
  },
  highlight: {
    header: palette.teal4,
    autopop: {
      backgroundColor: palette.teal1,
      titleBackgroundColor: palette.teal4,
    },
    leader: {
      backgroundColor: palette.gray1,
      titleBackgroundColor: palette.purple1,
    },
  },
  input: {
    textarea: {
      background: palette.white1,
      borderDisabled: palette.gray3,
      borderEnabled: palette.teal3,
      focus: palette.teal4,
      hover: palette.teal4,
      status: palette.gray6,
      statusAlert: palette.red1,
    },
  },
  label: '#555',
  links: {
    color: palette.teal6,
    hoverColor: palette.black1,
  },
  loadingScreen: {
    background: palette.teal1,
    icon: palette.teal6,
    text: palette.black1,
  },
  markComplete: {
    borderColor: palette.white1,
    borderColor2: palette.teal6,
    feedbackLink: palette.teal8,
    fontColor: palette.black1,
    hover: {
      color: palette.black2,
    },
  },
  modal: {
    backgroundColor: palette.white1,
    closeButton: {
      color: palette.teal4,
      hoverColor: palette.black1,
    },
    modalButton: {
      background: palette.teal4,
      color: palette.white1,
    },
    overlay: {
      backgroundColor: palette.gray4,
    },
  },
  multiSelectQuestion: {
    backgroundColor: palette.teal4,
    borderColor: palette.teal4,
    questionText: palette.black1,
    hover: palette.teal2,
    focus: palette.accent1,
  },
  navFooter: {
    backgroundColor: palette.white1,
    backgroundColorComplete: palette.teal4,
    hoverBackgroundColor: palette.white1,
    hoverBackgroundColorComplete: palette.black1,
    hoverColor: palette.black1,
    hoverColorComplete: palette.white1,
    hoverStrokeColor: palette.black1,
    hoverStrokeColorComplete: palette.white1,
    strokeColor: palette.teal4,
    strokeColorComplete: palette.white1,
  },
  navHeader: {
    background: palette.white1,
    borderBottom: palette.gray2,
    color: palette.teal6,
    buttonText: palette.teal6,
    secondaryItemsColor: palette.black2,
    hover: {
      polyline: palette.white1,
    },
    navHeaderWrapper: {
      background: palette.teal1,
    },
  },
  page: {
    backgroundColor: palette.white1,
    color: palette.black1,
  },
  pullQuote: {
    backgroundColor: palette.teal1,
    color: palette.black1,
    gradient: {
      bottom: palette.white1,
      top: palette.gray7,
    },
  },
  scoreCard: {
    autopopBackgroundColor: palette.teal1,
    backgroundColor: palette.white1,
    hr: palette.teal3,
  },
  singleSelectQuestion: {
    primaryBackground: palette.white1,
    backgroundColor: palette.teal2,
    questionText: palette.black1,
    focus: palette.teal4,
  },
  survey: {
    calloutInner: palette.white1,
    calloutOuter: palette.teal1,
    hr: palette.teal3,
  },
  textButton: {
    color: palette.teal5,
    hoverColor: palette.black1,
  },
  transcript: {
    color: palette.teal6,
    outline: palette.blue,
  },
}

import env from 'config/build'

export const DIRECTION_NEXT = 'Next'
export const DIRECTION_PREVIOUS = 'Previous'
export const IS_LMS_LOCAL_DEVELOPMENT = env?.isLmsLocalDevelopment
export const IS_CYPRESS_MOCK_LMS = env?.isCypressMockLms
export const LAUNCH_MODE = env?.launchMode
export const NAVIGATION_DIRECTION_ERROR = 'Navigation direction error'
export const SCO_DATA = env?.scoData
export const FOOTER_TEXT = "You've reached the end of this unit."

/* eslint-disable */
import {
  APP_VERSION,
  BUILD_TYPE,
  CFC_TOKEN,
  CONTENTFUL_ENDPOINT,
  CREATE_API_CONNECTION,
  GOOGLE_ANALYTICS_FILTER_PROPERTIES,
  GOOGLE_ANALYTICS_TRACKING_ID,
  HOST_DOMAIN,
  LAUNCH_MODE,
  NODE_ENV,
  SCO_DATA,
  SHARED_DOMAIN_SETTINGS,
  LAUNCH_MODES,
  SUBDOMAIN,
} from './constants'

import { hasParentApiInstance } from 'utils/api'

export const LD_CREDS = {
  clientSideID: process.env.LD_CLIENT_ID,
  user: {
    email: 'fakeScrollablePlayer@notrealdomain.or',
    kind: 'user',
    key: 'default_fake_scrollable_player_user_key',
    name: 'Default Fake Scrollable Player User',
  },
}

const environmentConfigs = {
  default: {
    domains: {
      // uses Contentful dev Azure function using subdomain and host domain defined in process.env, or prod values if not defined
      CONTENTFUL_FUNC_APP: `https://func-apps${SUBDOMAIN}-contentful-func.${HOST_DOMAIN}/api/${CONTENTFUL_ENDPOINT}`,
      ...SHARED_DOMAIN_SETTINGS,
    },
  },
  localContentful: {
    domains: {
      //uses local instance of Contentful dev function
      CONTENTFUL_FUNC_APP: `/api/${CONTENTFUL_ENDPOINT}`,
      ...SHARED_DOMAIN_SETTINGS,
    },
  },
}

const environmentName = BUILD_TYPE
const env = environmentConfigs[environmentName] || environmentConfigs.default
env.appVersion = APP_VERSION
env.createApiConnection = CREATE_API_CONNECTION
env.environment = environmentName //Append the environment name to the env object
env.googleAnalyticsFilterProperties = GOOGLE_ANALYTICS_FILTER_PROPERTIES
env.googleAnalyticsTrackingId = GOOGLE_ANALYTICS_TRACKING_ID
env.isLmsLocalDevelopment = LAUNCH_MODE === LAUNCH_MODES.LMS_LOCAL_DEVELOPMENT
env.isStandalone =
  LAUNCH_MODE === LAUNCH_MODES.STANDALONE || !hasParentApiInstance()
env.isCypressMockLms = !!window.parent.cypressMockLms
env.nodeEnv = NODE_ENV //Append the node environment name to the env object
env.scoData = SCO_DATA
env.userJwt = CFC_TOKEN
env.ldCreds = LD_CREDS

export default env

import env from 'config/build'
import { isFieldTestMode } from 'utils/player'

export const navigateBack = moduleId => () => {
  const sela = 'sel-for-adults'
  const fieldTest = 'sel-for-adults-field-test'
  const slug = isFieldTestMode() ? fieldTest : sela
  const environment = env?.domains?.LEARN_DOMAIN
  parent.window.open(
    `${environment}/product/${slug}/modules/${moduleId}`,
    '_self',
  )
  if (moduleId === undefined) {
    console.log('no module is available in localhost so backbutton cannot work')
  }
}

import env from 'config/build'
import { SUBDOMAIN } from 'config/constants'
import { contentfulApi } from 'lib/api'
import permissions from 'lib/permissions'
import { PROD_NODE_ENVIRONMENT_NAME } from 'lib/permissions/constants'
import { getUrlParams } from 'utils/url'

import {
  CONTENTFUL_PREVIEW_DOMAIN,
  CONTENTFUL_PROD_DOMAIN,
  NODE_ENV,
} from './constants'

const getEntry = (entryId, options) => async dispatch => {
  const { environment, format, include, locale } = options || {}
  const { token: contentfulToken, cache, preview } = getUrlParams() || {}
  const permissionedEnvironment = getPermissionedEnvironment(
    environment,
    preview,
  )
  const api = contentfulApi(env?.userJwt || contentfulToken)
  const rsaaAction = await api.getEntry(
    entryId,
    cache,
    permissionedEnvironment,
    format,
    include,
    locale,
    preview,
  )
  dispatch(rsaaAction)
}

//If contentful prod env is requested, running prod build, and preview not requested, use the Contentful prod
//domain. Otherwise use Contentful preview domain.
const getPermissionedEnvironment = (
  requestedEnviroment = NODE_ENV,
  requestedPreview,
) => {
  const contentfulProdEnvironmentIsRequested =
    requestedEnviroment === PROD_NODE_ENVIRONMENT_NAME ||
    SUBDOMAIN.endsWith('-staging')
  const hasPermissionToContentfulProdEnvironment = permissions.contentfulEnvironments.includes(
    CONTENTFUL_PROD_DOMAIN,
  )
  const permissionedEnvironment =
    contentfulProdEnvironmentIsRequested &&
    hasPermissionToContentfulProdEnvironment &&
    !requestedPreview
      ? CONTENTFUL_PROD_DOMAIN
      : CONTENTFUL_PREVIEW_DOMAIN

  return permissionedEnvironment
}

export default {
  getEntry,
}

import env from 'config/build'
import { ERROR_TYPES } from 'config/constants'

const LOCALES = {
  AUSTRALIAN: {
    name: 'Australian',
    code: 'en-AU',
  },
  ENGLISH: {
    name: 'English',
    code: 'en-US',
  },
  SPANISH: {
    name: 'Spanish',
    code: 'es-US',
  },
}
export const { CONTENTFUL_FUNC_APP, CONTENTFUL_PREVIEW_DOMAIN } =
  env.domains || {}
export const AUTH_TOKEN_TYPE = 'Bearer'
const DEFAULT_ENVIRONMENT = CONTENTFUL_PREVIEW_DOMAIN
const DEFAULT_ERROR_MESSAGE =
  'Error: There was a problem performing the Contentful RSAA API action.'
// Error code 101 is a SCORM standard for general exceptions.
const DEFAULT_STATUS = '101'
const DEFAULT_STATUS_TEXT = 'General Contentful API exception'
const ERROR_TYPE = ERROR_TYPES.CONTENTFUL_API_ERROR
export const DEFAULT_ERROR = {
  message: DEFAULT_ERROR_MESSAGE,
  name: ERROR_TYPE,
  status: DEFAULT_STATUS,
  statusText: DEFAULT_STATUS_TEXT,
}
const DEFAULT_FORMAT = true
const DEFAULT_INCLUDE = 4 //max allowed by the Contentful function is 10
const DEFAULT_LOCALE = LOCALES.ENGLISH.code
const DEFAULT_PREVIEW = true
export const DEFAULT_PARAMS = {
  ENVIRONMENT: DEFAULT_ENVIRONMENT,
  FORMAT: DEFAULT_FORMAT,
  INCLUDE: DEFAULT_INCLUDE,
  LOCALE: DEFAULT_LOCALE,
  PREVIEW: DEFAULT_PREVIEW,
}

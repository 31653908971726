import AudioComponent from 'components/Audio'
import AutoPopulate from 'components/AutoPopulate'
import AutoPopulateSurvey from 'components/AutoPopulateSurvey'
import BodyCopy from 'components/BodyCopy'
import ContentModal from 'components/ContentModal'
import Header from 'components/Header'
import HighlightedContentBox from 'components/HighlightedContent'
import Image from 'components/Image'
import ImageBodyCopy from 'components/ImageBodyCopy'
import LessonAccordion from 'components/LessonAccordion'
import MediaBodyCopy from 'components/MediaBodyCopy'
import MultiSelectQuestion from 'components/MultiSelectQuestion'
import PullQuote from 'components/PullQuote'
import Scorecard from 'components/Scorecard'
import SingleSelectQuestion from 'components/SingleSelectQuestion'
import Survey from 'components/Survey'
import TextEntry from 'components/TextEntry'
import TextLinkWithIcon from 'components/TextLinkWithIcon'
import VideoComponent from 'components/Video'

// Object for mapping content ids to their corresponding React components
const ACCORDION = 'accordion'
const HIGHLIGHT = 'highlight'
export const AUTOPOPULATE = 'autopopulate'
export const AUTOPOPULATE_TYPES = {
  interactivemcms: MultiSelectQuestion,
  interactivesingleselect: SingleSelectQuestion,
  interactivesurvey: AutoPopulateSurvey,
  interactivetextentry: TextEntry,
}
export const CONTENT_TYPES = {
  [ACCORDION]: LessonAccordion,
  [AUTOPOPULATE]: AutoPopulate,
  [HIGHLIGHT]: HighlightedContentBox,
  audio: AudioComponent,
  bodycopy: BodyCopy,
  contentmodal: ContentModal,
  h1: Header,
  h2: Header,
  h3: Header,
  h4: Header,
  image: Image,
  imagebodycopy: ImageBodyCopy,
  interactivemcms: MultiSelectQuestion,
  interactivesingleselect: SingleSelectQuestion,
  interactivesurvey: Survey,
  interactivetextentry: TextEntry,
  mediabodycopy: MediaBodyCopy,
  quote: PullQuote,
  scorecard: Scorecard,
  textlinkicon: TextLinkWithIcon,
  video: VideoComponent,
}
export const HEADER_BODY_COPY = 'headerbodycopy'
export const HEADER_BODY_COPY_CONTENT_TYPES = {
  BODY_COPY: 'bodycopy',
  H2: 'h2',
  H3: 'h3',
}
// Some elements need to be collected and dropped in to a React component at the end.  Ex. Accordion
export const COLLECTION_TYPES = [ACCORDION, HIGHLIGHT]

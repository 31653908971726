import React from 'react'

import palette from 'lib/theme/palette'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { selectors as appSelectors } from 'store/app'
import { LAYOUT_TYPES } from 'store/app/constants'

import HeaderComponent from './component'

const HeaderContainer = ({ h1, h2, h3, h4, id, pageComponentIndex }) => {
  const microlearningType = useSelector(appSelectors.getMicrolearningType)
  const displayForMeeting = microlearningType !== LAYOUT_TYPES.INDIVIDUAL
  const h3Background =
    microlearningType === LAYOUT_TYPES.LEADER_GROUP_MEETING
      ? palette.purple1
      : null
  const h1Background =
    microlearningType === LAYOUT_TYPES.LEADER_GROUP_MEETING
      ? palette.gray1
      : null

  return (
    <HeaderComponent
      displayForMeeting={displayForMeeting}
      h1={h1}
      h1Background={h1Background}
      h2={h2}
      h3={h3}
      h3Background={h3Background}
      h4={h4}
      id={id}
      pageComponentIndex={pageComponentIndex}
    />
  )
}

HeaderContainer.propTypes = {
  h1: PropTypes.string,
  h2: PropTypes.string,
  h3: PropTypes.string,
  h4: PropTypes.string,
  id: PropTypes.string,
  pageComponentIndex: PropTypes.string,
}

export default HeaderContainer

import styled from 'styled-components'

export const Wrapper = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  margin: 2.5rem 0;
`

export const Row = styled.div`
  display: flex;

  ${({ theme }) => theme?.breakpoints?.mobile} {
    flex-direction: column;
  }
`

export const Column = styled.div`
  div[data-testid='ratio-wrapper-content'] {
    margin: 0;
  }
  p {
    margin: 0;
  }
  ul {
    margin: 0;
  }
  li {
    margin: 0;
  }
  width: 50%;
  &:last-child {
    margin-left: 1.75rem;
  }

  ${({ theme }) => theme?.breakpoints?.mobile} {
    &:last-child {
      margin-top: 0.8rem;
      margin-left: 0;
    }

    width: 100%;
  }
`

import { breakpoints } from './breakpoints'
import colors from './colors'
import fonts from './fonts'
import fontWeights from './fontWeights'

export default {
  breakpoints,
  colors,
  fonts,
  fontWeights,
}

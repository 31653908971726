export const getAutoPopSourceIdsFromMicroLearning = microLearning => {
  const { content = [] } = microLearning || {}
  const refIds = []

  content.forEach(item => {
    const { sourceMicrolearning: id } = item

    if (!id) {
      return
    }

    const hasId = refIds.includes(id)

    !hasId && refIds.push(id)
  })

  return refIds
}

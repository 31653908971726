import React from 'react'

import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { selectors as appSelectors } from 'store/app'
import { selectors as interactivesSelectors } from 'store/interactives'
import {
  getFormattedCategories,
  getMaxPossibleScores,
  getTotalAnswered,
} from 'utils/survey'

import SurveyComponent from './component'

const SurveyContainer = ({
  categories,
  header,
  id,
  introText,
  pageComponentIndex,
  questionItems,
  scorecardIntroText,
  sourceMicroLearning,
}) => {
  const interactives = useSelector(interactivesSelectors.interactives)
  const primaryMicroLearningId = useSelector(appSelectors.contentfulId)
  const microLearningId = sourceMicroLearning || primaryMicroLearningId
  const formattedCategories = getFormattedCategories(
    microLearningId,
    categories,
    questionItems,
    interactives,
  )
  const maxPossibleScores = getMaxPossibleScores(questionItems)
  const totalAnswered = getTotalAnswered(
    microLearningId,
    questionItems,
    interactives,
  )
  const totalQuestions = questionItems?.length

  return (
    <SurveyComponent
      categories={formattedCategories}
      header={header}
      id={id}
      introText={introText}
      maxPossibleScores={maxPossibleScores}
      pageComponentIndex={pageComponentIndex}
      questionItems={questionItems}
      scorecardIntroText={scorecardIntroText}
      totalAnswered={totalAnswered}
      totalQuestions={totalQuestions}
    />
  )
}

SurveyContainer.propTypes = {
  categories: PropTypes.array,
  header: PropTypes.string,
  id: PropTypes.string,
  introText: PropTypes.string,
  pageComponentIndex: PropTypes.string,
  questionItems: PropTypes.array,
  scorecardIntroText: PropTypes.string,
  sourceMicroLearning: PropTypes.string,
}

SurveyContainer.defaultProps = {
  categories: [],
  pageComponentIndex: '0',
  questionItems: [],
}

export default SurveyContainer

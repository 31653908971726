import React, { useEffect } from 'react'

import PropTypes from 'prop-types'
import {
  addModalToDom,
  createModalElement,
  removeModalFromDom,
} from 'utils/modal'

import CloseableModalComponent from './component'

const CloseableModalContainer = ({ children, onClose, show }) => {
  const domElement = createModalElement()

  useEffect(() => {
    addModalToDom(domElement)

    const cleanup = () => removeModalFromDom(domElement)

    return cleanup
  }, [])

  return (
    <CloseableModalComponent
      domElement={domElement}
      onClose={onClose}
      show={show}
    >
      {children}
    </CloseableModalComponent>
  )
}

CloseableModalContainer.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
  show: PropTypes.bool,
}

CloseableModalContainer.defaultProps = {
  onClose() {},
}

export default CloseableModalContainer

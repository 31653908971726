import React from 'react'

import PropTypes from 'prop-types'

import ImageComponent from './component'

const ImageContainer = props => {
  const { image, accessibility } = props || {}
  const alt = accessibility?.altTextShort
  const srcUrl = `https:${image?.file?.url}`
  return <ImageComponent alt={alt} src={srcUrl} />
}
ImageContainer.defaultProps = {}
ImageContainer.propTypes = {
  accessibility: PropTypes.object,
  image: PropTypes.object,
}

export default ImageContainer

import { FormInputRadioButton, FormInputTextArea } from 'secondstep-components'
import styled from 'styled-components'

export const SingleSelectWrapper = styled.div.attrs(({ ariaLabelledBy }) => ({
  'aria-labelledby': ariaLabelledBy,
  tabIndex: 0,
}))`
  display: flex;
  flex-direction: column;
  margin: 1.75rem 0;

  ${({ theme }) => theme?.breakpoints?.mobile} {
    margin: 1.25rem 0;
    p,
    ul,
    ol,
    span {
      font-size: 1rem;
    }
  }
`

export const SingleSelectQuestionText = styled.span`
  font-family: ${({ theme }) =>
    theme?.fonts?.singleSelectQuestion?.questionText?.family};
  font-weight: ${({ theme }) =>
    theme?.fonts?.singleSelectQuestion?.questionText?.weight};
  margin-bottom: 0.5rem;
`

export const SingleSelectRadioButton = styled(FormInputRadioButton)``

export const SingleSelectTextArea = styled(FormInputTextArea)`
  margin: 0.5625rem 0 0 1.9375rem;
  // Prevents Grommet TextArea from overflowing to the right and ignoring the parent's paddingRight
  width: auto;
`

import React from 'react'

import ModalCloseButton from 'components/ModalCloseButton'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import { createModalElement } from 'utils/modal'

import { ModalInnerWrapper, StyledModal } from './component.styles'
import { DATA_TEST_ID, DATA_TEST_ID_CLOSE_BUTTON } from './constants'

const CloseableModalComponent = ({ children, domElement, onClose, show }) => {
  const modal = (
    <StyledModal
      dataTestId={DATA_TEST_ID}
      isModalOpen={show}
      modalClose={onClose}
    >
      <ModalCloseButton
        dataTestId={DATA_TEST_ID_CLOSE_BUTTON}
        onClick={onClose}
      />
      <ModalInnerWrapper>{children}</ModalInnerWrapper>
    </StyledModal>
  )
  const component = ReactDOM.createPortal(modal, domElement)

  return component
}

CloseableModalComponent.propTypes = {
  children: PropTypes.node,
  domElement: PropTypes.object,
  onClose: PropTypes.func,
  show: PropTypes.bool,
}

CloseableModalComponent.defaultProps = {
  domElement: createModalElement(),
  onClose() {},
}

export default CloseableModalComponent

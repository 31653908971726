import actions from 'store/contentful/actions'
import { default as rsaaActions } from 'store/rsaa/actions'
import { fetchWithNetworkError } from 'utils/fetchWithNetworkErrors'
import { createUrlWithParams } from 'utils/url'

import { HTTP_METHODS } from '../constants'

import {
  AUTH_TOKEN_TYPE,
  CONTENTFUL_FUNC_APP,
  DEFAULT_PARAMS,
} from './constants'
import { default as contentfulPayloads } from './payloads'

const contentfulApi = authToken => {
  const getRsaaAction = async (entryId, url) => {
    const authorization = `${AUTH_TOKEN_TYPE} ${authToken}`
    const fetchPayload = contentfulPayloads.createFetchingPayloadHandler(
      entryId,
    )
    const fetchSuccessPayload = contentfulPayloads.createFetchingSuccessPayloadHandler(
      entryId,
    )
    const fetchErrorPayload = contentfulPayloads.createFetchingErrorPayloadHandler(
      entryId,
    )
    const action = {
      endpoint: url,
      headers: {
        Authorization: authorization,
      },
      method: HTTP_METHODS.GET,
      types: [
        actions.fetching(fetchPayload),
        actions.fetchingSuccess(fetchSuccessPayload),
        actions.fetchingError(fetchErrorPayload),
      ],
      fetch: fetchWithNetworkError,
    }

    return rsaaActions.createAction(action)
  }

  return {
    baseApiAddress: CONTENTFUL_FUNC_APP,
    getEntry: async (
      entryId,
      cache,
      environment,
      format,
      include,
      locale,
      preview,
    ) => {
      const paramsObject = {
        entryId,
        ...setDefaults(cache, environment, format, include, locale, preview),
      }
      const url = createUrlWithParams(CONTENTFUL_FUNC_APP, paramsObject)
      const response = await getRsaaAction(entryId, url)

      return response
    },
  }
}

//If any params are not defined, supply default values
const setDefaults = (cache, environment, format, include, locale, preview) => {
  const defaultedFormat = format != null ? format : DEFAULT_PARAMS.FORMAT
  const defaultedInclude = include != null ? include : DEFAULT_PARAMS.INCLUDE
  const defaultedLocale = locale || DEFAULT_PARAMS.LOCALE
  const defaultedEnvironment = environment || DEFAULT_PARAMS.ENVIRONMENT
  const defaultedPreview = preview != null ? preview : DEFAULT_PARAMS.PREVIEW
  const defaultedParams = {
    environment: defaultedEnvironment,
    format: defaultedFormat,
    include: defaultedInclude,
    locale: defaultedLocale,
    preview: defaultedPreview,
  }

  if (cache != null) {
    defaultedParams.cache = cache
  }

  return defaultedParams
}

export default contentfulApi

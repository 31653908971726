import React, { Component } from 'react'

import PropTypes from 'prop-types'

import ErrorBoundaryComponent from './component'

class ErrorBoundaryContainer extends Component {
  static getDerivedStateFromError = error => {
    // Update state so the next render will show the fallback UI.
    return { error, hasError: true }
  }

  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch = (error, info) => {
    console.error(error, info)
    this.setState({ error, info })
  }

  render() {
    const { children, message, suppress } = this.props
    const { error, hasError, info } = this.state || {}
    const { componentStack } = info || {}
    const disabled = !hasError || suppress

    return (
      <ErrorBoundaryComponent
        disabled={disabled}
        error={error && error.toString()}
        message={message}
        stackInfo={componentStack}
      >
        {children}
      </ErrorBoundaryComponent>
    )
  }
}

ErrorBoundaryContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  message: PropTypes.string,
  suppress: PropTypes.bool,
}

ErrorBoundaryContainer.defaultProps = {
  suppress: false,
}

export default ErrorBoundaryContainer

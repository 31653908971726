import React from 'react'

import { default as modalHooks } from 'hooks/useModal'
import ContentMapper from 'lib/contentMapper'
import PropTypes from 'prop-types'

import ContentModalComponent from './component'

const ContentModalContainer = ({ content, linkText, pageComponentIndex }) => {
  const { openModal, closeModal, isModalOpen } = modalHooks.useModal()
  const contentMapper = new ContentMapper()
  const contentJsx = contentMapper.mapToComponents(content)

  return (
    <ContentModalComponent
      closeModal={closeModal}
      isModalOpen={isModalOpen}
      linkText={linkText}
      openModal={openModal}
      pageComponentIndex={pageComponentIndex}
    >
      {contentJsx}
    </ContentModalComponent>
  )
}

ContentModalContainer.propTypes = {
  content: PropTypes.array,
  linkText: PropTypes.string,
  pageComponentIndex: PropTypes.string,
}

ContentModalContainer.defaultProps = {
  linkText: '',
  pageComponentIndex: '0',
}

export default ContentModalContainer

import { H3HeightWrapper, H3Wrapper } from 'components/Header/component.styles'
import styled from 'styled-components'

import { AUTOPOP_HIGHLIGHT } from './constants'

export const HighlightWrapper = styled.div.attrs(
  ({ highlightType, theme, dataTestId }) => ({
    backgroundColor:
      highlightType === AUTOPOP_HIGHLIGHT
        ? theme?.colors?.highlight?.autopop?.backgroundColor
        : theme?.colors?.highlight?.leader?.backgroundColor,
    'data-testid': dataTestId,
  }),
)`
  border-top: 2px solid ${({ theme }) => theme?.colors?.highlight?.header};
  border-bottom: 2px solid ${({ theme }) => theme?.colors?.highlight?.header};
  background-color: ${({ backgroundColor }) => backgroundColor};
  margin: 3.75rem -2.5rem 2.5rem;
  padding: 0 2.5rem 2.5rem;

  // Removes margin for last item in the highlight content so padding can be set
  div > div:last-child {
    margin-bottom: 0rem;
  }

  ${({ theme }) => theme?.breakpoints?.mobile} {
    margin: 2rem -1.25rem 1.25rem;
    padding: 0 1.25rem 1.25rem;
  }
`

export const HighlightContent = styled.div``

//TODO: LEARN-9184 refactor or create new Header component that is friendlier to styling changes
export const TitleHeightWrapper = styled(H3HeightWrapper)`
  margin-top: -1.25rem;

  // H3HeightWrapper has a breakpoint which will override the margin-top value above unless this is here
  ${({ theme }) => theme?.breakpoints?.mobile} {
    margin-top: -1.25rem;
  }
`
export const TitleWrapper = styled(H3Wrapper).attrs(
  ({ highlightType, theme }) => ({
    titleBackgroundColor:
      highlightType === AUTOPOP_HIGHLIGHT
        ? theme?.colors?.highlight?.autopop?.titleBackgroundColor
        : theme?.colors?.highlight?.leader?.titleBackgroundColor,
  }),
)`
  background-color: ${({ titleBackgroundColor }) => titleBackgroundColor};
  border-radius: 0 6.25rem 6.25rem 0;
  display: inline-block;
  padding-left: 2.5rem;
  padding-right: 1rem;
`

import { ENDPOINTS, HTTP_METHODS } from 'lib/api/constants'
import CmiDataMapper from 'lib/cmiDataMapper'
import { CMI_KEYS } from 'store/cmi/constants'
import { createDiagnostic } from 'utils/api'

import ApiConnector from './ApiConnector'
import { LMS_API_BASE_ADDRESS } from './constants'

const cmiDataMapper = new CmiDataMapper()

// lmsApi wraps around an instance of APIConnector.

const lmsApi = async scoId => {
  const apiConnector = new ApiConnector(scoId)
  const apiClient = await apiConnector.getApiClient(scoId)

  const getValue = async elem => {
    let result

    try {
      const isSuspendData = elem === CMI_KEYS.SUSPEND_DATA
      const response = await apiClient.GetValue(elem)
      const formattedData = isSuspendData
        ? cmiDataMapper.mapInteractivesToRedux(response)
        : response
      const diagnostic = createDiagnostic(ENDPOINTS.LMS, HTTP_METHODS.GET)
      result = { data: formattedData, diagnostic }
    } catch (error) {
      const diagnostic = createDiagnostic(
        ENDPOINTS.LMS,
        HTTP_METHODS.GET,
        error,
      )
      result = { diagnostic }
    }

    return result
  }

  const setValue = async (elem, val) => {
    const isSuspendData = elem === CMI_KEYS.SUSPEND_DATA
    let formattedData = val

    if (isSuspendData) {
      const cmiDataMapper = new CmiDataMapper()
      formattedData = cmiDataMapper.mapInteractivesToLms(val)
    }

    let diagnostic

    try {
      await apiClient.SetValue(elem, formattedData)
      diagnostic = createDiagnostic(ENDPOINTS.LMS, HTTP_METHODS.POST)
    } catch (error) {
      diagnostic = createDiagnostic(ENDPOINTS.LMS, HTTP_METHODS.POST, error)
    }

    const result = { data: val, diagnostic }

    return result
  }

  return {
    baseApiAddress: LMS_API_BASE_ADDRESS,
    getValue,
    setValue,
  }
}

export default lmsApi

import { FlagsComponentRouter } from 'flags'

import OffComponent from './flag.off/index'
import OnComponent from './flag.on/index'

const SingleSelectQuestion = FlagsComponentRouter({
  flagSubscriptions: ['feature_learn_10300_add_flag_s_player'],
  OnComponent,
  OffComponent,
})

export default SingleSelectQuestion

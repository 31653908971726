import { InlineLoading } from 'secondstep-components'
import styled from 'styled-components'

export const LoadingScreenWrapper = styled.div`
  align-items: flex-start;
  background-color: ${({ theme }) => theme?.colors?.loadingScreen?.background};
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
`

export const StyledInlineLoading = styled(InlineLoading)`
  margin-top: 12.5rem;
  font-family: LufgaCFC;
  font-weight: 600;
  font-size: 1.75rem;
  color: ${({ theme }) => theme?.colors?.loadingScreen?.text};
  svg {
    height: 1.875rem;
    width: 1.875rem;
    color: ${({ theme }) => theme?.colors?.loadingScreen?.icon};
  }
`

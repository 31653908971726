import env from 'config/build'
import { selectors as interactivesSelectors } from 'store/interactives'
import { getLayoutForMicroLearningType } from 'utils/layout'

const isStandalone = env?.isStandalone
const scoData = env?.scoData

export const createContentfulToScoTree = currentLessonId => {
  const { modules } = scoData || {}
  const contentfulToScoTree = {}

  for (let i = 0; i < modules?.length; i++) {
    const currentModule = modules[i]
    const { units } = currentModule || {}

    for (let j = 0; j < units?.length; j++) {
      const currentUnit = units[j]
      const { lessons } = currentUnit || []

      // Flatten lessons array which contain choice lessons
      const flattenedLessons = lessons?.reduce((accumArray, currentLesson) => {
        const { choiceLessons } = currentLesson || {}

        if (choiceLessons) {
          choiceLessons.forEach(choiceLesson => {
            accumArray.push(choiceLesson)
          })

          return accumArray
        }

        accumArray.push(currentLesson)
        return accumArray
      }, [])

      // Search thru flattened lessons array and match the contentful id
      for (let k = 0; k < flattenedLessons?.length; k++) {
        const currentLesson = flattenedLessons[k]

        // Found the contentfulId for the current lesson, time to build the sco tree
        if (currentLesson.contentfulId === currentLessonId) {
          const previousLesson = flattenedLessons[k - 1] || null
          const nextLesson = flattenedLessons[k + 1] || null
          // Set the root data
          // Shortcut destructuring/rest operator trick to copy all properties except the modules property into a new const named currentRootData
          const {
            // eslint-disable-next-line
            modules: removedModules,
            ...currentRootData
          } = scoData || {}
          contentfulToScoTree.root = currentRootData

          // Set indexes for all keys
          currentModule.index = i
          currentUnit.index = j
          currentLesson.index = k

          // Set indexes for previous and next lessons if they exist
          if (previousLesson) {
            previousLesson.index = k - 1
          }
          if (nextLesson) {
            nextLesson.index = k + 1
          }

          // Set data for contentfulToScoTree
          // eslint-disable-next-line
          const { units: removedUnits, ...formattedModule } = currentModule
          contentfulToScoTree.currentModule = formattedModule

          // eslint-disable-next-line
          const { lessons: removedLessons, ...formattedUnit } = currentUnit
          contentfulToScoTree.currentUnit = formattedUnit
          contentfulToScoTree.currentLesson = currentLesson
          contentfulToScoTree.previousLesson = previousLesson
          contentfulToScoTree.nextLesson = nextLesson

          return contentfulToScoTree
        }
      }
    }
  }
  // No parent window or no matching id found
  return {}
}

export const createSourceIdsDictionary = (microLearningEntryIds, exclude) => {
  const contentfulToScoIds = isStandalone
    ? getStandaloneAutoPopIdPairs(microLearningEntryIds, exclude)
    : getAutoPopIdPairs(microLearningEntryIds, exclude)

  return contentfulToScoIds
}

export const getActionData = action => {
  const { payload, type } = action || {}
  const { data, diagnostic, microLearningEntryId } = payload || {}
  const { error } = diagnostic || {}
  const actionData = {
    data,
    diagnostic,
    error,
    type,
    microLearningEntryId,
  }

  return actionData
}

// Transform and scrub app data for usage in app reducers.
export const transformAppReducerData = (state, data) => {
  const {
    interactivesCompleted: interactivesCompletedState,
    appMultimediaStatus: appMultimediaStatusState,
  } = state || {}
  const {
    interactivesCompleted: interactivesCompletedData,
    appMultimediaStatus: appMultimediaStatusData,
  } = data || {}
  //TODO LEARN-10173 remove microlearningLabel once MLs no longer use label for type checking
  const {
    microlearningType: contentfulEntryType,
    microlearningLabel,
    ...scrubbedData
  } = data || {}
  const microlearningType = contentfulEntryType
    ? getLayoutForMicroLearningType({
        microLearningType: contentfulEntryType,
        microLearningLabel: microlearningLabel,
      })
    : state.microlearningType
  const transformedData = {
    ...scrubbedData,
    microlearningType,
    interactivesCompleted: {
      ...interactivesCompletedState,
      ...interactivesCompletedData,
    },
    appMultimediaStatus: {
      ...appMultimediaStatusState,
      ...appMultimediaStatusData,
    },
  }

  return transformedData
}

// Create an object with Contentful IDs as keys, and their corresponding
// SCO IDs as the values.
export const getAutoPopIdPairs = (microLearningEntryIds = [], exclude = []) => {
  const { modules = [] } = scoData || {}
  const lessonListReducer = (accumulator, module) => {
    const { units = [] } = module || {}

    units.forEach(unit => {
      const { lessons = [] } = unit || {}

      lessons.forEach(lesson => {
        const { choiceLessons, contentfulId } = lesson || {}
        if (choiceLessons) {
          choiceLessons.forEach(choiceLesson => {
            const { contentfulId } = choiceLesson || {}

            accumulator[contentfulId] = lesson
          })
        } else {
          accumulator[contentfulId] = lesson
        }
      })
    })

    return accumulator
  }
  const lessons = modules.reduce(lessonListReducer, {})
  const contentfulToScoIdsReducer = (accumulator, contentfulId) => {
    const isExcluded = exclude.includes(contentfulId)

    if (isExcluded) {
      return accumulator
    }

    accumulator[contentfulId] = lessons[contentfulId]?.scoID

    return accumulator
  }
  const contentfulToScoIds = microLearningEntryIds.reduce(
    contentfulToScoIdsReducer,
    {},
  )

  return contentfulToScoIds
}

export const getStandaloneAutoPopIdPairs = (
  microLearningEntryIds = [],
  exclude = [],
) => {
  const fakeScoPrefix = 'standaloneScoId'
  const idPairsReducer = (accumulator, microLearningEntryId, index) => {
    const isExcluded = exclude.includes(microLearningEntryId)

    if (!isExcluded) {
      accumulator[microLearningEntryId] = `${fakeScoPrefix}${index}`
    }

    return accumulator
  }
  const autoPopIdPairs = microLearningEntryIds.reduce(idPairsReducer, {})

  return autoPopIdPairs
}

// Given a cmi state, update the state object
export const updateCmiData = (microLearningEntryId, data) => {
  if (!microLearningEntryId) {
    console.warn(
      'Error: Cannot create cmi key value object. Micro learning id not provided.',
    )

    return {}
  }

  const updatedCmiData = {
    [microLearningEntryId]: data,
  }

  return updatedCmiData
}

// Given an interactives state, update the state object with an updated interactive
export const updateInteractivesData = (
  interactives,
  microLearningId,
  interactiveId,
  newInteractiveState,
) => {
  const interactive = interactivesSelectors.interactive(
    { interactives },
    microLearningId,
    interactiveId,
  )
  const { data = {} } = interactives?.[microLearningId] || {}
  const updatedInteractives = {
    [microLearningId]: {
      ...data,
      [interactiveId]: {
        ...interactive,
        ...newInteractiveState,
      },
    },
  }

  return updatedInteractives
}

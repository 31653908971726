import React, { useEffect } from 'react'

import { default as modalHooks } from 'hooks/useModal'
import { useSelector } from 'react-redux'
import { selectors as appSelectors } from 'store/app'
import { APP_SAVING_STATUS } from 'store/app/constants'
import { refreshPage } from 'utils/browser'

import ErrorModalComponent from './component'

const ErrorModalContainer = () => {
  const appSavingStatus = useSelector(appSelectors.getAppSavingStatus)
  const { openModal, closeModal, isModalOpen } = modalHooks.useModal()

  useEffect(() => {
    if (appSavingStatus === APP_SAVING_STATUS.FAILED_TO_SAVE) {
      openModal()
    }
  }, [appSavingStatus])

  const onClose = () => {
    closeModal()
  }

  return (
    <ErrorModalComponent
      isOpen={isModalOpen}
      onClose={onClose}
      onPageRefresh={refreshPage}
    />
  )
}

export default ErrorModalContainer

import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { VideoHelper } from 'secondstepVideo'

import VideoComponent from './component.jsx'
import {
  getCaptionTrackWithSource,
  onVideoEnded,
  onVideoPlay,
  onVideoStopped,
} from './helpers.js'

const VideoContainer = props => {
  const dispatch = useDispatch()

  const [transcript, setTranscript] = useState(null)
  const [audioDescriptionTranscript, setAudioDescriptionTranscript] = useState(
    null,
  )
  const [isTranscriptActive, setIsTranscriptActive] = useState(false)
  const [isAudioDescActive, setIsAudioDescActive] = useState(false)
  const [transcriptRendered, setRenderTranscript] = useState(false)
  const [isFetchingTranscript, setIsTranscriptFetching] = useState(false)
  const [isFetchingEATranscript, setIsFetchingEATranscript] = useState(false)

  const { videoReference, hasMargin, id, pageComponentIndex } = props || {}

  const { bitmovinUrl, bitmovinUrlExtended, vttFile } = videoReference || {}
  const hasVideo = VideoHelper.hasVideoBitmovinSource(videoReference)
  const settings = VideoHelper.getSettings({
    hotKeys: {
      enableVolumeScroll: false,
    },
  })
  const poster = VideoHelper.getVideoModulePosterSource(videoReference)
  const transcriptSrc = VideoHelper.getVideoModuleTranscriptSource(
    videoReference,
  )

  const extendedAudioTranscriptSrc = VideoHelper.getVideoModuleExtendedAudioTranscriptSource(
    videoReference,
  )

  const { file } = vttFile || {}
  const { url } = file || {}

  let tracks = null
  if (url) {
    // TODO using stringify bc pass from here to VideoDataSetterContainer is not able to keep track of an array with an object in it
    tracks = JSON.stringify(getCaptionTrackWithSource(url))
  }
  const handleLoad = async () => {
    setIsTranscriptFetching(true)
    const r = await fetch(transcriptSrc)
    if (!r.ok) {
      return
    }
    const text = await r.text()
    setTranscript(text)
    setIsTranscriptFetching(false)
  }

  const handleExtendedAudioTranscriptLoad = async () => {
    setIsFetchingEATranscript(true)
    const r = await fetch(extendedAudioTranscriptSrc)
    if (!r.ok) {
      return
    }
    const text = await r.text()

    setAudioDescriptionTranscript(text)
    setIsFetchingEATranscript(false)
  }

  const shouldWaitForTranscript =
    transcriptSrc && isFetchingTranscript && !transcript
  const shoulWaitForEATranscript =
    extendedAudioTranscriptSrc &&
    isFetchingEATranscript &&
    !audioDescriptionTranscript

  useEffect(() => {
    if (!transcript && transcriptSrc && !isFetchingTranscript) {
      handleLoad()
    }
  })

  useEffect(() => {
    if (
      extendedAudioTranscriptSrc &&
      !audioDescriptionTranscript &&
      !isFetchingEATranscript
    ) {
      handleExtendedAudioTranscriptLoad()
    }
  })

  const showTranscript = () => {
    if (!transcriptRendered && transcriptSrc) {
      setRenderTranscript(true)
      setIsTranscriptActive(true)
    }
    if (transcriptRendered) {
      setRenderTranscript(false)
      setIsTranscriptActive(false)
    }
  }

  const handleClickAudioDesc = () => setIsAudioDescActive(!isAudioDescActive)
  const onPlay = onVideoPlay({ dispatch, id })
  const onStopped = onVideoStopped({ dispatch, id })
  const onEnded = onVideoEnded({ dispatch, id })
  const canShowAudioDescriptionButton = bitmovinUrlExtended !== undefined
  const canShowTranscriptButton = transcriptSrc !== undefined
  const canShowAccessibilityMenu =
    canShowAudioDescriptionButton === true || canShowTranscriptButton === true

  if (!bitmovinUrl || shouldWaitForTranscript || shoulWaitForEATranscript) {
    return null
  }

  const videoUrl = isAudioDescActive ? bitmovinUrlExtended : bitmovinUrl

  return (
    <VideoComponent
      audioDescriptionTranscript={audioDescriptionTranscript}
      canShowAccessibilityMenu={canShowAccessibilityMenu}
      canShowAudioDescriptionButton={canShowAudioDescriptionButton}
      canShowTranscriptButton={canShowTranscriptButton}
      handleClickAudioDesc={handleClickAudioDesc}
      hasMargin={hasMargin}
      hasVideoSource={hasVideo}
      id={id}
      isAudioDescActive={isAudioDescActive}
      isTranscriptActive={isTranscriptActive}
      key={videoUrl}
      onEnded={onEnded}
      onPlay={onPlay}
      onStopped={onStopped}
      pageComponentIndex={pageComponentIndex}
      poster={poster}
      settings={settings}
      showTranscript={showTranscript}
      tracks={tracks}
      transcript={transcript}
      transcriptRendered={transcriptRendered}
      videoUrl={videoUrl}
    />
  )
}

VideoContainer.defaultProps = {
  hasMargin: true,
}

VideoContainer.propTypes = {
  onEnded: PropTypes.func,
  onPlay: PropTypes.func,
  onStopped: PropTypes.func,
}

export default VideoContainer

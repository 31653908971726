const DEFAULTS = {
  BUILD_TYPE: 'default',
  CONTENTFUL_PREVIEW_DOMAIN: 'preview.contentful.com',
  CONTENTFUL_PROD_DOMAIN: 'cdn.contentful.com',
  HOST_DOMAIN: 'secondstep.org',
  NODE_ENV: 'production',
  SUBDOMAIN: '',
  TIMEOUT_INTERVAL: 900000, // 15 minutes
}

export const CONTENT_GUTTER_WIDTH = '2.5rem'
export const CONTENTFUL_ENDPOINT = 'GetContentfulResource'
export const ERROR_TYPES = {
  CONTENTFUL_API_ERROR: 'contentfulApiError',
  LMS_API_ERROR: 'lmsApiError',
  LOCAL_ERROR: 'localError',
  GENERAL_API_ERROR: 'generalApiError',
}
export const LAUNCH_MODES = {
  CONTENTFUL_LOCAL_DEVELOPMENT: 'contentfulLocalDevelopment',
  CYPRESS_MOCK_LMS: 'cypressMockLms',
  LMS_LOCAL_DEVELOPMENT: 'lmsLocalDevelopment',
  STANDALONE: 'standalone',
}
// Matches Learn service numbers so error codes are consistent intra-platform.
export const SERVICE_CODES = {
  profileError: 1,
  licenseError: 2,
  lmsError: 3,
  contentfulError: 4,
}
export const URL_PARAMS = ['cache', 'entry', 'preview', 'theme', 'token']

// process.env variables
export const APP_VERSION = process.env.APP_VERSION
export const BUILD_TYPE = process.env.BUILD_TYPE || DEFAULTS.BUILD_TYPE
export const GOOGLE_ANALYTICS_TRACKING_ID = process.env.GA_TRACKING_ID
export const HOST_DOMAIN = process.env.HOST_DOMAIN || DEFAULTS.HOST_DOMAIN
export const LAUNCH_MODE = process.env.LAUNCH_MODE
export const NODE_ENV = process.env.NODE_ENV || DEFAULTS.NODE_ENV
export const SUBDOMAIN = process.env.SUBDOMAIN || DEFAULTS.SUBDOMAIN
export const TIMEOUT_INTERVAL =
  process.env.TIMEOUT_INTERVAL || DEFAULTS.TIMEOUT_INTERVAL

// Parent window variables
const CFC_PLAYER_DATA = parent?.window?.__CFC_PLAYER_DATA__
export const CFC_TOKEN = parent?.window?.__CFC_TOKEN__ || process.env.CFC_TOKEN
export const CREATE_API_CONNECTION = parent?.window?.createApiConnection
export const SCO_DATA = CFC_PLAYER_DATA?.path
export const GOOGLE_ANALYTICS_FILTER_PROPERTIES =
  window?.parent?.__GOOGLE_ANALYTICS_FILTER_PROPERTIES__
export const SHARED_DOMAIN_SETTINGS = {
  //settings that are the same for all domains
  CONTENTFUL_PREVIEW_DOMAIN: DEFAULTS.CONTENTFUL_PREVIEW_DOMAIN,
  CONTENTFUL_PROD_DOMAIN: DEFAULTS.CONTENTFUL_PROD_DOMAIN,
  LEARN_DOMAIN: `https://learn${SUBDOMAIN}.${HOST_DOMAIN}`,
  USER_CONTEXT_DOMAIN: `https://api${SUBDOMAIN}.${HOST_DOMAIN}/`,
}

export const isLMS = () => {
  return (
    !!window.parent.__CFC_PLAYER_DATA__ &&
    !!window.parent.API_1484_11 &&
    window.location.host.includes('lms')
  )
}

const hasParam = param => {
  const { search } = location || {}
  const params = search && new URLSearchParams(search)
  const previewParam = params.get(param) || false
  return !!previewParam
}

export const isPreviewMode = () => !isLMS() && hasParam('preview')
export const isFamilyMode = () => !isLMS() && hasParam('family')

export const isFieldTestMode = () =>
  window?.parent?.__CFC_PLAYER_DATA__?.programName === 'selaFT' ||
  window?.parent?.__CFC_PLAYER_DATA__?.path?.contentfulId ===
    '6PXU4YwOlqyYJMxEpNd2Wu'

import React from 'react'

import PropTypes from 'prop-types'

import {
  TextEntryLabel,
  TextEntryStatusArea,
  TextEntryTextArea,
  TextEntryTextInput,
  TextEntryWrapper,
} from './component.styles'
import {
  MAXLENGTH,
  PLACEHOLDERTEXT,
  STATUS_AREA_DATA_TEST_ID_PREFIX,
  TEXT_AREA_DATA_TEST_ID_PREFIX,
  TEXT_INPUT_DATA_TEST_ID_PREFIX,
} from './constants'

const TextEntryComponent = ({
  currentLength,
  handleChange,
  id,
  isMultiLine,
  pageComponentIndex,
  questionText,
  response,
  showStatusAlert,
  showStatusArea,
}) => {
  const dataTestIdSuffix = `${pageComponentIndex}-${id}`

  const textAreaDataTestId = `${TEXT_AREA_DATA_TEST_ID_PREFIX}${dataTestIdSuffix}`
  const textInputDatTestId = `${TEXT_INPUT_DATA_TEST_ID_PREFIX}${dataTestIdSuffix}`
  const accessibilityLabel = `${questionText}-${pageComponentIndex}`
  const inputProps = {
    dataTestId: isMultiLine ? textAreaDataTestId : textInputDatTestId,
    onChange: handleChange,
    key: textAreaDataTestId,
    id: accessibilityLabel,
    maxLength: MAXLENGTH,
    placeholder: PLACEHOLDERTEXT,
    value: response,
  }

  return (
    <TextEntryWrapper>
      <TextEntryLabel htmlFor={accessibilityLabel}>
        {questionText}
      </TextEntryLabel>
      {isMultiLine ? (
        <TextEntryTextArea {...inputProps} />
      ) : (
        <TextEntryTextInput {...inputProps} />
      )}
      {showStatusArea && (
        <TextEntryStatusArea
          dataTestId={`${STATUS_AREA_DATA_TEST_ID_PREFIX}${dataTestIdSuffix}`}
          showStatusAlert={showStatusAlert}
        >
          {`${currentLength} / ${MAXLENGTH}`}
        </TextEntryStatusArea>
      )}
    </TextEntryWrapper>
  )
}

TextEntryComponent.propTypes = {
  currentLength: PropTypes.number,
  handleChange: PropTypes.func,
  id: PropTypes.string,
  isMultiLine: PropTypes.bool,
  label: PropTypes.string,
  pageComponentIndex: PropTypes.string,
  questionText: PropTypes.string,
  response: PropTypes.string,
  showStatusAlert: PropTypes.bool,
  showStatusArea: PropTypes.bool,
}

TextEntryComponent.defaultProps = {
  currentLength: 0,
  handleChange() {},
  isMultiLine: true,
  pageComponentIndex: '0',
  questionText: '',
  showStatusAlert: false,
  showStatusArea: false,
}

export default TextEntryComponent

import React from 'react'

import { SkipToMainWrapper } from './component.styles'
import { SKIP_TO_MAIN } from './constants'

const SkipToMainComponent = () => {
  return (
    <SkipToMainWrapper data-testid="skip-to-main-text" href={'#main-content'}>
      {SKIP_TO_MAIN}
    </SkipToMainWrapper>
  )
}

export default SkipToMainComponent

import React, { Fragment } from 'react'

import Header from 'components/Header'
import MarkAsComplete from 'components/MarkAsComplete'
import MicrolearningDetails from 'components/MicrolearningDetails'
import NavFooter from 'components/NavFooter'
import NavHeader from 'components/NavHeader'
import PropTypes from 'prop-types'

import ErrorModal from '../ErrorModal'
import TimeoutGuard from '../TimeoutGuard'

import { PageLayoutOuterWrapper, PageLayoutWrapper } from './component.styles'
import { MICROLEARNING_LABEL, MICROLEARNING_TITLE_ID } from './constants'

const PageLayoutComponent = ({ children, title, label, currentUnitTitle }) => {
  return (
    <Fragment>
      <NavHeader />
      <main id={'main-content'} role="main">
        <PageLayoutOuterWrapper>
          <PageLayoutWrapper>
            <TimeoutGuard />
            <ErrorModal />
            <header>
              {currentUnitTitle && <MicrolearningDetails />}
              <Header h3={label} id={MICROLEARNING_LABEL} key={1} />
              <Header h1={title} id={MICROLEARNING_TITLE_ID} key={2} />
            </header>
            {children}
            <MarkAsComplete />
          </PageLayoutWrapper>
        </PageLayoutOuterWrapper>
      </main>
      <NavFooter />
    </Fragment>
  )
}

PageLayoutComponent.defaultProps = {
  label: '',
  title: '',
}

PageLayoutComponent.propTypes = {
  children: PropTypes.node,
  currentUnitTitle: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  title: PropTypes.string,
}

export default PageLayoutComponent

import React, { Suspense } from 'react'

import RatioWrapper from 'components/RatioWrapper'
import defaultSvg from 'components/SelaVideoPlayButtonDefault/playButtonDefault.svg'
import playButtonHover from 'components/SelaVideoPlayButtonHover/playButtonHover.svg'
import { Logger } from 'logging'
import PropTypes from 'prop-types'
import { ErrorBoundary } from 'secondstep-components'
import { TranscriptComponent, VideoV3 } from 'secondstepVideo'
import { useBreakpoints } from 'utils/breakpointProp'

import {
  ContentWrapper,
  Spacer,
  TranscriptOutterWrapper,
  VideoContentWrapper,
  VideoWrapper,
} from './component.styles'
import { DATA_TEST_ID_PREFIX } from './constants'
import MenuComponent from './menuComponent'

const VideoComponent = props => {
  const {
    audioDescriptionTranscript,
    canShowAccessibilityMenu,
    canShowAudioDescriptionButton,
    canShowTranscriptButton,
    videoUrl,
    tracks,
    settings,
    poster,
    hasVideoSource,
    hasMargin,
    onEnded,
    onPlay,
    onStopped,
    pageComponentIndex,
    id,
    transcript,
    isAudioHovering,
    showTranscript,
    isHovering,
    isTranscriptActive,
    transcriptRendered,
    handleClickAudioDesc,
    isAudioDescActive,
  } = props || {}
  const modifiedTracks = JSON.parse(tracks)

  const dataTestId = pageComponentIndex
    ? `${DATA_TEST_ID_PREFIX}${pageComponentIndex}-${id}`
    : `${DATA_TEST_ID_PREFIX}${id}`

  const appInsightInstance = window.appInsight

  const { isSm } = useBreakpoints()

  const video = hasVideoSource ? (
    <VideoV3
      appInsightInstance={appInsightInstance}
      dataTestId={dataTestId}
      logger={Logger}
      onEnded={onEnded}
      onPlay={onPlay}
      onStopped={onStopped}
      poster={poster}
      settings={settings}
      styles={{
        bigPlayButton: {
          default: defaultSvg,
          hover: playButtonHover,
        },
      }}
      tracks={modifiedTracks}
      videoUrl={videoUrl}
    />
  ) : null

  return (
    <>
      <ContentWrapper>
        <VideoWrapper
          hasMargin={hasMargin}
          transcriptRendered={transcriptRendered}
        >
          <RatioWrapper>
            <Suspense fallback={<div>Loading...</div>}>
              <VideoContentWrapper>{video}</VideoContentWrapper>
            </Suspense>
          </RatioWrapper>
        </VideoWrapper>
        {!isSm && <Spacer transcriptRendered={transcriptRendered} />}
        {isSm && canShowAccessibilityMenu && (
          <MenuComponent
            canShowAudioDescriptionButton={canShowAudioDescriptionButton}
            canShowTranscriptButton={canShowTranscriptButton}
            handleClickAudioDesc={handleClickAudioDesc}
            isAudioDescActive={isAudioDescActive}
            isAudioHovering={isAudioHovering}
            isHovering={isHovering}
            isTranscriptActive={isTranscriptActive}
            showTranscript={showTranscript}
            transcriptRendered={transcriptRendered}
          />
        )}
        {transcriptRendered && (
          <TranscriptOutterWrapper
            data-testid="transcript-outer-wrapper"
            hasMargin={hasMargin}
          >
            <ErrorBoundary message="Problem loading video transcript">
              <TranscriptComponent
                audioDescriptionTranscript={audioDescriptionTranscript}
                isAudioDescActive={isAudioDescActive}
                transcript={transcript}
              />
            </ErrorBoundary>
          </TranscriptOutterWrapper>
        )}
      </ContentWrapper>
      {!isSm && canShowAccessibilityMenu && (
        <MenuComponent
          canShowAudioDescriptionButton={canShowAudioDescriptionButton}
          canShowTranscriptButton={canShowTranscriptButton}
          handleClickAudioDesc={handleClickAudioDesc}
          isAudioDescActive={isAudioDescActive}
          isAudioHovering={isAudioHovering}
          isHovering={isHovering}
          isTranscriptActive={isTranscriptActive}
          showTranscript={showTranscript}
          transcriptRendered={transcriptRendered}
        />
      )}
    </>
  )
}

VideoComponent.defaultProps = {}

VideoComponent.propTypes = {
  handleClickAudioDesc: PropTypes.func,
  hasMargin: PropTypes.bool,
  hasVideoSource: PropTypes.bool,
  isAudioDescActive: PropTypes.bool,
  poster: PropTypes.string,
  settings: PropTypes.object,
  tracks: PropTypes.array,
  videoUrl: PropTypes.string,
}

export default VideoComponent

// Collector is used for elements that use a flat structure in contentful, such as accordions and highlighted content.
// It stores the title of the start element. It also stores an array of Contentful JSON objects in a content array.

const resetCollection = () => {
  return {
    isCollecting: false,
    content: [],
    title: '',
  }
}

const startCollection = (title, highlightType) => {
  return {
    content: [],
    highlightType,
    isCollecting: true,
    title,
  }
}

export default {
  resetCollection,
  startCollection,
}

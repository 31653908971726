import React, { useEffect } from 'react'

import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { selectors as appSelectors } from 'store/app'

const NavigationGuard = ({ children }) => {
  const isAppLocked = useSelector(appSelectors.isAppLocked)
  const beforeUnload = event => {
    const e = event || window.event
    if (isAppLocked) {
      e.preventDefault()
      if (e) {
        e.returnValue = 'Your progress is being saved'
      }

      return 'Your progress is being saved'
    }
  }

  useEffect(() => {
    window.addEventListener('beforeunload', beforeUnload)

    return () => window.removeEventListener('beforeunload', beforeUnload)
  }, [isAppLocked])

  return <>{children}</>
}

NavigationGuard.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
}

export default NavigationGuard

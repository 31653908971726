export const httpErrorMessage = statusCode => {
  const httpMapper = {
    [-1]: {
      statusMessage: 'Network Error',
      shouldTryAgain: true,
    },
    400: {
      statusMessage: 'Bad Request',
      shouldTryAgain: false,
    },
    401: {
      statusMessage: 'Unauthorized',
      shouldTryAgain: true,
    },
    403: {
      statusMessage: 'Forbidden',
      shouldTryAgain: true,
    },
    404: {
      statusMessage: 'Not Found',
      shouldTryAgain: false,
    },
    408: {
      statusMessage: 'Timeout',
      shouldTryAgain: true,
    },
    413: {
      statusMessage: 'Content Too Large',
      shouldTryAgain: false,
    },
    500: {
      statusMessage: 'Server Error',
      shouldTryAgain: true,
    },
    default: {
      statusMessage: 'Server Error',
      shouldTryAgain: true,
    },
  }
  const errorMessage = httpMapper[`${statusCode}`] || httpMapper.default

  return errorMessage
}

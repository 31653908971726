import { IconCaretSelect, NavArrow } from 'secondstep-components'
import styled from 'styled-components'

export const NavFooterWrapper = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: center;
`

export const NavFooterInnerWrapper = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  font-family: LufgaCFC;
  justify-content: space-between;
  max-width: 72.875rem;
  padding: 3.75rem 3.125rem;
  width: 100%;

  ${({ theme }) => theme?.breakpoints?.mobile} {
    text-align: center;
    padding: 2.5rem 1.25rem;
  }
`

export const NavFooterInfo = styled.span`
  font-size: 1.125rem;
  ${({ theme }) => theme?.breakpoints?.mobile} {
    text-align: center;
  }
`

export const NavFooterButtonWrapper = styled.div.attrs(
  ({ isPreviousLesson }) => ({
    justifyContent: isPreviousLesson ? 'space-between' : 'flex-end',
  }),
)`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
  width: 100%;
  flex-wrap: wrap;

  button:first-child {
    margin: 1.25rem 1.25rem 0 0;
  }

  ${({ theme }) => theme?.breakpoints?.mobile} {
    justify-content: center;
  }
`

export const NavArrowWrapperPrevious = styled.div`
  margin-right: 0.375rem;
  transform: scale(0.85);
  margin-top: 0.2rem;
`

export const NavArrowWrapperNext = styled.div`
  margin-left: 0.375rem;
  transform: scale(0.85) rotate(180deg);
`

export const NavArrowStyled = styled(NavArrow).attrs(
  ({ isLessonComplete, theme }) => {
    return {
      strokeColor: isLessonComplete
        ? theme?.colors?.navFooter?.strokeColorComplete
        : theme?.colors?.navFooter?.strokeColor,
    }
  },
)``

export const NavFooterButton = styled.button.attrs(
  ({ buttonText, isLessonComplete, navigationDirText, dataTestId, theme }) => ({
    backgroundColor: isLessonComplete
      ? theme?.colors?.navFooter?.backgroundColorComplete
      : theme?.colors?.navFooter?.backgroundColor,
    'data-testid': dataTestId,
    disabledBackgroundColor: isLessonComplete
      ? theme?.colors?.disabledButtons?.primaryBackground
      : theme?.colors?.disabledButtons?.color,
    disabledForegroundColor: isLessonComplete
      ? theme?.colors?.disabledButtons?.color
      : theme?.colors?.disabledButtons?.primaryBackground,
    disabledPolylineColor: isLessonComplete
      ? theme?.colors?.disabledButtons?.color
      : theme?.colors?.disabledButtons?.primaryBackground,
    foregroundColor: isLessonComplete
      ? theme?.colors?.navFooter?.backgroundColor
      : theme?.colors?.navFooter?.backgroundColorComplete,
    hoverBackgroundColor: isLessonComplete
      ? theme?.colors?.navFooter?.hoverBackgroundColorComplete
      : theme?.colors?.navFooter?.hoverBackgroundColor,
    hoverColor: isLessonComplete
      ? theme?.colors?.navFooter?.hoverColorComplete
      : theme?.colors?.navFooter?.hoverColor,
    hoverStrokeColor: isLessonComplete
      ? theme?.colors?.navFooter?.hoverStrokeColorComplete
      : theme?.colors?.navFooter?.hoverStrokeColor,
    navigationTextMobile: navigationDirText,
    navigationTextWithLessonType: buttonText,
  }),
)`
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: 0.125rem solid #00a3a0;
  border-radius: 1.3125rem;
  color: ${({ foregroundColor }) => foregroundColor};
  cursor: pointer;
  width: 15.0625rem;
  display: flex;
  height: 2.625rem;
  justify-content: center;
  margin-top: 1.25rem;

  span::before {
    font-family: LufgaCFC;
    font-weight: 600;
    font-size: 1rem;
    content: ${({ navigationTextWithLessonType }) =>
      `'${navigationTextWithLessonType}'`};
  }

  ${({ theme }) => theme?.breakpoints?.mobile} {
    width: 7.8125rem;

    span::before {
      content: ${({ navigationTextMobile }) => `'${navigationTextMobile}'`};
    }
  }
  :hover {
    background-color: ${({ hoverBackgroundColor }) => hoverBackgroundColor};
    color: ${({ hoverColor }) => hoverColor};
    border: 0.125rem solid
      ${({ theme }) => theme?.colors?.markComplete?.fontColor};
    div {
      svg {
        g {
          polyline {
            stroke: ${({ hoverStrokeColor }) => hoverStrokeColor};
          }
        }
      }
    }
  }
  :disabled {
    cursor: initial;
    background-color: ${({ disabledBackgroundColor }) =>
      disabledBackgroundColor};
    color: ${({ disabledForegroundColor }) => disabledForegroundColor};
    border: 0.125rem solid
      ${({ theme }) => theme?.colors?.disabledButtons.primaryBackground};
    polyline {
      stroke: ${({ disabledPolylineColor }) => disabledPolylineColor};
`

export const NavFooterCaret = styled(IconCaretSelect)``

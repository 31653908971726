import { default as lmsPayloads } from 'lib/api/lms/payloads'
import { all, put, takeLatest } from 'redux-saga/effects'
import { operations as appOperations } from 'store/app'
import { default as baseTypes } from 'store/types'
import { updateCmiData } from 'utils/state'

import { default as cmiActions } from './actions'
import { CMI_KEYS } from './constants'
import { default as cmiTypes } from './types'

export function* updateCmi({ payload }) {
  const { microLearningEntryId, data } = payload || {}
  const fetchingData = updateCmiData(microLearningEntryId, data)
  const updatingPayloadArgs = {
    data: fetchingData,
    microLearningEntryId,
  }
  const updatingPayload = lmsPayloads.updating(updatingPayloadArgs)
  yield put(cmiActions.updating(updatingPayload))
  yield put(appOperations.lock())
  const { data: updatingPayloadData } = updatingPayload || {}
  const completionStatus =
    updatingPayloadData[microLearningEntryId].completionStatus

  yield put({
    data: completionStatus,
    // Currently hard-coded to save completion status.  In the future, will need to adjust to save data for other SCORM cmi keys.
    key: CMI_KEYS.COMPLETION_STATUS,
    microLearningEntryId,
    onError: diagnostic => ({
      payload: {
        data,
        diagnostic,
        microLearningEntryId,
      },
      type: cmiTypes.UPDATING_WITH_RETRIES_ERROR,
    }),
    onRetry: () => appOperations.unableToSaveApp(),
    onSuccess: diagnostic => ({
      payload: {
        data,
        diagnostic,
        microLearningEntryId,
      },
      type: cmiTypes.UPDATING_WITH_RETRIES_SUCCESS,
    }),
    type: baseTypes.SET_VALUE_QUEUE,
  })
}

export function* updateCmiError({ payload }) {
  const { data, diagnostic, microLearningEntryId } = payload || {}
  const updatingErrorPayloadArgs = {
    data,
    diagnostic,
    microLearningEntryId,
  }
  const updatingErrorPayload = lmsPayloads.updatingError(
    updatingErrorPayloadArgs,
  )
  yield put(cmiActions.updatingError(updatingErrorPayload))
  yield put(appOperations.unlock())
  yield put(appOperations.failedToSave())
}

export function* updateCmiSuccess({ payload }) {
  const { microLearningEntryId, data, diagnostic } = payload || {}
  const updatingSuccessPayloadArgs = { data, diagnostic, microLearningEntryId }
  const updatingSuccessPayload = lmsPayloads.updatingSuccess(
    updatingSuccessPayloadArgs,
    data,
  )
  yield put(cmiActions.updatingSuccess(updatingSuccessPayload))
  yield put(appOperations.unlock())
}

export default function* rootSaga() {
  yield all([
    takeLatest(cmiTypes.UPDATING_WITH_RETRIES, updateCmi),
    takeLatest(cmiTypes.UPDATING_WITH_RETRIES_SUCCESS, updateCmiSuccess),
    takeLatest(cmiTypes.UPDATING_WITH_RETRIES_ERROR, updateCmiError),
  ])
}

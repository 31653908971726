import React, { useState } from 'react'

import ErrorMessage from 'components/ErrorMessage'
import LoadingScreen from 'components/LoadingScreen'
import PageLayout from 'components/PageLayout'
import * as appHooks from 'hooks/useApp'
import { default as diagnosticsValidatorHooks } from 'hooks/useDiagnosticsValidator'
import { GoogleAnalytics, resolvePlayerContext } from 'logging'
import { useSelector } from 'react-redux'
import { selectors as appSelectors } from 'store/app'
import { selectors as contentfulSelectors } from 'store/contentful/'

import NavigationGuard from './components/NavigationGuard/container'
import env from './config/build'

const App = () => {
  const currentLocation = useSelector(appSelectors.contentfulId)
  const isContentfulHydrated = useSelector(appSelectors.isContentfulHydrated)
  const isLmsHydrated = useSelector(appSelectors.isLmsHydrated)
  const microLearning = useSelector(state =>
    contentfulSelectors.entry(state, currentLocation),
  )
  const [isDone, setIsDone] = useState(false)

  appHooks.Initialize()

  const { validate } = diagnosticsValidatorHooks.useDiagnosticsValidator()
  const contentfulErrors = validate().contentfulErrors
  const hasContentfulErrors =
    contentfulErrors && Object.keys(contentfulErrors).length > 0

  if (hasContentfulErrors) {
    const firstError = Object.values(contentfulErrors)[0]
    const { status } = firstError || {}
    const serviceName = 'contentfulError'

    return <ErrorMessage serviceName={serviceName} statusCode={status} />
  }
  const { content, displayTitle, label } = microLearning || {}
  const isLoaded = isLmsHydrated && isContentfulHydrated

  if (!window.Cypress) {
    GoogleAnalytics.init({
      globalPrefix: 'scrollable-player',
      pageTitle:
        window.parent?.__GOOGLE_ANALYTICS_FILTER_PROPERTIES__?.title ||
        displayTitle,
      trackingId: env.googleAnalyticsTrackingId,
      context: resolvePlayerContext(),
    })
  }
  const injectBaseTag = () => {
    if (isDone) {
      return
    }
    setIsDone(true)
    var js,
      fjs = document.getElementsByTagName('head')[0]

    js = document.createElement('base')
    js.target = '_parent'
    fjs.appendChild(js, fjs)
  }

  injectBaseTag()

  return isLoaded ? (
    <NavigationGuard>
      <PageLayout content={content} label={label} title={displayTitle} />
    </NavigationGuard>
  ) : (
    <LoadingScreen />
  )
}

export default App

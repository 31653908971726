import React from 'react'

import Scorecard from 'components/Scorecard'
import SingleSelectQuestion from 'components/SingleSelectQuestion'
import PropTypes from 'prop-types'
import { getDataTestIdHeader, getDataTestIdIntroText } from 'utils/survey'

import {
  Callout,
  Header,
  Question,
  SurveyHorizRule,
  Text,
  Wrapper,
} from './component.styles'

const SurveyComponent = ({
  categories,
  header,
  id,
  introText,
  maxPossibleScores,
  pageComponentIndex,
  questionItems,
  scorecardIntroText,
  totalAnswered,
  totalQuestions,
}) => {
  const headerDataTestId = getDataTestIdHeader(pageComponentIndex, id)
  const introTextDataTestId = getDataTestIdIntroText(pageComponentIndex, id)

  return (
    <Callout>
      <Wrapper>
        {header && <Header dataTestId={headerDataTestId}>{header}</Header>}
        {header && introText && <SurveyHorizRule />}
        {introText && <Text datatestid={introTextDataTestId}>{introText}</Text>}
        {questionItems.map(question => (
          <Question>
            <SingleSelectQuestion {...question} key={question.id} />
          </Question>
        ))}
      </Wrapper>
      <Scorecard
        categories={categories}
        maxPossibleScores={maxPossibleScores}
        pageComponentIndex={pageComponentIndex}
        scorecardIntroText={scorecardIntroText}
        totalAnswered={totalAnswered}
        totalQuestions={totalQuestions}
      />
    </Callout>
  )
}

SurveyComponent.propTypes = {
  categories: PropTypes.object,
  header: PropTypes.string,
  id: PropTypes.string,
  introText: PropTypes.string,
  maxPossibleScores: PropTypes.object,
  pageComponentIndex: PropTypes.string,
  questionItems: PropTypes.array,
  scorecardIntroText: PropTypes.string,
  totalAnswered: PropTypes.number,
  totalQuestions: PropTypes.number,
}

SurveyComponent.defaultProps = {
  header: '',
  introText: '',
  questionItems: [],
  pageComponentIndex: '0',
}

export default SurveyComponent

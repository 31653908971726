import { Markdown } from 'grommet'
import styled from 'styled-components'

export const Wrapper = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  line-height: 1.375rem;
`

export const Body = styled(Markdown)`
  font-size: 1.125rem;
  line-height: 1.625rem;

  p {
    color: ${({ theme }) => theme?.colors?.bodyCopy};
    letter-spacing: 0rem;
    max-width: none;
    margin: 1.75rem 0 0 0;
  }
  /* Safari only override */
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      strong {
        letter-spacing: -0.0626rem;
      }
    }
  }

  ul,
  ol {
    margin-block-start: 0.8rem;
    margin-block-end: 1rem;
  }

  li::marker {
    color: ${({ theme }) => theme?.colors?.bulletMarkerColor};
  }

  ${({ theme }) => theme?.breakpoints?.mobile} {
    font-size: 1rem;
    line-height: 1.5rem;
    p {
      margin: 1.25rem 0 0 0;
    }
    * {
      font-size: 1rem;
    }
  }
`

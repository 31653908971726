import React from 'react'

import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import { createModalElement } from 'utils/modal'

import { OverlayInnerWrapper, StyledOverlay } from './component.styles'
import { DATA_TEST_ID } from './constants'

const CloseableOverlayComponent = ({ children, domElement, onClose, show }) => {
  const modal = (
    <StyledOverlay
      dataTestId={DATA_TEST_ID}
      isModalOpen={show}
      modalClose={onClose}
    >
      <OverlayInnerWrapper>{children}</OverlayInnerWrapper>
    </StyledOverlay>
  )
  const component = ReactDOM.createPortal(modal, domElement)

  return component
}

CloseableOverlayComponent.propTypes = {
  children: PropTypes.node,
  domElement: PropTypes.object,
  onClose: PropTypes.func,
  show: PropTypes.bool,
}

CloseableOverlayComponent.defaultProps = {
  domElement: createModalElement(),
  onClose() {},
}

export default CloseableOverlayComponent

import { useEffect, useRef, useState } from 'react'

import { useDebounce } from 'hooks/useDebounce'
import { useDispatch, useSelector } from 'react-redux'
import {
  operations as interactivesOperations,
  selectors as interactivesSelectors,
} from 'store/interactives'

// Use this hook in components that have a text entry element.
// ex. TextEntry, Single Select Question, Multi Select Question
const useTextResponse = (interactiveType, microLearningId, interactiveId) => {
  const dispatch = useDispatch()
  const [response, setResponse] = useState('')
  const debounceTime = 1500
  const debouncedResponse = useDebounce(response, debounceTime)
  const firstRenderRef = useRef(true)
  const interactive = useSelector(state =>
    interactivesSelectors.interactive(state, microLearningId, interactiveId),
  )

  useEffect(() => {
    // Check if this is the initial render, if so don't save
    if (firstRenderRef.current) {
      firstRenderRef.current = false
      return
    }

    const hasChanged = response !== interactive?.response

    hasChanged && updateReduxState(response)
  }, [debouncedResponse])

  function updateTextResponse(inputResponse) {
    setResponse(inputResponse)
  }

  function updateReduxState() {
    const newInteractiveState = {
      ...interactive,
      response,
      type: interactiveType,
    }

    dispatch(
      interactivesOperations.update(
        microLearningId,
        interactiveId,
        newInteractiveState,
      ),
    )
  }

  return {
    response,
    updateTextResponse,
  }
}

export default { useTextResponse }

import { ERROR_TYPES } from 'config/constants'
import { getFormattedDate } from 'utils/date'

const DEFAULT_ERROR_NAME = ERROR_TYPES.GENERAL_API_ERROR

export const createDiagnostic = (endpoint, method, error) => ({
  endpoint,
  error,
  method,
  time: getFormattedDate(),
})

export const createErrorFromApiResponse = async (response, defaultError) => {
  if (!response) {
    return defaultError
  }

  const { status = defaultError.status, statusText = defaultError.statusText } =
    response || {}
  const { message: providedDefaultMessage, name: providedDefaultName } =
    defaultError || {}
  const message = response.text
    ? JSON.parse(await response.text()).message
    : providedDefaultMessage
  const name = providedDefaultName || DEFAULT_ERROR_NAME
  const error = {
    message,
    name,
    status,
    statusText,
  }

  return error
}

export const createErrorFromMessage = (message, defaultError = {}) => {
  const {
    message: providedDefaultMessage,
    name: providedDefaultName,
    status,
    statusText,
  } = defaultError || {}
  const defaultedMessage = message || providedDefaultMessage
  const name = providedDefaultName || DEFAULT_ERROR_NAME
  const error = {
    message: defaultedMessage,
    name,
    status,
    statusText,
  }

  return error
}

export const decode = (data = '{}') => {
  let decodedData = decodeURIComponent(data)
  const isObject = decodedData.charAt(0) === '{'

  decodedData = isObject ? JSON.parse(decodedData) : decodedData

  return decodedData
}

export const encode = (data = {}) => {
  const dataString = JSON.stringify(data)
  const encodedDataString = encodeURIComponent(dataString)

  return encodedDataString
}

export const getFirstErrorDiagnosticFromLmsResponses = (responses = []) => {
  const getDiagnosticFromResponse = response => response?.diagnostic
  const diagnostics = responses.map(getDiagnosticFromResponse)
  const diagnosticHasError = diagnostic => {
    const hasError = !!diagnostic?.error

    if (hasError) {
      return diagnostic
    }
  }
  const errorDiagnostics = diagnostics.filter(diagnosticHasError)
  const firstErrorDiagnostic = errorDiagnostics[0]

  return firstErrorDiagnostic
}

// Navigate through parent containers and find nearest SCORM 2004 API window object, if it exists
export const getParentApiInstance = () => {
  const maxFindDepth = 50
  let depth = 0
  let win = window
  let { API_1484_11: api } = win || {}

  while (!api && depth <= maxFindDepth) {
    if ((!win.parent && !win.opener) || (win.parent && win.parent === win)) {
      break
    }

    win = win.parent || win.opener
    api = win.API_1484_11
    depth++
  }

  return api
}

export const hasParentApiInstance = () => {
  const parentApi = getParentApiInstance()
  const hasParentApi = parentApi != null

  return hasParentApi
}

import React from 'react'

import { useDispatch } from 'react-redux'
import { APP_MULTIMEDIA_STATUS } from 'store/app/constants'
import { default as appOperations } from 'store/app/operations'

import AudioComponent from './component.jsx'
import { GET_CAPTION_TRACK_WITH_SOURCE } from './data'
import {
  getAudioModulePosterSource,
  getAudioModuleVttSource,
  getSettings,
  hasAudioSource,
} from './helpers.js'

const AudioContainer = props => {
  const dispatch = useDispatch()
  const { locator, hasMargin, id, pageComponentIndex } = props || {}
  const streamingLocator = locator
  const hasAudio = hasAudioSource(props)
  const settings = getSettings(props)
  const vttSource = getAudioModuleVttSource(props)
  const poster = getAudioModulePosterSource(props)
  const tracks = []
  const captionTrack = vttSource
    ? GET_CAPTION_TRACK_WITH_SOURCE(vttSource)
    : null
  captionTrack && tracks.push(captionTrack)

  const onPlay = id => {
    dispatch(
      appOperations.update({
        appMultimediaStatus: {
          [id]: APP_MULTIMEDIA_STATUS.PLAYING,
        },
      }),
    )
  }
  const onEnded = id => {
    dispatch(
      appOperations.update({
        appMultimediaStatus: {
          [id]: APP_MULTIMEDIA_STATUS.ENDED,
        },
      }),
    )
  }
  const onStopped = id => {
    dispatch(
      appOperations.update({
        appMultimediaStatus: {
          [id]: APP_MULTIMEDIA_STATUS.STOPPED,
        },
      }),
    )
  }

  return (
    <AudioComponent
      hasAudioSource={hasAudio}
      hasMargin={hasMargin}
      id={id}
      onEnded={onEnded}
      onPlay={onPlay}
      onStopped={onStopped}
      pageComponentIndex={pageComponentIndex}
      poster={poster}
      settings={settings}
      streamingLocator={streamingLocator}
      tracks={tracks}
    />
  )
}

AudioContainer.defaultProps = {
  hasMargin: true,
}

AudioContainer.propTypes = {}

export default AudioContainer

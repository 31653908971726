import { default as interactivesSelectors } from '.'

const selections = ({ interactives }, microLearningId, interactiveId) =>
  interactivesSelectors.interactive(
    { interactives },
    microLearningId,
    interactiveId,
  )?.selections
const response = ({ interactives }, microLearningId, interactiveId) =>
  interactivesSelectors.interactive(
    { interactives },
    microLearningId,
    interactiveId,
  )?.response

export default {
  response,
  selections,
}

import { FormCheckBox, FormInputTextArea } from 'secondstep-components'
import styled from 'styled-components'

export const MultiSelectWrapper = styled.div.attrs(({ ariaLabelledBy }) => ({
  'aria-labelledby': ariaLabelledBy,
  tabIndex: 0,
}))`
  display: flex;
  flex-direction: column;
  margin: 1.75rem 0;

  ${({ theme }) => theme?.breakpoints?.mobile} {
    margin: 1.25rem 0;
    p,
    ul,
    ol,
    span {
      font-size: 1rem;
    }
  }
  .checkbox-container {
    margin-bottom: 0.5rem;
  }
`

export const MultiSelectQuestionText = styled.span`
  font-family: ${({ theme }) =>
    theme?.fonts?.multiSelectQuestion?.questionText?.family};
  font-weight: ${({ theme }) =>
    theme?.fonts?.multiSelectQuestion?.questionText?.weight};
  margin-bottom: 0.5rem;
`

// Most of the custom Grommet CheckBox styling is located in src/lib/theme/grommet.js
export const MultiSelectCheckBox = styled(FormCheckBox).attrs(
  ({ dataTestId }) => ({
    'data-testid': dataTestId,
  }),
)``

export const MultiSelectTextArea = styled(FormInputTextArea).attrs(
  ({ dataTestId }) => ({
    'data-testid': dataTestId,
  }),
)`
  margin: 0.5625rem 0 0 1.9375rem;
  // Prevents Grommet TextArea from overflowing to the right and ignoring the parent's paddingRight
  width: auto;
`

export const MultiSelectTextAreaStatus = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  display: flex;
  font-size: 0.875rem;
  color: ${({ showStatusAlert, theme }) =>
    showStatusAlert
      ? theme?.colors?.input?.textarea?.statusAlert
      : theme?.colors?.input?.textarea?.status};
  height: 0.8rem;
  justify-content: flex-end;
  margin-top: 0.25rem;
`

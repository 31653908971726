import { useDispatch } from 'react-redux'
import { operations as appOperations } from 'store/app'

// Use this hook to update micro learning completion status and persist to LMS.
const useInteractiveComplete = () => {
  const dispatch = useDispatch()
  const actions = {
    setInteractivesCompleted(id, data) {
      dispatch(
        appOperations.update({
          interactivesCompleted: {
            [id]: !!data,
          },
        }),
      )
    },
  }

  return actions
}

export default {
  useInteractiveComplete,
}

import React from 'react'

import theme from 'lib/theme'
import PropTypes from 'prop-types'

import { LinkBox, StyledTextLink } from './component.styles'
const TextLinkWithIconComponent = ({ asset, id, parseIconType }) => {
  const PDF = 'pdf'
  const { contentType } = asset || {}
  const text = asset?.displayTitle
  const url = asset?.file?.file?.url
  const downloadIcon = parseIconType(contentType, id)
  const targetType = contentType?.id === PDF ? '_blank' : '_self'

  return (
    <LinkBox>
      <StyledTextLink
        color={theme?.colors?.links?.color}
        dataTestId={`text-link-${id}`}
        href={url}
        target={targetType}
        theme={{
          anchor: {
            fontWeight: 100,
          },
          global: {
            focus: {},
            colors: {},
          },
        }}
      >
        {downloadIcon}
        {text}
      </StyledTextLink>
    </LinkBox>
  )
}

TextLinkWithIconComponent.defaultProps = {
  parseIconType: () => {},
}

TextLinkWithIconComponent.propTypes = {
  asset: PropTypes.object,
  id: PropTypes.string,
  parseIconType: PropTypes.func,
}

export default TextLinkWithIconComponent

import {
  LAYOUT_TYPES,
  MEETING_TYPES_LABELS,
  MICROLEARNING_TYPES,
} from 'store/app/constants'

export const getLayoutForMicroLearningType = ({
  microLearningType,
  microLearningLabel,
}) => {
  if (
    microLearningType === LAYOUT_TYPES.LEADER_GROUP_MEETING ||
    //TODO: LEARN-10173 remove second case with other items once MLs no longer use label for type checking
    (microLearningType === MICROLEARNING_TYPES.GROUP &&
      microLearningLabel === MEETING_TYPES_LABELS.LEADER_TEAM)
  ) {
    return LAYOUT_TYPES.LEADER_GROUP_MEETING
  }

  if (
    microLearningType === LAYOUT_TYPES.SMALL_GROUP_MEETING ||
    //TODO: LEARN-10173 remove second case with other items once MLs no longer use label for type checking
    (microLearningType === MICROLEARNING_TYPES.GROUP &&
      microLearningLabel !== MEETING_TYPES_LABELS.LEADER_TEAM)
  ) {
    return LAYOUT_TYPES.SMALL_GROUP_MEETING
  }

  if (microLearningType === MICROLEARNING_TYPES.INDIVIDUAL) {
    return MICROLEARNING_TYPES.INDIVIDUAL
  }

  return MICROLEARNING_TYPES.INDIVIDUAL
}

import React from 'react'

import PropTypes from 'prop-types'

import {
  PullQuoteOuterWrapper,
  PullQuoteText,
  PullQuoteWrapper,
} from './component.styles'

const PullQuote = ({ id, text, gradient }) => {
  const dataTestId = `pull-quote-${id}`
  return (
    <PullQuoteOuterWrapper className={gradient} id="content">
      <PullQuoteWrapper data-testid={dataTestId}>
        <PullQuoteText>{text}</PullQuoteText>
      </PullQuoteWrapper>
    </PullQuoteOuterWrapper>
  )
}

PullQuote.propTypes = {
  gradient: PropTypes.oneOf([
    'gradient-leader',
    'gradient-meeting',
    'gradient-small',
  ]),
  id: PropTypes.string,
  text: PropTypes.string,
}

PullQuote.defaultProps = {
  text: '',
}

export default PullQuote

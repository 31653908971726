import React from 'react'

const ModalCloseIcon = () => (
  <svg data-testid="close-icon" height="16px" viewBox="0 0 16 16" width="16px">
    <g
      fill="none"
      fillRule="evenodd"
      stroke="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
    >
      <g
        fill="#D8D8D8"
        id="Group"
        stroke="currentColor"
        strokeWidth="2"
        transform="translate(1.000000, 1.000000)"
      >
        <line
          id="Path-3"
          x1="4.54747351e-13"
          x2="13.4350288"
          y1="13.4350288"
          y2="0"
        />
        <line
          id="Path-3"
          transform="translate(6.717514, 6.717514) scale(-1, 1) translate(-6.717514, -6.717514) "
          x1="4.54747351e-13"
          x2="13.4350288"
          y1="13.4350288"
          y2="0"
        />
      </g>
    </g>
  </svg>
)

export default ModalCloseIcon

import { Markdown } from 'grommet'
import styled from 'styled-components'

export const CategoryWrapper = styled.div`
  align-items: center;
  display: flex;
  font-family: ${({ theme }) => theme?.fonts?.scoreCard?.score?.family};
  font-weight: ${({ theme }) => theme?.fonts?.scoreCard?.score?.weight};
  justify-content: space-between;
  margin-top: 2.1875rem;

  ${({ theme }) => theme?.breakpoints?.tablet} {
    flex-direction: column;
    align-items: flex-start;
  }

  ${({ theme }) => theme?.breakpoints?.mobile} {
    margin-top: 1.75rem;
  }
`

export const Footer = styled.div.attrs(({ isSingleCategory }) => ({
  justifyContent: isSingleCategory ? 'center' : 'flex-end',
  justifyContentTablet: !isSingleCategory && 'flex-start',
}))`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
  color: ${({ theme }) => theme?.colors?.label};
  opacity: 0.75;
  line-height: 1.25rem;
  margin-top: 1.6rem;
  font-style: italic;

  ${({ theme }) => theme?.breakpoints?.tablet} {
    justify-content: ${({ justifyContentTablet }) => justifyContentTablet};
  }
`

export const Header = styled.h4`
  font-family: ${({ theme }) => theme?.fonts?.scoreCard?.header?.family};
  font-weight: ${({ theme }) => theme?.fonts?.scoreCard?.header?.weight};
  font-size: 1.25rem;
  margin: 0;

  ${({ theme }) => theme?.breakpoints?.mobile} {
    font-size: 1.125rem;
  }
`

export const SurveyHorizRule = styled.hr`
  border: none;
  border-bottom: 0.125rem solid ${({ theme }) => theme?.colors?.scoreCard?.hr};
  margin: 0.3125rem 0;
`

export const MultiCategoryHeaderWrapper = styled.div.attrs(
  ({ dataTestId }) => ({
    'data-testid': dataTestId,
  }),
)``

export const MultiCategoryWrapper = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))``

export const SingleCategoryWrapper = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Score = styled.div.attrs(({ dataTestId, isAutoPop, theme }) => ({
  backgroundColor: isAutoPop
    ? theme?.colors?.scoreCard?.autopopBackgroundColor
    : theme?.colors?.scoreCard?.backgroundColor,
  'data-testid': dataTestId,
}))`
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 0.375rem;
  display: flex;
  flex-shrink: 0;
  height: 2.188rem;
  justify-content: center;
  width: 4.438rem;

  ${({ theme }) => theme?.breakpoints?.mobile} {
    width: 3.5rem;
    height: 1.938rem;
  }
`

export const ScoreText = styled(Markdown)``

export const ScoreTotal = styled.div`
  display: flex;
  flex-shrink: 0;
  padding: 0 0.75rem 0 2.5rem;

  ${({ theme }) => theme?.breakpoints?.tablet} {
    padding: 0 0.75rem 0 0;
  }
`

export const ScoreWrapper = styled.div.attrs(({ isSingleCategory }) => ({
  justifyContent: isSingleCategory ? 'center' : 'flex-end',
  marginBottom: isSingleCategory ? '1.1875rem' : '0',
}))`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent};
  margin-bottom: ${({ marginBottom }) => marginBottom};

  ${({ theme }) => theme?.breakpoints?.tablet} {
    margin-top: 0.625rem;
  }
`

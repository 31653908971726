import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import thunk from 'redux-thunk'
import { apiMiddleware } from 'store/rsaa/middleware'

import rootReducer from './reducer'
import rootSaga from './sagas'

export const history = createBrowserHistory()

const sagaMiddleware = createSagaMiddleware()

const middleware = [apiMiddleware, thunk, sagaMiddleware] //order matters. Api middleware needs to request before thunk handles the request.
const enhancers = []
const initialState = {}

if (process.env.NODE_ENV !== 'production') {
  // add redux dev tool
  const devToolsExtension =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 10,
    })
  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers)
const store = createStore(rootReducer, initialState, composedEnhancers)

sagaMiddleware.run(rootSaga)

export default store

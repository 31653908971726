import LinkIcon from 'components/Icons/LinkIcon'
import styled from 'styled-components'

export const MarkAsCompleteOuterWrapper = styled.div.attrs(
  ({ dataTestId }) => ({
    'data-testid': dataTestId,
  }),
)`
  text-align: center;
  &.gradient-small,
  &.gradient-leader {
    margin: 0 -2.5rem;
    padding: 0 2.5rem;
    background: ${({ theme }) =>
      `linear-gradient(to bottom, ${theme?.colors?.pullQuote?.gradient?.top} 50%, ${theme?.colors?.pullQuote?.gradient?.bottom} 50%);`};

    ${({ theme }) => theme?.breakpoints?.mobile} {
      margin: 0 -1.25rem;
      padding: 0 1.25rem;
    }
  }
`

export const MarkAsCompleteWrapper = styled.div.attrs(
  ({ dataTestId, isComplete }) => ({
    'data-testid': dataTestId,
    isComplete: isComplete,
  }),
)`
  position: relative;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme?.colors?.pullQuote?.backgroundColor};
  border-radius: 0 3.8125rem;
  border: 0.25rem solid
    ${({ theme }) => theme?.colors?.markComplete?.borderColor};
  color: ${({ theme }) => theme?.colors?.pullQuote?.color};
  display: flex;
  justify-content: center;
  margin: 2.5rem 0;
  padding: ${({ isComplete }) =>
    isComplete ? '1.875rem 1.75rem' : '2.5rem 1.75rem'};

  ${({ theme }) => theme?.breakpoints?.mobile} {
    padding-right: 1.75rem;
    padding-left: 1.75rem;
    padding: ${({ isComplete }) =>
      isComplete ? '1.875rem 1.75rem' : '2.5rem 1.75rem'};
  }

  p {
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
  }
`

export const CheckMarkWrapper = styled.div`
  position: absolute;
  top: -0.625rem;
`

export const HeaderContent = styled.div.attrs(({ isComplete }) => ({
  marginBottom: isComplete ? '0.75rem' : '0',
}))`
  color: ${({ theme }) => theme?.colors?.markComplete?.fontColor};
  font-family: ${({ theme }) => theme?.fonts?.markComplete?.header?.family};
  font-weight: ${({ theme }) => theme?.fonts?.markComplete?.header?.weight};
  font-size: 1.25rem;
  line-height: 2.1875rem;
  letter-spacing: 0.016rem;
  margin-bottom: ${({ marginBottom }) => marginBottom};

  ${({ theme }) => theme?.breakpoints?.mobile} {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
`

export const BodyContent = styled.div`
  font-size: 1.125rem;
  letter-spacing: 0;
  line-height: 1.625rem;
  color: ${({ theme }) => theme?.colors?.markComplete?.fontColor};
  margin-bottom: 1.5rem;

  ${({ theme }) => theme?.breakpoints?.mobile} {
    font-size: 1rem;
  }
`

export const ModalHeaderContent = styled.div`
  font-family: ${({ theme }) => theme?.fonts?.markComplete?.header?.family};
  font-weight: ${({ theme }) => theme?.fonts?.markComplete?.header?.weight};
  font-size: 1.25rem;
  line-height: 2.188rem;
  letter-spacing: 0.016rem;
  color: ${({ theme }) => theme?.colors?.markComplete?.fontColor};
  ${({ theme }) => theme?.breakpoints?.mobile} {
    line-height: 1.5rem;
  }
`

export const ModalBodyContent = styled.div`
  font-size: 1.125rem;
  letter-spacing: 0;
  line-height: 1.625rem;
  color: ${({ theme }) => theme?.colors?.markComplete?.fontColor};
  margin-bottom: 1.75rem;
  margin-top: 0.75rem;
`

export const MarkCompleteButton = styled.button.attrs(
  ({ dataTestId, isComplete }) => ({
    'data-testid': dataTestId,
    isComplete: isComplete,
  }),
)`
  border: 0.125rem solid
    ${({ theme }) => theme?.colors?.markComplete?.borderColor2};
  border-radius: 1.3125rem;
  background-color: ${({ isComplete }) =>
    isComplete
      ? ({ theme }) => theme?.colors?.markComplete?.borderColor
      : ({ theme }) => theme?.colors?.markComplete?.borderColor2};
  color: ${({ isComplete }) =>
    isComplete
      ? ({ theme }) => theme?.colors?.markComplete?.borderColor2
      : ({ theme }) => theme?.colors?.markComplete?.borderColor};
  cursor: pointer;
  height: 2.5rem;
  width: ${({ isComplete }) => (isComplete ? '15.438rem' : '10.438rem')};
  font-size: 1rem;
  font-family: ${({ theme }) => theme?.fonts?.markComplete?.markButton?.family};
  font-weight: ${({ theme }) => theme?.fonts?.markComplete?.markButton?.weight};
  letter-spacing: 0.031rem;
  line-height: 1.5rem;
  margin-top: 0;

  ${({ theme }) => theme?.breakpoints?.tablet} {
    width: ${({ isComplete }) => (isComplete ? '17rem' : '12rem')};
  }

  :hover {
    background-color: ${({ isComplete }) =>
      isComplete
        ? ({ theme }) => theme?.colors?.markComplete?.borderColor
        : ({ theme }) => theme?.colors?.markComplete?.fontColor};
    color: ${({ isComplete }) =>
      isComplete
        ? ({ theme }) => theme?.colors?.markComplete?.fontColor
        : ({ theme }) => theme?.colors?.markComplete?.borderColor};
    border: 0.125rem solid
      ${({ theme }) => theme?.colors?.markComplete?.fontColor};
  }

  :disabled {
    cursor: initial;
    background-color: ${({ isComplete }) =>
      isComplete
        ? ({ theme }) => theme?.colors?.disabledButtons.color
        : ({ theme }) => theme?.colors?.disabledButtons.primaryBackground};

    color: ${({ isComplete }) =>
      isComplete
        ? ({ theme }) => theme?.colors?.disabledButtons.primaryBackground
        : ({ theme }) => theme?.colors?.disabledButtons.color};
    border: 0.125rem solid
      ${({ theme }) => theme?.colors?.disabledButtons.primaryBackground};
  }

  :focus {
    background-color: ${({ isComplete }) =>
      isComplete
        ? ({ theme }) => theme?.colors?.markComplete?.borderColor
        : ({ theme }) => theme?.colors?.markComplete?.fontColor};
    color: ${({ isComplete }) =>
      isComplete
        ? ({ theme }) => theme?.colors?.markComplete?.fontColor
        : ({ theme }) => theme?.colors?.markComplete?.borderColor};
    border: 0.125rem solid
      ${({ theme }) => theme?.colors?.markComplete?.fontColor};
  }
`

export const FeedbackLink = styled.a.attrs(({ url }) => ({
  target: '_blank',
  href: url,
}))`
  color: ${({ theme }) => theme?.colors?.markComplete?.feedbackLink};
  font-family: ${({ theme }) =>
    theme?.fonts?.markComplete?.feedbackLink?.family};
  font-weight: ${({ theme }) =>
    theme?.fonts?.markComplete?.feedbackLink?.weight};
  font-size: 1rem;
  margin-top: 1rem;
  :link,
  :visited {
    font-weight: ${({ theme }) => theme?.fontWeights?.normal};
  }
`
export const FeedbackLinkBreak = styled.a.attrs(({ url }) => ({
  target: '_blank',
  href: url,
}))`
  color: ${({ theme }) => theme?.colors?.markComplete?.feedbackLink};
  font-family: ${({ theme }) =>
    theme?.fonts?.markComplete?.feedbackLink?.family};
  font-weight: ${({ theme }) =>
    theme?.fonts?.markComplete?.feedbackLink?.weight};
  font-size: 1rem;
  margin-top: 1rem;
  margin-left: 0.2rem;
  :link,
  :visited {
    font-weight: ${({ theme }) => theme?.fontWeights?.normal};
  }
  ${({ theme }) => theme?.breakpoints?.tablet} {
    margin-top: 0;
  }
`
export const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${({ theme }) => theme?.breakpoints?.tablet} {
    flex-direction: column;
  }
`

export const StyledLinkIcon = styled(LinkIcon).attrs(({ theme }) => ({
  color: theme?.colors?.markComplete?.feedbackLink,
  size: '0.8125rem',
}))``

export const GoBackButton = styled.button`
  margin-right: 1.25rem;
  border: 0.125rem solid
    ${({ theme }) => theme?.colors?.markComplete?.borderColor2};
  border-radius: 1.3125rem;
  background-color: ${({ theme }) => theme?.colors?.markComplete?.borderColor};
  color: ${({ theme }) => theme?.colors?.markComplete?.borderColor2};
  cursor: pointer;
  height: 2.5rem;
  width: 10.188rem;
  font-size: 1rem;
  font-family: ${({ theme }) => theme?.fonts?.markComplete?.markButton?.family};
  font-weight: ${({ theme }) => theme?.fonts?.markComplete?.markButton?.weight};
  letter-spacing: 0.031rem;
  line-height: 1.5rem;
  :hover {
    background-color: ${({ theme }) =>
      theme?.colors?.markComplete?.borderColor};
    color: ${({ theme }) => theme?.colors?.markComplete?.fontColor};
    border: 0.125rem solid
      ${({ theme }) => theme?.colors?.markComplete?.fontColor};
  }
  :focus {
    background-color: ${({ theme }) =>
      theme?.colors?.markComplete?.borderColor};
    color: ${({ theme }) => theme?.colors?.markComplete?.fontColor};
    border: 0.125rem solid
      ${({ theme }) => theme?.colors?.markComplete?.fontColor};
  }
  ${({ theme }) => theme?.breakpoints?.mobile} {
    margin-bottom: 1.25rem;
  }
  ${({ theme }) => theme?.breakpoints?.tablet} {
    width: 12rem;
  }
  :disabled {
    cursor: initial;
    background-color: ${({ theme }) => theme?.colors?.disabledButtons.color};
    color: ${({ theme }) => theme?.colors?.disabledButtons.primaryBackground};
    border: 0.125rem solid
      ${({ theme }) => theme?.colors?.disabledButtons.primaryBackground};
  }
`

export const ContentArea = styled.div`
  text-align: center;
`

export const ModalContentArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2.5rem 0;
  ${({ theme }) => theme?.breakpoints?.tablet} {
    text-align: center;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 0;
  ${({ theme }) => theme?.breakpoints?.mobile} {
    flex-direction: column;
  }
`

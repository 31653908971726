import React from 'react'

import PropTypes from 'prop-types'
import { NavSaved } from 'secondstep-components'

import { SaveSvg, StyledStatusLabel } from './component.styles'
import {
  appSavingStatus,
  SAVED,
  statusIconMap,
  statusLabelMap,
} from './constants'

const SaveStatus = ({ status }) => {
  const IconComponent = statusIconMap[status] || NavSaved
  const label = statusLabelMap[status] || SAVED

  return (
    <SaveSvg>
      <IconComponent />
      <StyledStatusLabel data-testid="save-status-text">
        {label}
      </StyledStatusLabel>
    </SaveSvg>
  )
}

SaveStatus.propTypes = {
  status: PropTypes.oneOf(appSavingStatus),
}

export default SaveStatus

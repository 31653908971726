export const GET_CAPTION_TRACK_WITH_SOURCE = (source = '') => ({
  kind: 'captions',
  label: 'English',
  src: source,
  srclang: 'en',
})
export const GET_DEFAULT_PLAYER_SETTINGS = () => ({
  controls: true,
  height: '100%',
  hotKeys: {
    enableVolumeScroll: false,
  },
  width: '100%',
  plugins: {
    appInsights: {
      //add additonal plugin options here
      buffering: true,
      bitrateQuality: true,
      debug: false,
      downloadInfo: true,
      end: true,
      error: true,
      fullscreen: true,
      loaded: true,
      playbackSummary: true,
      playTime: true,
      percentPlayed: true,
      play: true,
      pause: true,
      seek: true,
      viewed: true,
    },
  },
})

import { types as rsaaTypes } from 'store/rsaa'
import { createDiagnostic, createErrorFromApiResponse } from 'utils/api'

import { DEFAULT_ERROR } from './constants'

const createFetchingPayloadHandler = entryId => action => {
  const rsaaMetadata = (action && action[rsaaTypes.RSAA]) || {}
  const { endpoint, method } = rsaaMetadata || {}
  const data = { entryId }
  const diagnostic = createDiagnostic(endpoint, method)
  const payload = { data, diagnostic }

  return payload
}

const createFetchingSuccessPayloadHandler = entryId => async (
  action,
  state,
  response,
) => {
  const rsaaMetadata = (action && action[rsaaTypes.RSAA]) || {}
  const { endpoint, method } = rsaaMetadata || {}
  const entry = response.json ? await response.json() : '{}'
  const data = { entry, entryId }
  const diagnostic = createDiagnostic(endpoint, method)
  const payload = { data, diagnostic }

  return payload
}

const createFetchingErrorPayloadHandler = entryId => async (
  action,
  state,
  response,
) => {
  const rsaaMetadata = (action && action[rsaaTypes.RSAA]) || {}
  const { endpoint, method } = rsaaMetadata || {}
  const data = { entryId }
  const error = await createErrorFromApiResponse(response, DEFAULT_ERROR)
  const diagnostic = createDiagnostic(endpoint, method, error)
  const payload = { data, diagnostic }

  return payload
}

export default {
  createFetchingPayloadHandler,
  createFetchingSuccessPayloadHandler,
  createFetchingErrorPayloadHandler,
}

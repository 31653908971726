const modalAddError = 'No modal root to append modal component to.'
const modalElementName = 'modalElement'
const modalRemoveError = 'No modal root to remove modal component from.'
// modal-root is defined in the main index.html. It is wholly independent from the
// app in the DOM.
const modalRootId = 'modal-root'

const getModalElement = () => document?.getElementById(modalRootId)

export const addModalToDom = modalElement => {
  const modalRoot = getModalElement()

  if (!modalRoot || !modalRoot.appendChild) {
    console.warn(modalAddError)

    return
  }

  modalRoot?.appendChild(modalElement)
}

export const createModalElement = () =>
  document?.createElement(modalElementName)

export const removeModalFromDom = modalElement => {
  const modalRoot = getModalElement()

  if (!modalRoot || !modalRoot.removeChild) {
    console.warn(modalRemoveError)

    return
  }

  modalRoot?.removeChild(modalElement)
}

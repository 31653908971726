import { H3Wrapper } from 'components/Header/component.styles'
import styled from 'styled-components'

const gutterWidths = {
  desktop: '2.5rem',
  tablet: '2.5rem',
  mobile: '1.25rem',
}

export const PageLayoutWrapper = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
  h3MarginLeft: '-' + gutterWidths.desktop,
  h3PaddingLeft: gutterWidths.desktop,
  padding: gutterWidths.desktop,
  paddingMobile: gutterWidths.mobile,
}))`
  background-color: ${({ theme }) => theme?.colors?.page?.backgroundColor};
  border-radius: 0.75rem 0.75rem 0 0;
  box-shadow: 0 1px 1625rem 2px rgba(166, 166, 166, 0.8);
  box-sizing: border-box;

  color: ${({ theme }) => theme?.colors?.page?.color};
  font-size: 1.125rem;
  line-height: 1.625rem;
  margin: auto;
  max-width: 72.875rem;
  padding: ${({ padding }) => padding};
  a {
    color: ${({ theme }) => theme?.colors?.links?.color};
    font-weight: 600;
    cursor: pointer;
    outline: none;
    text-decoration: none;
  }

  ${H3Wrapper} {
    margin-left: ${({ h3MarginLeft }) => h3MarginLeft};
    padding-left: ${({ h3PaddingLeft }) => h3PaddingLeft};
  }

  ${({ theme }) => theme?.breakpoints?.mobile} {
    border-radius: initial;
    box-shadow: none;
    font-size: 1rem;
    line-height: 1.5rem;
    padding: ${({ paddingMobile }) => paddingMobile};
  }
`

export const PageLayoutOuterWrapper = styled.div`
  padding: 3.125rem;

  ${({ theme }) => theme?.breakpoints?.mobile} {
    padding: 0;
  }
`

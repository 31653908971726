import React from 'react'

import PropTypes from 'prop-types'

import { Wrapper } from './component.styles'
import { DATA_TEST_ID_PREFIX } from './constants'

const MicrolearningDetailsComponent = ({ title, unit, time, id }) => {
  const dataTestId = `${DATA_TEST_ID_PREFIX}${id}`
  return (
    <Wrapper dataTestId={dataTestId} key={dataTestId}>
      {unit ? `${title}, Unit ${unit}` : `${title}`}
      <br />
      {time} min.
    </Wrapper>
  )
}

MicrolearningDetailsComponent.propTypes = {
  id: PropTypes.number,
  isUnitAvailable: PropTypes.func,
  time: PropTypes.number,
  title: PropTypes.string,
  unit: PropTypes.number,
}

export default MicrolearningDetailsComponent

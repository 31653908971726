import React, { useEffect } from 'react'

import PropTypes from 'prop-types'
import {
  addModalToDom,
  createModalElement,
  removeModalFromDom,
} from 'utils/modal'

import CloseableOverlayComponent from './component'

const CloseableOverlayContainer = ({ children, onClose, show }) => {
  const domElement = createModalElement()

  useEffect(() => {
    addModalToDom(domElement)

    const cleanup = () => removeModalFromDom(domElement)

    return cleanup
  }, [])

  return (
    <CloseableOverlayComponent
      domElement={domElement}
      onClose={onClose}
      show={show}
    >
      {children}
    </CloseableOverlayComponent>
  )
}

CloseableOverlayContainer.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
  show: PropTypes.bool,
}

CloseableOverlayContainer.defaultProps = {
  onClose() {},
}

export default CloseableOverlayContainer

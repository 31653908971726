import { selectors as interactivesSelectors } from 'store/interactives'
import { singleSelectSelectors } from 'store/interactives/selectors'

const answerValueDefault = 0
const initialScore = 0
const initialTotalAnswered = 0

export const getDataTestIdHeader = (pageComponentIndex = '0', id = '0') =>
  `survey-${pageComponentIndex}-header-${id}`
export const getDataTestIdIntroText = (pageComponentIndex = '0', id = '0') =>
  `survey-${pageComponentIndex}-intro-text-${id}`

// Given survey categories object from Contentful, return a formatted object
// with category ids as keys and score data as values.
export const getFormattedCategories = (
  microLearningId,
  categories,
  questionItems,
  interactives,
) => {
  const categoriesReducer = (formattedCategories = {}, category) => {
    const { id: onCategoryId, scoreText } = category || {}

    questionItems.forEach(question => {
      const { category: questionCategory, id: questionId } = question
      const { id: questionCategoryId } = questionCategory || {}

      if (onCategoryId === questionCategoryId) {
        const { score: categoryScore = initialScore } =
          formattedCategories[onCategoryId] || {}
        const answerValue =
          singleSelectSelectors.answerValue(
            { interactives },
            microLearningId,
            questionId,
          ) || answerValueDefault
        const score = categoryScore + answerValue
        const formattedCategory = {
          scoreText,
          score,
        }
        formattedCategories[onCategoryId] = formattedCategory
      }
    })

    return formattedCategories
  }

  const formattedCategories = categories.reduce(categoriesReducer, {})

  return formattedCategories
}

// Get an object with category ids as keys and max possible scores as values.
export const getMaxPossibleScores = (questionItems = []) => {
  // This makes an assumption that scores are originally in ascending order based on
  // answerValue. If that changes, the maxPossibleScores calculation will break.
  // This has been discussed with Angie
  const maxPossibleScoresReducer = (accum, currentQuestion) => {
    const answerChoicesDescending = currentQuestion?.answerChoices
      ?.slice()
      ?.reverse()
    const categoryId = currentQuestion?.category?.id
    let categoryTotalMaxScore = accum[categoryId] || initialScore

    // Loops thru array in reverse order to minimize nested looping in this reduce function.
    for (const answer of answerChoicesDescending) {
      const { answerValue } = answer || {}

      if (answerValue) {
        accum[categoryId] = categoryTotalMaxScore + answerValue
        break
      }
    }

    return accum
  }
  const maxPossibleScores = questionItems.reduce(maxPossibleScoresReducer, {})

  return maxPossibleScores
}

//Loop through all survey questions. For each question, get from redux whether it has been answered.
export const getTotalAnswered = (
  microLearningId,
  questionItems = [],
  interactives,
) => {
  const totalAnsweredReducer = (totalAnswered, currentQuestion) => {
    const interactiveId = interactivesSelectors.interactiveId(currentQuestion)
    const selections = singleSelectSelectors.selections(
      { interactives },
      microLearningId,
      interactiveId,
    )
    const newTotalAnswered = selections ? totalAnswered + 1 : totalAnswered

    return newTotalAnswered
  }
  const totalAnswered = questionItems.reduce(
    totalAnsweredReducer,
    initialTotalAnswered,
  )

  return totalAnswered
}

import { ERROR_SUFFIX, SUCCESS_SUFFIX } from './constants'

const ACTION_TYPES = {
  FETCHING: 'FETCHING',
  UPDATING: 'UPDATING',
  UPDATING_WITH_RETRIES: 'UPDATING_WITH_RETRIES',
}
const BASE_TYPES = {
  APP: 'app',
  CONTENTFUL: 'contentful',
  LMS: 'lms',
}
const SUBTYPES = {
  CMI: 'cmi',
  ENTRY: 'entry',
  INTERACTIVES: 'interactives',
}

// Gets the 3 types for a redux lifecycle - in progress, error, success
export const getTypes = (actionType, baseType, subType) => {
  if (!baseType) {
    console.warn('Error creating redux types. No action type provided.')

    return {}
  }

  const formattedSubType = subType ? `${subType}/` : ''
  const prefix = `${baseType}/${formattedSubType}${actionType}`
  const types = {
    [actionType]: prefix,
    [`${actionType}${ERROR_SUFFIX}`]: `${prefix}${ERROR_SUFFIX}`,
    [`${actionType}${SUCCESS_SUFFIX}`]: `${prefix}${SUCCESS_SUFFIX}`,
  }

  return types
}

export default {
  ACTION_TYPES,
  BASE_TYPES,
  SUBTYPES,
  SET_VALUE_QUEUE: 'API_ASYNC_SET_VALUE',
}

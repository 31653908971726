import React from 'react'

import SkipToMain from 'components/SkipToMain'
import PropTypes from 'prop-types'

import {
  BackButton,
  BackText,
  MlNumber,
  MlTitle,
  NavHeaderTitle,
  NavHeaderWrapper,
  SecondaryItemsWrapper,
  StyledNavArrow,
} from './component.styles'
import { appSavingStatus, BACK_TEXT } from './constants'
import SaveStatus from './SaveStatus'

const NavHeaderComponent = ({
  displayTitle,
  disabled,
  label,
  navigateBack,
  status,
}) => {
  return (
    <NavHeaderWrapper
      dataTestId={`navigation-header-wrapper`}
      role="navigation"
    >
      <BackButton
        aria-label="Back Button"
        dataTestId={`nav-back-button`}
        disabled={disabled}
        onClick={navigateBack}
      >
        <StyledNavArrow />
        <BackText>{BACK_TEXT}</BackText>
      </BackButton>
      <NavHeaderTitle data-testid="navigation-header-title">
        <MlNumber data-testid="ml-number">{label}:</MlNumber>
        <MlTitle data-testid="ml-title">{displayTitle}</MlTitle>
      </NavHeaderTitle>
      <SecondaryItemsWrapper>
        <SkipToMain />
        <SaveStatus status={status} />
      </SecondaryItemsWrapper>
    </NavHeaderWrapper>
  )
}

NavHeaderComponent.defaultProps = {
  navigateBack: () => {},
}

NavHeaderComponent.propTypes = {
  disabled: PropTypes.bool,
  displayTitle: PropTypes.string,
  label: PropTypes.string,
  navigateBack: PropTypes.func,
  navigateIntoId: PropTypes.func,
  status: PropTypes.oneOf(appSavingStatus),
}

export default NavHeaderComponent

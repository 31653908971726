import { ERROR_TYPES } from 'config/constants'

const DEFAULT_ERROR_MESSAGE =
  'Error: There was a problem performing the local action.'
const DEFAULT_STATUS = '101'
const DEFAULT_STATUS_TEXT = 'General local API exception'
const ERROR_TYPE = ERROR_TYPES.LOCAL_ERROR
export const DEFAULT_ERROR = {
  message: DEFAULT_ERROR_MESSAGE,
  name: ERROR_TYPE,
  status: DEFAULT_STATUS,
  statusText: DEFAULT_STATUS_TEXT,
}

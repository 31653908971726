import { TextLink } from 'secondstep-components'
import styled from 'styled-components'

export const LinkBox = styled.div`
  margin: 1.75rem 0;
  font-family: ${({ theme }) => theme?.fonts?.textLinkWithIcon?.text?.family};
  font-weight: ${({ theme }) => theme?.fonts?.textLinkWithIcon?.text?.weight};
`
export const StyledTextLink = styled(TextLink)`
  color: ${({ theme }) => theme?.colors?.links?.color};
  &:hover {
    color: ${({ theme }) => theme?.colors?.links?.hoverColor};
  }
  &:focus {
    color: ${({ theme }) => theme?.colors?.links?.hoverColor};
  }
`

export default {
  accordion: {
    header: { family: 'LufgaCFC', weight: 500 },
  },
  autopopSurvey: {
    header: 'LufgaCFC-Medium',
    question: { family: 'Molde', weight: 500 },
  },
  bodyCopy: '',
  errorMessage: {
    header: { family: 'LufgaCFC', weight: 700 },
    strong: { family: 'Molde', weight: 500 },
    button: { family: 'LufgaCFC', weight: 600 },
  },
  heading: {
    level: {
      1: { family: 'LufgaCFC', weight: 700 },
      2: { family: 'LufgaCFC', weight: 600 },
      3: { family: 'LufgaCFC', weight: 400 },
    },
  },
  markComplete: {
    feedbackLink: { family: 'Molde', weight: 500 },
    header: { family: 'LufgaCFC', weight: 500 },
    markButton: { family: 'LufgaCFC', weight: 600 },
  },
  modal: {
    header: { family: 'LufgaCFC', weight: 500 },
    refreshButton: { family: 'LufgaCFC', weight: 600 },
  },
  multiSelectQuestion: {
    questionText: { family: 'Molde', weight: 500 },
  },
  navHeader: {
    displayTitleFont: 'LufgaCFC',
  },
  pullQuote: 'LufgaCFC',
  scoreCard: {
    header: { family: 'LufgaCFC', weight: 500 },
    score: { family: 'Molde', weight: 500 },
  },
  singleSelectQuestion: {
    questionText: { family: 'Molde', weight: 500 },
  },
  survey: {
    header: { family: 'LufgaCFC', weight: 500 },
  },
  textButton: {
    text: { family: 'Molde', weight: 500 },
  },
  textEntry: {
    label: { family: 'Molde', weight: 500 },
  },
  textLinkWithIcon: {
    text: { family: 'Molde', weight: 500 },
  },
}

import React from 'react'

import ModalCloseIcon from 'components/ModalCloseIcon'
import { Button } from 'grommet'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const ModalCloseButton = styled(Button).attrs(({ dataTestId }) => ({
  a11yTitle: 'Close',
  'data-testid': dataTestId,
  plain: true,
}))`
  :hover {
    svg {
      transition: color 0.4s ease-in-out;
      color: ${({ theme }) => theme?.colors?.modal?.closeButton?.hoverColor};
    }
  }
`

ModalCloseButton.propTypes = {
  dataTestId: PropTypes.string.isRequired,
  icon: PropTypes.node,
  onClick: PropTypes.func.isRequired,
}

ModalCloseButton.defaultProps = {
  icon: <ModalCloseIcon />,
}

export default ModalCloseButton

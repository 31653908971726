import {
  HeaderFour,
  HeaderOne,
  HeaderThree,
  HeaderTwo,
} from 'secondstep-components'
import styled from 'styled-components'

export const Wrapper = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  line-height: 1.375rem;
`

export const H1 = styled(HeaderOne)`
  border-radius: 0.75rem 0.75rem 0 0;
  color: ${({ theme }) => theme?.colors?.heading?.level[1]?.color};
  font-family: ${({ theme }) => theme?.fonts?.heading?.level[1]?.family};
  font-weight: ${({ theme }) => theme?.fonts?.heading?.level[1]?.weight};
  font-size: 2.625rem;
  letter-spacing: 0rem;
  line-height: 3rem;
  margin: -9.625rem -2.5rem -6.125rem -2.5rem;
  padding: 10.0625rem 1.75rem 6.3125rem 2.5rem;

  &.highlighted {
    background-color: ${({ theme, backgroundColor }) =>
      backgroundColor || theme?.colors?.header?.backgroundColor};
  }
  
  svg {
    margin-left: 20px;
  }
  
  }

  ${({ theme }) => theme?.breakpoints?.mobile} {
    margin: -13.25rem -2.5rem -6.125rem -2.5rem;
    padding: 13.0625rem 1.75rem 6.3125rem 2.5rem;
    line-height: 2.75rem;
  }
}
`

export const H2 = styled(HeaderTwo)`
  color: ${({ theme }) => theme?.colors?.heading?.level[2]?.color};
  font-family: ${({ theme }) => theme?.fonts?.heading?.level[2]?.family};
  font-weight: ${({ theme }) => theme?.fonts?.heading?.level[2]?.weight};
  font-size: 1.75rem;
  letter-spacing: 0rem;
  line-height: 2.125rem;
  margin: 2.5rem 0 1.25rem;

  ${({ theme }) => theme?.breakpoints?.mobile} {
    font-size: 1.625rem;
    line-height: 2rem;
  }
`

export const H3 = styled(HeaderThree)`
  color: ${({ theme }) => theme?.colors?.heading?.level[3]?.color};
  font-family: ${({ theme }) => theme?.fonts?.heading?.level[2]?.family};
  font-weight: ${({ theme }) => theme?.fonts?.heading?.level[2]?.weight};
  font-size: 1.125rem;
  letter-spacing: 0.075rem;
  line-height: 1rem;
  margin: 0;

  ${({ theme }) => theme?.breakpoints?.mobile} {
    font-size: 1rem;
  }
`

//This wrapper is necessary since display inline block is used in H3Wrapper to control the background color fill.
export const H3HeightWrapper = styled.div`
  margin: 0.375rem 0 1.75rem 0;

  ${({ theme }) => theme?.breakpoints?.mobile} {
    margin: 0.125rem 0 1.25rem 0;
  }
`

export const H3Wrapper = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor || theme?.colors?.heading?.level[3]?.backgroundColor};
  border-radius: 0 6.25rem 6.25rem 0;
  display: inline-block;
  padding: 0.75rem 1rem 0.75rem;

  ${({ theme }) => theme?.breakpoints?.mobile} {
    padding: 0.75rem 0.75rem 0.75rem;
  }
`

export const H4 = styled(HeaderFour)`
  color: ${({ theme }) => theme?.colors?.heading?.level[4]?.color};
  font-family: ${({ theme }) => theme?.fonts?.heading?.level[3]?.family};
  font-weight: ${({ theme }) => theme?.fonts?.heading?.level[3]?.weight};
  font-size: 1.25rem;
  letter-spacing: 0.015625rem;
  line-height: 1.625rem;
  max-width: none;
`

import { getTypes, default as types } from 'store/types'

const actionType = types?.ACTION_TYPES?.FETCHING
const baseType = types?.BASE_TYPES?.CONTENTFUL
const subType = types?.SUBTYPES?.ENTRY
const contentfulTypes = getTypes(actionType, baseType, subType)

export default {
  ...contentfulTypes,
}

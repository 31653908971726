const createFetchingAction = types => payload => ({
  type: types.FETCHING,
  payload,
})

const createFetchingErrorAction = types => payload => ({
  type: types.FETCHING_ERROR,
  payload,
})

const createFetchingSuccessAction = types => payload => ({
  type: types.FETCHING_SUCCESS,
  payload,
})

const createUpdatingAction = types => payload => ({
  type: types.UPDATING,
  payload,
})

const createUpdatingErrorAction = types => payload => ({
  type: types.UPDATING_ERROR,
  payload,
})

const createUpdatingSuccessAction = types => payload => ({
  type: types.UPDATING_SUCCESS,
  payload,
})

const createUpdatingWithRetriesAction = types => payload => ({
  type: types.UPDATING_WITH_RETRIES,
  payload,
})

const createUpdatingWithRetriesErrorAction = types => payload => ({
  type: types.UPDATING_WITH_RETRIES_ERROR,
  payload,
})

const createUpdatingWithRetriesSuccessAction = types => payload => ({
  type: types.UPDATING_WITH_RETRIES_SUCCESS,
  payload,
})

const createFetchingActions = types => {
  const fetching = createFetchingAction(types)
  const fetchingError = createFetchingErrorAction(types)
  const fetchingSuccess = createFetchingSuccessAction(types)
  const fetchingActions = {
    fetching,
    fetchingError,
    fetchingSuccess,
  }

  return fetchingActions
}

const createUpdatingActions = types => {
  const updating = createUpdatingAction(types)
  const updatingError = createUpdatingErrorAction(types)
  const updatingSuccess = createUpdatingSuccessAction(types)
  const updatingActions = {
    updating,
    updatingError,
    updatingSuccess,
  }

  return updatingActions
}

// withRetries actions are actions that go through the full lifecycle and checks. (queued, retried,
// connected to visual saving status, etc)
const createUpdatingWithRetriesActions = types => {
  const updatingWithRetries = createUpdatingWithRetriesAction(types)
  const updatingWithRetriesError = createUpdatingWithRetriesErrorAction(types)
  const updatingWithRetriesSuccess = createUpdatingWithRetriesSuccessAction(
    types,
  )
  const updatingWithRetriesActions = {
    updatingWithRetries,
    updatingWithRetriesError,
    updatingWithRetriesSuccess,
  }

  return updatingWithRetriesActions
}

export default {
  createFetchingActions,
  createUpdatingActions,
  createUpdatingWithRetriesActions,
}

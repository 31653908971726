import React from 'react'

import PropTypes from 'prop-types'
import { IconDoc, IconExcel, IconPDF, IconPpt } from 'secondstep-components'

import TextLinkWithIconComponent from './component'
import { EXCEL_DOC, PDF, POWERPOINT_DOC, WORD_DOC } from './constants'

const TextLinkWithIconContainer = ({ asset, id }) => {
  function parseIconType(contentType, id) {
    const fileTypeId = contentType?.id

    if (fileTypeId === PDF)
      return <IconPDF data-testid={`pdf-download-icon-${id}`} />
    else if (fileTypeId === WORD_DOC) return <IconDoc />
    else if (fileTypeId === POWERPOINT_DOC) return <IconPpt />
    else if (fileTypeId === EXCEL_DOC) return <IconExcel />
    else {
      console.error('Icon type not found')
      return null
    }
  }

  const textLinkWithIconProps = {
    asset,
    id,
    parseIconType,
  }

  return <TextLinkWithIconComponent {...textLinkWithIconProps} />
}

TextLinkWithIconContainer.propTypes = {
  asset: PropTypes.object,
  id: PropTypes.string,
}

export default TextLinkWithIconContainer

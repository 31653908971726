export const CMI_KEYS = {
  COMPLETION_STATUS: 'completion_status',
  PROGRESS_MEASURE: 'progress_measure',
  SUSPEND_DATA: 'suspend_data',
}
export const COMPLETION_STATUS = {
  COMPLETED: 'completed',
  INCOMPLETE: 'incomplete',
  NOT_ATTEMPTED: 'not_attempted',
  UNKNOWN: 'unknown',
}

import React from 'react'

import ContentMapper from 'lib/contentMapper'
import PropTypes from 'prop-types'

import LessonAccordionComponent from './component'

const LessonAccordionContainer = ({
  content,
  title,
  id,
  pageComponentIndex,
}) => {
  const contentMapper = new ContentMapper()
  const contentJsx = contentMapper.mapToComponents(content)

  return (
    <LessonAccordionComponent
      id={id}
      pageComponentIndex={pageComponentIndex}
      title={title}
    >
      {contentJsx}
    </LessonAccordionComponent>
  )
}

LessonAccordionContainer.propTypes = {
  content: PropTypes.array,
  id: PropTypes.string,
  pageComponentIndex: PropTypes.string,
  title: PropTypes.string,
}

LessonAccordionContainer.defaultProps = {
  pageComponentIndex: '0',
  title: '',
}

export default LessonAccordionContainer

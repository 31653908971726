import { operations as appOperations } from 'store/app'

// Hydration status manager keeps track of all lms hydration status and marks isLmsHydrated
// in redux as true once all required hydrations have occurred to present a microlearning.

class lmsHydrationStatusManager {
  cmiHydrated = {}
  interactivesHydrated = {}
  #isLmsHydrated = false
  numOfSecondaryMicroLearnings // set externally

  resetHydrationStatus = () => {
    this.cmiHydrated = {}
    this.interactivesHydrated = {}
    this.#isLmsHydrated = false
  }

  setCmiHydrated = (dispatch, microLearningEntryId) => {
    if (!microLearningEntryId) {
      return
    }

    this.cmiHydrated[microLearningEntryId] = true

    this.#checkHydrationComplete(dispatch)
  }

  setInteractivesHydrated = (dispatch, microLearningEntryId) => {
    if (!microLearningEntryId) {
      return
    }

    this.interactivesHydrated[microLearningEntryId] = true

    this.#checkHydrationComplete(dispatch)
  }

  #checkHydrationComplete = dispatch => {
    if (this.#isLmsHydrated) {
      return
    }

    const microLearningsInteractivesHydratedKeys = Object.keys(
      this.interactivesHydrated,
    )
    const microLearningsInteractivesHydratedValues = Object.values(
      this.interactivesHydrated,
    )
    const microLearningsCmiHydratedKeys = Object.keys(this.cmiHydrated)
    const microLearningsCmiHydratedValues = Object.values(this.cmiHydrated)
    const numOfHydratedInteractivesMicroLearnings =
      microLearningsInteractivesHydratedKeys.length
    const numOfMicroLearningsCmiHydrated = microLearningsCmiHydratedKeys.length
    const numOfPrimaryMicroLearnings = 1
    const totalNumOfMicroLearnings =
      numOfPrimaryMicroLearnings + this.numOfSecondaryMicroLearnings
    const isCmiHydrated =
      numOfMicroLearningsCmiHydrated === numOfPrimaryMicroLearnings &&
      microLearningsCmiHydratedValues.every(isHydrated => isHydrated)
    const isInteractivesHydrated =
      numOfHydratedInteractivesMicroLearnings === totalNumOfMicroLearnings &&
      microLearningsInteractivesHydratedValues.every(isHydrated => isHydrated)
    const isReduxFullyHydrated = isInteractivesHydrated && isCmiHydrated

    if (!isReduxFullyHydrated) {
      return
    }

    this.#isLmsHydrated = true
    dispatch && dispatch(appOperations.update({ isLmsHydrated: true }))
  }
}

export default new lmsHydrationStatusManager()

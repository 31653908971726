import { default as lmsPayloads } from 'lib/api/lms/payloads'
import { all, delay, put, select, takeLatest } from 'redux-saga/effects'
import { operations as appOperations } from 'store/app'
import {
  actions as interactivesActions,
  selectors as interactivesSelectors,
  types as interactiveTypes,
} from 'store/interactives'
import { default as baseTypes } from 'store/types'
import { updateInteractivesData } from 'utils/state'

import { CMI_KEYS } from '../cmi/constants'

export function* updateInteractives({ payload }) {
  const { data, interactiveId, microLearningEntryId } = payload || {}
  const appState = yield select()
  const interactives = interactivesSelectors.interactives(appState)
  const updatingPayloadArgs = {
    data: { [interactiveId]: data },
    microLearningEntryId,
  }
  const updatingPayload = lmsPayloads.updating(updatingPayloadArgs)
  yield put(appOperations.savingApp())
  yield put(appOperations.lock())
  yield put(interactivesActions.updating(updatingPayload))

  const updateSuccessData = updateInteractivesData(
    interactives,
    microLearningEntryId,
    interactiveId,
    data,
  )

  yield put({
    data: updateSuccessData,
    key: CMI_KEYS.SUSPEND_DATA,
    microLearningEntryId,
    onError: diagnostic => ({
      payload: {
        data,
        diagnostic,
        microLearningEntryId,
      },
      type: interactiveTypes.UPDATING_WITH_RETRIES_ERROR,
    }),
    onRetry: () => appOperations.unableToSaveApp(),
    onSuccess: diagnostic => ({
      payload: {
        diagnostic,
        microLearningEntryId,
      },
      type: interactiveTypes.UPDATING_WITH_RETRIES_SUCCESS,
    }),
    type: baseTypes.SET_VALUE_QUEUE,
  })
}

export function* updateInteractivesError({ payload }) {
  const { data, diagnostic, microLearningEntryId } = payload || {}
  const updatingErrorPayloadArgs = {
    data,
    diagnostic,
    microLearningEntryId,
  }
  const updatingErrorPayload = lmsPayloads.updatingError(
    updatingErrorPayloadArgs,
  )
  yield put(interactivesActions.updatingError(updatingErrorPayload))
  yield put(appOperations.unlock())
  yield put(appOperations.failedToSave())
}

export function* updateInteractiveSuccess({ payload }) {
  /*
   * In order to improve UX, we use a delay to provide feedback to the user
   * that we are saving the app as in much cases the operation will be really fast
   * and almost invisible to human eye.
   * */
  const { diagnostic, microLearningEntryId } = payload || {}
  const untilSavingIsVisible = 1500
  const updatingSuccessPayloadArgs = { microLearningEntryId, diagnostic }
  const updatingSuccessPayload = lmsPayloads.updatingSuccess(
    updatingSuccessPayloadArgs,
  )
  yield put(interactivesActions.updatingSuccess(updatingSuccessPayload))
  yield put(appOperations.unlock())
  yield delay(untilSavingIsVisible)
  yield put(appOperations.savedApp())
}

export default function* rootSaga() {
  yield all([
    takeLatest(interactiveTypes.UPDATING_WITH_RETRIES, updateInteractives),
    takeLatest(
      interactiveTypes.UPDATING_WITH_RETRIES_SUCCESS,
      updateInteractiveSuccess,
    ),
    takeLatest(
      interactiveTypes.UPDATING_WITH_RETRIES_ERROR,
      updateInteractivesError,
    ),
  ])
}

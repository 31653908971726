import React from 'react'

import PropTypes from 'prop-types'

import {
  NavArrowStyled,
  NavArrowWrapperNext,
  NavArrowWrapperPrevious,
  NavFooterButton,
  NavFooterButtonWrapper,
  NavFooterInfo,
  NavFooterInnerWrapper,
  NavFooterWrapper,
} from './component.styles'
import { DIRECTION_NEXT, DIRECTION_PREVIOUS, FOOTER_TEXT } from './constants'

const NavFooterComponent = ({
  currentUnitTitle,
  disabled,
  isLessonComplete,
  isNextLesson,
  isPreviousLesson,
  navigateToLesson,
  nextLessonType,
  previousLessonType,
  renderButtonText,
  upNextDetails,
}) => {
  const navFooterInfoText = isNextLesson ? (
    <NavFooterInfo>
      <strong>Up Next:</strong> {upNextDetails}
    </NavFooterInfo>
  ) : (
    <NavFooterInfo>
      <strong>{FOOTER_TEXT}</strong>
      {currentUnitTitle}
    </NavFooterInfo>
  )
  return (
    <NavFooterWrapper aria-label="module navigation" role="navigation">
      <NavFooterInnerWrapper>
        {navFooterInfoText}
        <NavFooterButtonWrapper isPreviousLesson={isPreviousLesson}>
          {isPreviousLesson && (
            <NavFooterButton
              buttonText={renderButtonText(
                previousLessonType,
                DIRECTION_PREVIOUS,
              )}
              dataTestId={`nav-footer-previous-button`}
              disabled={disabled}
              navigationDirText={DIRECTION_PREVIOUS}
              onClick={() => navigateToLesson(DIRECTION_PREVIOUS)}
            >
              <NavArrowWrapperPrevious>
                <NavArrowStyled />
              </NavArrowWrapperPrevious>
              <span />
            </NavFooterButton>
          )}
          {isNextLesson && (
            <NavFooterButton
              buttonText={renderButtonText(nextLessonType, DIRECTION_NEXT)}
              dataTestId={`nav-footer-next-button`}
              disabled={disabled}
              isLessonComplete={isLessonComplete}
              navigationDirText={DIRECTION_NEXT}
              onClick={() => navigateToLesson(DIRECTION_NEXT)}
            >
              <span />
              <NavArrowWrapperNext>
                <NavArrowStyled isLessonComplete={isLessonComplete} />
              </NavArrowWrapperNext>
            </NavFooterButton>
          )}
        </NavFooterButtonWrapper>
      </NavFooterInnerWrapper>
    </NavFooterWrapper>
  )
}

NavFooterComponent.defaultProps = {
  navigateToLesson: () => {},
  renderButtonText: () => {},
}

NavFooterComponent.propTypes = {
  currentUnitIndex: PropTypes.number,
  currentUnitTitle: PropTypes.string,
  disabled: PropTypes.bool,
  isLessonComplete: PropTypes.bool,
  isNextLesson: PropTypes.bool,
  isPreviousLesson: PropTypes.bool,
  navigateToLesson: PropTypes.func,
  nextLessonId: PropTypes.string,
  nextLessonType: PropTypes.string,
  previousLessonId: PropTypes.string,
  previousLessonType: PropTypes.string,
  renderButtonText: PropTypes.func,
  upNextDetails: PropTypes.string,
}

NavFooterComponent.defaultProps = {
  nextLessonType: '',
  upNextDetails: '',
}

export default NavFooterComponent

import { ENDPOINTS, HTTP_METHODS } from 'lib/api/constants'
import { DEFAULT_ERROR } from 'lib/api/lms/constants'
import { default as lmsPayloads } from 'lib/api/lms/payloads'
import LmsConnectionsManager from 'lib/LmsConnectionsManager'
import LmsHydrationStatusManager from 'lib/LmsHydrationStatusManager'
import { CMI_KEYS } from 'store/cmi/constants'
import { createDiagnostic, createErrorFromMessage } from 'utils/api'

import { default as interactivesActions } from './actions'
import { default as interactivesTypes } from './types'

// Hydrate redux suspend_data (interactives) value from state persisted on LMS.
// TODO: think through ordering of use of getState here. We need to be SURE that it is accurate to where
// we need it to be at the time of running it in the app. Could be out of sync of what we THINK it is
const hydrate = microLearningEntryId => async (dispatch, getState) => {
  const appState = getState()

  try {
    const fetchingData = {}
    const fetchingPayloadArgs = {
      data: fetchingData,
      microLearningEntryId,
    }
    const payload = lmsPayloads.fetching(fetchingPayloadArgs)

    dispatch(interactivesActions.fetching(payload))

    const api = LmsConnectionsManager.getApiConnection(
      microLearningEntryId,
      appState,
    )
    const lmsResponse = await api.getValue(CMI_KEYS.SUSPEND_DATA)
    const { data: lmsResponseData, diagnostic } = lmsResponse || {}
    const fetchingSuccessPayloadArgs = {
      data: lmsResponseData,
      diagnostic,
      microLearningEntryId,
    }
    const payloadSuccess = lmsPayloads.fetchingSuccess(
      fetchingSuccessPayloadArgs,
    )

    dispatch(interactivesActions.fetchingSuccess(payloadSuccess))
    LmsHydrationStatusManager.setInteractivesHydrated(
      dispatch,
      microLearningEntryId,
    )
  } catch (errorMessage) {
    console.warn(
      `Error: Interactives (suspend_data) hydration failed - ${errorMessage}`,
    )

    const error = createErrorFromMessage(errorMessage, DEFAULT_ERROR)
    const diagnostic = createDiagnostic(ENDPOINTS.LMS, HTTP_METHODS.GET, error)
    const fetchingErrorPayloadArgs = {
      data: { [microLearningEntryId]: {} },
      diagnostic,
      microLearningEntryId,
    }
    const payloadError = lmsPayloads.fetchingError(fetchingErrorPayloadArgs)

    dispatch(interactivesActions.fetchingError(payloadError))

    throw errorMessage
  }
}

const update = (
  microLearningEntryId,
  interactiveId,
  data,
) => async dispatch => {
  const updatingWithRetriesPayloadArgs = {
    type: interactivesTypes.UPDATING_WITH_RETRIES,
    data,
    interactiveId,
    microLearningEntryId,
  }
  const updatingWithRetriesPayload = lmsPayloads.updatingWithRetries(
    updatingWithRetriesPayloadArgs,
  )

  dispatch(interactivesActions.updatingWithRetries(updatingWithRetriesPayload))
}

export default {
  hydrate,
  update,
}

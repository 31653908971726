const contentType = interactiveProps =>
  interactiveProps?.contentType?.id?.toLowerCase()
const interactives = ({ interactives }) => interactives
const interactive = ({ interactives }, microLearningId, interactiveId) =>
  interactives && interactives[microLearningId]?.data[interactiveId]
const interactiveId = interactiveProps => interactiveProps?.id

export default {
  contentType,
  interactive,
  interactiveId,
  interactives,
}

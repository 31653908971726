import { ENDPOINTS, HTTP_METHODS } from 'lib/api/constants'
import { createDiagnostic, createErrorFromMessage } from 'utils/api'

import { DEFAULT_ERROR } from './constants'

const fetching = (data = {}) => ({
  data,
  diagnostic: createDiagnostic(ENDPOINTS.LOCAL, HTTP_METHODS.GET),
})

const fetchingSuccess = (data = {}) => ({
  data,
  diagnostic: createDiagnostic(ENDPOINTS.LOCAL, HTTP_METHODS.GET),
})

const fetchingError = (data = {}, errorMessage) => {
  const error = createErrorFromMessage(errorMessage, DEFAULT_ERROR)
  const diagnostic = createDiagnostic(ENDPOINTS.LOCAL, HTTP_METHODS.GET, error)
  const payload = { data, diagnostic }

  return payload
}

const updating = (data = {}) => ({
  data,
  diagnostic: createDiagnostic(ENDPOINTS.LOCAL, HTTP_METHODS.POST),
})

const updatingSuccess = (data = {}) => ({
  data,
  diagnostic: createDiagnostic(ENDPOINTS.LOCAL, HTTP_METHODS.POST),
})

const updatingError = (data = {}, errorMessage) => {
  const error = createErrorFromMessage(errorMessage, DEFAULT_ERROR)
  const diagnostic = createDiagnostic(ENDPOINTS.LOCAL, HTTP_METHODS.POST, error)
  const payload = { data, diagnostic }

  return payload
}

export default {
  fetching,
  fetchingSuccess,
  fetchingError,
  updating,
  updatingSuccess,
  updatingError,
}

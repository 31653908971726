import env from 'config/build'

const { LEARN_DOMAIN } = env.domains || {}

export const BACK = 'Back to Dashboard'
export const DASHBOARD = `dashboard`
export const DASHBOARD_URL = `${LEARN_DOMAIN}/${DASHBOARD}`
export const DATA_TEST_ID_BACK_BUTTON = 'button-back'
export const DATA_TEST_ID_DIAGNOSTIC = 'error-code'
export const DATA_TEST_ID_FRIENDLY_MESSAGE = 'details-error-code'
export const DATA_TEST_ID_HEADER = 'error-details-header'
export const DATA_TEST_ID_ICON = 'icon-warning'
export const DATA_TEST_ID_TRY_AGAIN_BUTTON = 'button-try-again'
export const ERROR_CODE = 'Error code:'
export const FRIENDLY_MESSAGE = 'There was an error retrieving page content.'
export const HEADER = "Sorry! There's been an error. Please try again."
export const HEADER_NO_RETRY = "Sorry! There's been an error."
export const HIDE_DETAILS = 'Hide Details'
export const SERVICE_CODE = 'Service code:'
export const SHOW_DETAILS = 'Show Details'
export const TRY_AGAIN = 'Try Again'

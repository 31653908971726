import { Markdown } from 'grommet'
import styled from 'styled-components'

export const PullQuoteOuterWrapper = styled.div`
  &.gradient-small,
  &.gradient-leader {
    margin: 0 -2.5rem;
    padding: 0 2.5rem;
    background: ${({ theme }) =>
      `linear-gradient(to bottom, ${theme?.colors?.pullQuote?.gradient?.top} 50%, ${theme?.colors?.pullQuote?.gradient?.bottom} 50%);`};

    ${({ theme }) => theme?.breakpoints?.mobile} {
      margin: 0 -1.25rem;
      padding: 0 1.25rem;
    }
  }
`

export const PullQuoteWrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme?.colors?.pullQuote?.backgroundColor};
  border-radius: 0 3.8125rem;
  border: 0.25rem solid white;
  color: ${({ theme }) => theme?.colors?.pullQuote?.color};
  display: flex;
  justify-content: center;
  margin: 2.5rem 0;
  padding: 2.5rem;

  ${({ theme }) => theme?.breakpoints?.mobile} {
    padding: 1.75rem;
  }
`

export const PullQuoteText = styled(Markdown)`
  font-family: ${({ theme }) => theme?.fonts?.pullQuote};
  font-size: 1.25rem;

  ${({ theme }) => theme?.breakpoints?.mobile} {
    font-size: 1.125rem;
  }
`

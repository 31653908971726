import React from 'react'

import PropTypes from 'prop-types'

import {
  CategoryWrapper,
  Footer,
  Header,
  MultiCategoryHeaderWrapper,
  MultiCategoryWrapper,
  Score,
  ScoreText,
  ScoreTotal,
  ScoreWrapper,
  SingleCategoryWrapper,
  SurveyHorizRule,
} from './component.styles'
import { INITIAL_SCORE, SLASH, TOTAL_SCORE } from './constants'
import {
  getHeaderDataTestId,
  getScoreDataTestId,
  getWrapperDataTestId,
} from './data'

const ScorecardComponent = ({
  categories,
  footerText,
  introText,
  isAutoPop,
  isSingleCategory,
  maxPossibleScores,
  pageComponentIndex,
}) => {
  const getScoreComponent = (categoryScore, maxCategoryScore, index) => {
    const dataTestId = getScoreDataTestId(index)
    const scoreText = `${categoryScore}${SLASH}${maxCategoryScore}`

    return (
      <ScoreWrapper dataTestId={dataTestId} isSingleCategory={isSingleCategory}>
        <ScoreTotal>{TOTAL_SCORE}</ScoreTotal>
        <Score isAutoPop={isAutoPop}>{scoreText}</Score>
      </ScoreWrapper>
    )
  }

  const mapSingleCategory = () => {
    const categoryId = Object.keys(categories)[0]
    const { score: categoryScore } = categories[categoryId] || INITIAL_SCORE
    const maxCategoryScore = maxPossibleScores[categoryId] || 0
    const component = getScoreComponent(categoryScore, maxCategoryScore, 0)

    return component
  }

  const mapMultipleCategories = () => {
    const categoryComponents = Object.keys(categories).map(
      (categoryId, index) => {
        const { score: categoryScore, scoreText: categoryScoreText } =
          categories[categoryId] || {}
        const maxCategoryScore = maxPossibleScores[categoryId] || INITIAL_SCORE
        const scoreComponent = getScoreComponent(
          categoryScore,
          maxCategoryScore,
          index,
        )
        const component = (
          <CategoryWrapper key={categoryId}>
            <ScoreText>{categoryScoreText}</ScoreText>
            {scoreComponent}
          </CategoryWrapper>
        )

        return component
      },
    )

    return categoryComponents
  }

  const Wrapper = isSingleCategory
    ? SingleCategoryWrapper
    : MultiCategoryWrapper
  const categoryComponents = !isSingleCategory
    ? mapMultipleCategories()
    : mapSingleCategory()
  const dataTestId = getWrapperDataTestId(pageComponentIndex)
  const hasMultiCategoryHeader = !isSingleCategory && introText

  return (
    <Wrapper dataTestId={dataTestId}>
      {hasMultiCategoryHeader && (
        <MultiCategoryHeaderWrapper
          key={getHeaderDataTestId(pageComponentIndex)}
        >
          <Header>{introText}</Header>
          <SurveyHorizRule />
        </MultiCategoryHeaderWrapper>
      )}
      {categoryComponents}
      <Footer isSingleCategory={isSingleCategory}>{footerText}</Footer>
    </Wrapper>
  )
}

ScorecardComponent.propTypes = {
  categories: PropTypes.object,
  footerText: PropTypes.string,
  introText: PropTypes.string,
  isAutoPop: PropTypes.bool,
  isSingleCategory: PropTypes.bool,
  maxPossibleScores: PropTypes.object,
  pageComponentIndex: PropTypes.string,
}

ScorecardComponent.defaultProps = {
  categories: {},
  footerText: '',
  maxPossibleScores: {},
  pageComponentIndex: '0',
}

export default ScorecardComponent

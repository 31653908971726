import React from 'react'

import PropTypes from 'prop-types'

import { StyledAccordion } from './component.styles'
import { DATA_TEST_ID_PREFIX } from './constants'

const LessonAccordionComponent = ({
  children,
  title,
  pageComponentIndex,
  id,
}) => {
  const dataTestId = `${DATA_TEST_ID_PREFIX}${pageComponentIndex}-${id}`

  return (
    <StyledAccordion dataTestId={dataTestId} title={title}>
      {children}
    </StyledAccordion>
  )
}

LessonAccordionComponent.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  pageComponentIndex: PropTypes.string,
  title: PropTypes.string,
}

LessonAccordionComponent.defaultProps = {
  children: [],
  id: 'test-id',
  title: '',
  pageComponentIndex: '0',
}

export default LessonAccordionComponent

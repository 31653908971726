import React from 'react'

import { LoadingScreenWrapper, StyledInlineLoading } from './component.styles'

const LoadingScreen = () => {
  return (
    <LoadingScreenWrapper>
      <StyledInlineLoading />
    </LoadingScreenWrapper>
  )
}

export default LoadingScreen

export const HTTP_METHODS = {
  DELETE: 'DELETE',
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
}
// Non-RSAA API calls use this endpoint object. RSAA calls provide the endpoint in its metadata.
export const ENDPOINTS = {
  LMS: 'lms scorm api',
  LOCAL: 'local',
}

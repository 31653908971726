import styled from 'styled-components'

export const AudioWrapper = styled.div.attrs(({ hasMargin }) => ({
  margin: hasMargin ? '2.5rem 0' : '0',
}))`
  margin: ${({ margin }) => margin};
  width: 100%;
`

export const AudioContentWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

import React, { useState } from 'react'

import PropTypes from 'prop-types'
import { AudioDescriptionButton, TranscriptButton } from 'secondstep-components'
import { useBreakpoints } from 'utils/breakpointProp'

import {
  AudioDescTooltip,
  ToggleWrapper,
  TranscriptTooltip,
} from './component.styles'
import {
  audioToolTipText,
  audioToolTipTextNotActive,
  tooltipTextActive,
  tooltipTextNotActive,
} from './constants'

const MenuComponent = ({
  canShowAudioDescriptionButton,
  canShowTranscriptButton,
  isTranscriptActive,
  isAudioDescActive,
  showTranscript,
  handleClickAudioDesc,
  transcriptRendered,
}) => {
  const { isSm } = useBreakpoints()
  const [isAudioHovering, setIsAudioHovering] = useState(false)
  const [isHovering, setIsHovering] = useState(false)
  const tooltipText = transcriptRendered
    ? tooltipTextActive
    : tooltipTextNotActive
  const AudioDescTooltipText = isAudioDescActive
    ? audioToolTipTextNotActive
    : audioToolTipText

  return (
    <ToggleWrapper transcriptRendered={transcriptRendered}>
      {isAudioHovering && !isSm && (
        <AudioDescTooltip isAudioDescActive={isAudioDescActive}>
          {AudioDescTooltipText}
        </AudioDescTooltip>
      )}
      {!isHovering && canShowAudioDescriptionButton && (
        <AudioDescriptionButton
          ariaLabel="Audio Description button"
          hasActive={isAudioDescActive}
          hasHover={isAudioHovering}
          hasResponsive
          id="audio-description-button"
          onClick={handleClickAudioDesc}
          onMouseEnter={() => setIsAudioHovering(true)}
          onMouseLeave={() => setIsAudioHovering(false)}
          variant="sela"
        />
      )}
      {isHovering && !isSm && (
        <TranscriptTooltip>{tooltipText}</TranscriptTooltip>
      )}
      {canShowTranscriptButton && (
        <TranscriptButton
          hasActive={isTranscriptActive}
          hasHover={isHovering}
          id="transcript-button"
          onClick={showTranscript}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          variant="sela"
        />
      )}
    </ToggleWrapper>
  )
}

MenuComponent.propTypes = {
  canShowAudioDescriptionButton: PropTypes.bool,
  canShowTranscriptButton: PropTypes.bool,
  handleClickAudioDesc: PropTypes.func,
  isAudioDescActive: PropTypes.bool,
  isTranscriptActive: PropTypes.bool,
  showTranscript: PropTypes.func,
  transcript: PropTypes.string,
  transcriptRendered: PropTypes.bool,
}

export default MenuComponent

import { getTypes, default as types } from 'store/types'

const actionTypeFetching = types?.ACTION_TYPES?.FETCHING
const actionTypeUpdating = types?.ACTION_TYPES?.UPDATING
const actionTypeUpdatingWithRetries = types?.ACTION_TYPES?.UPDATING_WITH_RETRIES
const baseType = types?.BASE_TYPES?.LMS
const subType = types?.SUBTYPES?.INTERACTIVES

const interactivesFetchingTypes = getTypes(
  actionTypeFetching,
  baseType,
  subType,
)
const interactivesUpdatingTypes = getTypes(
  actionTypeUpdating,
  baseType,
  subType,
)
const interactivesUpdatingWithRetriesTypes = getTypes(
  actionTypeUpdatingWithRetries,
  baseType,
  subType,
)

export default {
  ...interactivesFetchingTypes,
  ...interactivesUpdatingTypes,
  ...interactivesUpdatingWithRetriesTypes,
}

import { getParentApiInstance } from 'utils/api'

import {
  CODE_SUCCESS,
  CREATE_API_CONNECTION,
  DEFAULT_ERROR,
  INITIAL_STATE,
  IS_STANDALONE,
} from './constants'

// The ApiConnector connects to parent window apis. If a sco id is provided, a new client will be created (or
// returned if existing). If no sco id provided, the api connector uses the default SCORM-compliant API_1484_11
// api from the parent window. isStandalone and createApiConnection default to env variable values.
const ApiConnector = (
  scoId,
  isStandalone = IS_STANDALONE,
  createApiConnection = CREATE_API_CONNECTION,
) => {
  const getInitialState = () => Object.assign({}, INITIAL_STATE)
  let state = getInitialState()

  const getOverloadedParentApi = parentApi => ({
    ...parentApi,
    apiGetValue: parentApi.GetValue,
    apiSetValue: parentApi.SetValue,
    apiTerminate: parentApi.Terminate,
    GetValue: getValue,
    SetValue: setValue,
    Terminate: terminate,
  })

  const getStandaloneApi = () => ({
    GetValue: getValue,
    SetValue: setValue,
    Terminate: terminate,
  })

  const getInstance = async () => {
    const { hasRequestedInitialization } = state || {}

    // If instance promise already exists, use that one
    if (state.instance) {
      return await state.instance // await promise
    }

    // If this is the first request for initialization, kick off initialization
    if (!hasRequestedInitialization) {
      state = {
        ...state,
        hasRequestedInitialization: true,
        instance: wrapApi(scoId),
        isStandalone,
        scoId: scoId,
      }
    }

    // Initialization is done. Does an instance exist now? Return the promise.
    if (state.instance) {
      return await state.instance // await promise
    }
  }

  const wrapApi = async () => {
    const suffix = scoId ? `sco ID ${scoId}.` : `primary microlearning.`
    const infoMessage = isStandalone
      ? `Running standalone. Using local api connection for ${suffix}`
      : `Running in iFrame. Using parent api connection for ${suffix}`
    const isPrimaryApiConnection = !scoId

    console.info(infoMessage)

    if (isStandalone) {
      const api = getStandaloneApi()

      return api
    }

    if (isPrimaryApiConnection) {
      // use the primary scorm api from parent window (SCORM-compliant API_1484_11)
      const parentApi = getParentApiInstance()

      parentApi && (await parentApi.Initialize())

      const api = parentApi
        ? getOverloadedParentApi(parentApi)
        : getStandaloneApi()

      return api
    }

    // else it is a secondary connection in non-standalone mode. Use a secondary api using
    // createApiConnection from parent window
    const options = { sco: scoId }
    const connectionApi = createApiConnection(options)

    connectionApi && (await connectionApi.Initialize())
    const api = connectionApi
      ? getOverloadedParentApi(connectionApi)
      : getStandaloneApi()

    return api
  }

  // Get the api client for the scoId.  If no scoId provided, get the default parent api
  // for the central microLearning.
  const getApiClient = async () => {
    const apiClient = await getInstance(scoId)

    return apiClient
  }

  // Check the response for errors.  If there are errors, throw them.
  const checkForErrors = (api, elem) => {
    const status = api.GetLastError && api.GetLastError()
    const diagnostics = api.GetDiagnostic && api.GetDiagnostic()
    const isErrorStatus = status !== CODE_SUCCESS

    diagnostics &&
      console.warn(
        `Diagnostics received from API when getting value ${elem} - ${diagnostics}`,
      )

    if (isErrorStatus) {
      const statusText = api.GetErrorString
        ? api.GetErrorString(status)
        : DEFAULT_ERROR.message

      console.error(
        `Error getting/setting value ${elem} - ${status}: ${statusText}`,
      )

      throw {
        status,
        statusText,
      }
    }
  }

  const getValue = async elem => {
    if (isStandalone) {
      return
    }

    const api = await getApiClient(scoId)
    let val = api.apiGetValue && (await api.apiGetValue(elem))
    val = val?.result || val

    checkForErrors(api, elem)

    // check for boolean types
    if (['true', 'false'].includes(val)) {
      return val === 'true'
    }

    // check for real, float, int types
    const asNumber = Number(val)

    if (!isNaN(asNumber) && val !== '') {
      return asNumber
    }

    // left with time, interval, string, id, or enum types

    return val
  }

  const setValue = async (elem, val) => {
    if (isStandalone) {
      return
    }

    const api = await getApiClient(scoId)
    const response = api.apiSetValue && (await api.apiSetValue(elem, val))

    checkForErrors(api, elem)

    return response
  }

  const terminate = async () => {
    const { scoId } = state || {}

    if (!isStandalone) {
      const api = await getApiClient(scoId)
      api.apiTerminate && (await api?.apiTerminate())
    }

    state = getInitialState()
  }

  return {
    getApiClient,
  }
}

export default ApiConnector

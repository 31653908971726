import {
  BodyCopy,
  ContainedButton,
  HeaderOne,
  YieldIcon,
} from 'secondstep-components'
import styled from 'styled-components'

export const ButtonsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 3.125rem 0rem;

  ${({ theme }) => theme?.breakpoints?.tablet} {
    margin: 3.125rem 0rem;
  }

  button:first-of-type {
    margin-right: 1.25rem;
  }

  button:last-of-type {
  }

  ${({ theme }) => theme?.breakpoints?.tablet} {
    flex-direction: column;

    button:first-of-type {
      margin-bottom: 1.25rem;
      margin-right: 0rem;
    }
  }
`

export const BodyCopyStyled = styled(BodyCopy)`
  color: ${({ theme }) => theme?.colors?.bodyCopy};
  font-size: 1.125rem;
  letter-spacing: 0rem;
  line-height: 1.625rem;
  margin-bottom: 1.125rem;

  ${({ theme }) => theme?.breakpoints?.tablet} {
    margin-bottom: 1rem;
  }
`
export const ErrorBodyCopy = styled(BodyCopy)`
  color: ${({ theme }) => theme?.colors?.bodyCopy};
  font-size: 1.125rem;
  line-height: 1.625rem;

  strong {
    font-family: ${({ theme }) => theme?.fonts?.errorMessage?.strong?.family};
    font-weight: ${({ theme }) => theme?.fonts?.errorMessage?.strong?.weight};
  }
`

export const Header = styled(HeaderOne)`
  color: ${({ theme }) => theme?.colors?.bodyCopy};
  font-family: ${({ theme }) => theme?.fonts?.errorMessage?.header?.family};
  font-weight: ${({ theme }) => theme?.fonts?.errorMessage?.header?.weight};
  font-size: 2.625rem;
  line-height: 3rem;
  margin: 0rem;
  text-align: center;
`

export const HeaderWrapper = styled.div`
  max-width: 41.125rem;

  ${({ theme }) => theme?.breakpoints?.tablet} {
    max-width: 30rem;
  }
`

export const StyledContainedButton = styled(ContainedButton)`
  border: 0.125rem solid
    ${({ theme }) => theme?.colors?.errorMessage?.button?.border};
  border-radius: 1.3125rem;
  background-color: ${({ theme }) =>
    theme?.colors?.errorMessage?.button?.border};
  color: ${({ theme }) => theme?.colors?.errorMessage?.button?.color};
  cursor: pointer;
  font-family: ${({ theme }) => theme?.fonts?.errorMessage?.button?.family};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme?.fonts?.errorMessage?.button?.weight};
  height: 2.5rem;
  letter-spacing: 0.03125rem;
  line-height: 1.5rem;
  padding: 0.5rem 1.75rem;
  text-align: center;
  text-transform: none;

  :hover,
  :focus {
    background-color: ${({ theme }) =>
      theme?.colors?.errorMessage?.hover?.background};
    border: 0.125rem solid
      ${({ theme }) => theme?.colors?.errorMessage?.hover?.background};
    color: ${({ theme }) => theme?.colors?.errorMessage?.hover?.color};
  }
`

export const StyledYieldIcon = styled(YieldIcon)`
  height: 4.6875rem;
  margin-bottom: 3.125rem;
  width: 5.4375rem;

  ${({ theme }) => theme?.breakpoints?.tablet} {
    margin-bottom: 2.5rem;
  }
`

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  padding: 0rem 1rem;
  text-align: center;

  svg {
    margin-bottom: 3.125rem;
    width: 5.4375rem;
    height: 4.6875rem;

    ${({ theme }) => theme?.breakpoints?.tablet} {
      margin-bottom: 2.5rem;
    }
  }
`

export const Wrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) =>
    theme?.colors?.errorMessage?.backgroundColor};
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  justify-content: center;
  width: 100vw;
`

import { H3Wrapper } from 'components/Header/component.styles'
import ModalCloseButton from 'components/ModalCloseButton'
import { Button } from 'grommet'
import { Modal, ModalContentWrapper } from 'secondstep-components'
import styled from 'styled-components'

const gutterWidths = {
  desktop: '2.5rem',
  mobile: '1.25rem',
  tablet: '2.5rem',
}

export const StyledModal = styled(Modal).attrs(() => ({
  h3MarginLeft: '-' + gutterWidths.desktop,
  h3MarginLeftMobile: '-' + gutterWidths.mobile,
  h3PaddingLeft: gutterWidths.desktop,
  h3PaddingLeftMobile: gutterWidths.mobile,
}))`
  /* react-modal uses a react portal to display the modal. Because of that, we need to define
 opacity animation here.  More info: https://stackoverflow.com/a/58356017/4257223*/

  .ReactModal__Overlay {
    z-index: 1000;
    opacity: 0;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
    transition: all 100ms ease-in;
  }
  position: relative;

  ${H3Wrapper} {
    margin-left: ${({ h3MarginLeft }) => h3MarginLeft};
    padding-left: ${({ h3PaddingLeft }) => h3PaddingLeft};

    ${({ theme }) => theme?.breakpoints?.mobile} {
      margin-left: ${({ h3MarginLeftMobile }) => h3MarginLeftMobile};
      padding-left: ${({ h3PaddingLeftMobile }) => h3PaddingLeftMobile};
    }
  }

  ${ModalContentWrapper} {
    background-color: ${({ theme }) => theme?.colors?.modal?.backgroundColor};
    box-sizing: border-box;
    max-width: 67.875rem;
    padding: 2.5rem;
    width: calc(100% - 11.25rem);
    @media only screen and (max-width: 580px) {
      padding: 2.5rem 1.25rem 2.5rem;
      width: calc(100% - 2.5rem);
    }
  }

  ${ModalCloseButton} {
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
    display: flex;
    justify-content: flex-end;
    color: ${({ theme }) => theme?.colors?.modal?.closeButton?.color};
    margin-right: 0.25rem;
    margin-top: 0.25rem;

    ${({ theme }) => theme?.breakpoints?.mobile} {
      margin-right: 0rem;
      margin-top: 0rem;
    }

    svg {
      height: 0.875rem;
      width: 0.875rem;
    }

    :focus {
      svg {
        color: ${({ theme }) => theme?.colors?.modal?.closeButton?.hoverColor};
        transition: all 0.2s ease-in-out;
      }
    }

    :hover {
      svg {
        color: ${({ theme }) => theme?.colors?.modal?.closeButton?.hoverColor};
      }
    }
  }

  .Overlay {
    background-color: ${({ theme }) =>
      theme?.colors?.modal?.overlay?.backgroundColor};
  }

  h2 {
    margin-top: 0;

    ${({ theme }) => theme?.breakpoints?.mobile} {
      margin-right: 0.75rem;
    }
  }
`

export const ModalInnerWrapper = styled.div`
  * + * {
    margin: 1.75rem 0 0;

    ${({ theme }) => theme?.breakpoints?.mobile} {
      margin: 1.25rem 0 0;
    }
  }
`

export const StyledCloseButton = styled(Button).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))``

import React, { useEffect } from 'react'

import { default as interactiveCompletedHooks } from 'hooks/useInteractiveComplete'
import { default as textResponseHooks } from 'hooks/useTextResponse'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { selectors as appSelectors } from 'store/app'
import { selectors as interactiveSelectors } from 'store/interactives'
import { textEntrySelectors } from 'store/interactives/selectors'

import TextEntryComponent from './component'
import { CONTENT_TYPE, MAXLENGTH } from './constants'

const TextEntryContainer = ({
  contentType,
  entryField,
  id,
  pageComponentIndex,
  sourceMicroLearning,
  questionText,
}) => {
  const interactiveType = interactiveSelectors.contentType({ contentType })
  const primaryMicroLearningId = useSelector(appSelectors.contentfulId)
  const interactivesCompleted =
    useSelector(appSelectors.interactivesCompleted) || {}
  const microLearningId = sourceMicroLearning || primaryMicroLearningId
  const { response, updateTextResponse } = textResponseHooks.useTextResponse(
    interactiveType,
    microLearningId,
    id,
  )
  const savedResponse = useSelector(
    state => textEntrySelectors.response(state, microLearningId, id) || '',
  )
  const {
    setInteractivesCompleted,
  } = interactiveCompletedHooks.useInteractiveComplete()
  const currentLength = response.length
  const showStatusArea = currentLength >= MAXLENGTH - 20
  const showStatusAlert = currentLength == MAXLENGTH

  useEffect(() => {
    // Prevent setInteractivesCompleted from firing on ever debounce unless interactivesCompleted value has changed
    if (interactivesCompleted[id] !== !!savedResponse) {
      setInteractivesCompleted(id, savedResponse)
    }
    if (savedResponse) {
      updateTextResponse(savedResponse)
    }
  }, [savedResponse, id])

  const onTextChange = e => {
    const inputResponse = e.target.value
    updateTextResponse(inputResponse)
  }

  const textEntryComponentProps = {
    currentLength,
    handleChange: onTextChange,
    id,
    isMultiLine: entryField,
    pageComponentIndex,
    questionText,
    response,
    showStatusAlert,
    showStatusArea,
  }

  return <TextEntryComponent {...textEntryComponentProps} />
}

TextEntryContainer.propTypes = {
  contentType: PropTypes.object,
  entryField: PropTypes.bool,
  id: PropTypes.string.isRequired,
  pageComponentIndex: PropTypes.string,
  questionText: PropTypes.string.isRequired,
  sourceMicroLearning: PropTypes.string,
}

TextEntryContainer.defaultProps = {
  contentType: CONTENT_TYPE,
}

export default TextEntryContainer

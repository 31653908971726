import { default as interactivesSelectors } from '.'

const response = ({ interactives }, microLearningId, interactiveId) =>
  interactivesSelectors.interactive(
    { interactives },
    microLearningId,
    interactiveId,
  )?.response

export default {
  response,
}

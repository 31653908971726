import { URL_PARAMS } from '../config/constants'
const EMPTY_STRING = ''

// create a url with a params string appended to the end
export const createUrlWithParams = (baseUrl, paramsObject) => {
  if (!baseUrl) {
    return EMPTY_STRING
  }

  const hasParams = paramsObject && Object.keys(paramsObject).length > 0

  if (!hasParams) {
    return EMPTY_STRING
  }

  const separator = baseUrl.includes('?') ? '&' : '?'
  const params = createParamsString(paramsObject)
  const endpoint = `${baseUrl}${separator}${params}`

  return endpoint
}

// create a params string to append to the end of a url
export const createParamsString = params => {
  if (!params) {
    return EMPTY_STRING
  }

  const paramKeys = Object.keys(params)
  const hasParams = paramKeys.length === 0

  if (hasParams) {
    return EMPTY_STRING
  }

  const encodedQueryParams = paramKeys
    .map(param => encodeQueryParam(param, params[param]))
    .join('&')

  return encodedQueryParams
}

export const encodeQueryParam = (key, value) => {
  const keyToEncode = key != null ? key : EMPTY_STRING
  const valueToEncode = value != null ? value : EMPTY_STRING
  const encodedKey = `${encodeURIComponent(keyToEncode)}`
  const encodedValue = `${encodeURIComponent(valueToEncode)}`
  const encodedQueryParam = `${encodedKey}=${encodedValue}`

  return encodedQueryParam
}

//Get the url params from the current browser location url
export const getUrlParams = (paramNamesArray = URL_PARAMS) => {
  const { search } = location || {}
  const urlParams = search && new URLSearchParams(search)
  const returnObj = {}
  const booleanParams = ['preview']

  if (!urlParams) {
    return returnObj
  }

  paramNamesArray.forEach(paramName => {
    const urlParam = urlParams.get(paramName)

    if (!urlParam) {
      return
    }

    if (booleanParams.includes(paramName)) {
      returnObj[paramName] = urlParam === 'true'
    } else {
      returnObj[paramName] = urlParam
    }
  })

  return returnObj
}

export const getNextLessonUrl = (
  currentUrl,
  currentId,
  nextId,
  isLmsDevRoute,
) => {
  const attemptIdx = 5

  const newUrl = currentUrl
    .split('/')
    .map((str, idx) => {
      if (isLmsDevRoute && idx === attemptIdx) {
        return ++str
      } else if (str.includes(currentId)) {
        return str.replace(currentId, nextId)
      } else return str
    })
    .join('/')

  return newUrl
}

import React from 'react'

import { useSelector } from 'react-redux'
import { selectors as appSelectors } from 'store/app'
import { selectors as contentfulSelectors } from 'store/contentful'

import NavHeaderComponent from './component'
import { navigateBack as navigateBackCurry } from './helper'

const NavHeaderContainer = () => {
  const microLearningId = useSelector(state => appSelectors.contentfulId(state))
  const appSavingStatus = useSelector(state =>
    appSelectors.getAppSavingStatus(state),
  )
  const appLockStatus = useSelector(appSelectors.isAppLocked)
  const moduleId = useSelector(
    state =>
      appSelectors.contentfulToScoTree(state)?.currentModule?.contentfulId,
  )
  const entry = useSelector(state =>
    contentfulSelectors.entry(state, microLearningId),
  )

  const navigateBack = navigateBackCurry(moduleId)
  const { displayTitle, label } = entry || {}

  return (
    <NavHeaderComponent
      disabled={appLockStatus}
      displayTitle={displayTitle}
      label={label}
      navigateBack={navigateBack}
      status={appSavingStatus}
    />
  )
}

export default NavHeaderContainer

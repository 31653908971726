import React from 'react'

import { useSelector } from 'react-redux'
import { selectors as appSelectors } from 'store/app'
import { LAYOUT_TYPES, MEETING_TYPES_LABELS } from 'store/app/constants'
import { selectors as cmiSelectors } from 'store/cmi'
import { COMPLETION_STATUS } from 'store/cmi/constants'
import { selectors as contentfulSelectors } from 'store/contentful/'
import { getNextLessonUrl } from 'utils/url'

import NavFooterComponent from './component'
import {
  DIRECTION_NEXT,
  DIRECTION_PREVIOUS,
  IS_CYPRESS_MOCK_LMS,
  IS_LMS_LOCAL_DEVELOPMENT,
  NAVIGATION_DIRECTION_ERROR,
  SCO_DATA,
} from './constants'

const NavFooterContainer = () => {
  const appLockStatus = useSelector(appSelectors.isAppLocked)
  const currentMicroLearningId = useSelector(appSelectors.contentfulId)
  const currentUnit = useSelector(appSelectors.currentUnit)
  const nextLesson = useSelector(appSelectors.nextLesson)
  const previousLesson = useSelector(appSelectors.previousLesson)
  const completionStatus = useSelector(state =>
    cmiSelectors.completionStatus(state, currentMicroLearningId),
  )
  const currentScoId = useSelector(appSelectors.scoId)

  const { contentfulId: currentUnitId } = currentUnit || {}
  const { contentfulId: previousContentfulId, scoID: previousScoId } =
    previousLesson || {}
  const { contentfulId: nextContentfulId, scoID: nextScoId } = nextLesson || {}

  const currentUnitContentfulEntry = useSelector(state =>
    contentfulSelectors.entry(state, currentUnitId),
  )
  const nextLessonContentfulEntry = useSelector(state =>
    contentfulSelectors.entry(state, nextContentfulId),
  )
  const previousLessonContentfulEntry = useSelector(state =>
    contentfulSelectors.entry(state, previousContentfulId),
  )

  const {
    label: nextLessonLabel,
    displayTitle: nextLessonDisplayTitle,
    microlearningType: nextLessonType,
  } = nextLessonContentfulEntry || {}
  const upNextDetails =
    nextLessonLabel &&
    nextLessonDisplayTitle &&
    `${nextLessonLabel}: ${nextLessonDisplayTitle}`
  const currentUnitTitle = currentUnitContentfulEntry?.displayTitle
  const previousLessonType = previousLessonContentfulEntry?.microlearningType
  // Handles what the button text will say. CSS handles mobile text as it changes based on breakpoints.
  const renderButtonText = (microlearningType, directionType) => {
    if (microlearningType === LAYOUT_TYPES.LEADER_GROUP_MEETING) {
      return MEETING_TYPES_LABELS.LEADER_TEAM
    } else if (microlearningType === LAYOUT_TYPES.SMALL_GROUP_MEETING) {
      return MEETING_TYPES_LABELS.SMALL_GROUP
    } else {
      return `${directionType} Microlearning`
    }
  }

  const navigateToLesson = direction => {
    let currentWindow = parent?.window
    let currentUrl
    let upcomingContentfulId
    let upcomingScoId
    let nextUrl

    // Set upcomingId to navigate to based on direction
    if (direction === DIRECTION_NEXT) {
      upcomingContentfulId = nextContentfulId
      upcomingScoId = nextScoId
    } else if (direction === DIRECTION_PREVIOUS) {
      upcomingContentfulId = previousContentfulId
      upcomingScoId = previousScoId
    } else {
      console.error(NAVIGATION_DIRECTION_ERROR)
      return
    }

    // Pass in scoId or contentfulId based on whether or not you're in the local lms dev route
    if (IS_LMS_LOCAL_DEVELOPMENT) {
      currentUrl = currentWindow.location?.href
      nextUrl = getNextLessonUrl(
        currentUrl,
        currentMicroLearningId,
        upcomingContentfulId,
        IS_LMS_LOCAL_DEVELOPMENT,
      )
    }
    // Cypress mock runs in standalone mode with the exception that the scoData and SCORM API object are injected
    // into the parent window to trick the player into thinking it was launched via localhost LMS. That's why the window
    // context is the player's, so you don't change the Cypress test window url.
    else if (IS_CYPRESS_MOCK_LMS) {
      currentWindow = window || {}
      currentUrl = currentWindow.location?.href
      nextUrl = getNextLessonUrl(
        currentUrl,
        currentMicroLearningId,
        upcomingContentfulId,
        IS_LMS_LOCAL_DEVELOPMENT,
      )
    } else {
      currentUrl = currentWindow.location?.href
      nextUrl = getNextLessonUrl(
        currentUrl,
        currentScoId,
        upcomingScoId,
        IS_LMS_LOCAL_DEVELOPMENT,
      )
    }

    currentWindow.open(nextUrl, '_self')
  }

  const isPreviousLesson = !!previousLesson
  const isNextLesson = !!nextLesson
  const isLessonComplete = completionStatus === COMPLETION_STATUS.COMPLETED
  const navFooterProps = {
    currentUnitTitle,
    disabled: appLockStatus,
    isLessonComplete,
    isNextLesson,
    isPreviousLesson,
    navigateToLesson,
    nextLessonType,
    previousLessonType,
    renderButtonText,
    upNextDetails,
  }
  return SCO_DATA ? <NavFooterComponent {...navFooterProps} /> : null
}

export default NavFooterContainer

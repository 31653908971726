import React, { useEffect } from 'react'

import { default as interactiveCompletedHooks } from 'hooks/useInteractiveComplete'
import { default as selectHooks } from 'hooks/useSelect'
import { default as textResponseHooks } from 'hooks/useTextResponse'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { selectors as appSelectors } from 'store/app'
import { selectors as interactiveSelectors } from 'store/interactives'
import { singleSelectSelectors } from 'store/interactives/selectors'

import SingleSelectQuestion from './component'
import { CONTENT_TYPE } from './constants'
import { getFormattedAnswers } from './data'

const SingleSelectQuestionContainer = ({
  answerChoices,
  contentType,
  id,
  pageComponentIndex,
  questionText,
  sourceMicroLearning,
}) => {
  const interactiveType = interactiveSelectors.contentType({ contentType })
  const primaryMicroLearningId = useSelector(appSelectors.contentfulId)
  const microLearningId = sourceMicroLearning || primaryMicroLearningId
  const interactivesCompleted =
    useSelector(appSelectors.interactivesCompleted) || {}
  const isCompleted = interactivesCompleted[id]
  const selections = useSelector(state =>
    singleSelectSelectors.selections(state, microLearningId, id),
  )
  const selection = selections?.[0] || ''
  const savedResponse = useSelector(
    state => singleSelectSelectors.response(state, microLearningId, id) || '',
  )
  const { updateSelections } = selectHooks.useSelect(
    interactiveType,
    microLearningId,
    id,
  )
  const { response, updateTextResponse } = textResponseHooks.useTextResponse(
    interactiveType,
    microLearningId,
    id,
  )
  const {
    setInteractivesCompleted,
  } = interactiveCompletedHooks.useInteractiveComplete()

  useEffect(() => {
    setInteractivesCompleted(id, selection)
  }, [])

  useEffect(() => {
    if (savedResponse) {
      updateTextResponse(savedResponse)
    }
  }, [savedResponse])

  const onCheck = (e, answerValue) => {
    const newSelection = [e.target.value]

    updateSelections(newSelection, answerValue)
    // SingleSelect can't be unchecked so once it's completed, it can't ever be uncompleted so no reason to dispatch again
    !isCompleted && setInteractivesCompleted(id, newSelection)
  }

  const onTextChange = e => {
    const inputResponse = e.target.value
    updateTextResponse(inputResponse)
  }

  const formattedAnswers = getFormattedAnswers(answerChoices)

  return (
    <SingleSelectQuestion
      answers={formattedAnswers}
      handleChange={onTextChange}
      handleCheck={onCheck}
      id={id}
      pageComponentIndex={pageComponentIndex}
      questionText={questionText}
      response={response}
      selectedAnswer={selection}
    />
  )
}

SingleSelectQuestionContainer.propTypes = {
  answerChoices: PropTypes.array,
  contentType: PropTypes.object,
  id: PropTypes.string,
  pageComponentIndex: PropTypes.string,
  questionText: PropTypes.string,
  sourceMicroLearning: PropTypes.string,
}

SingleSelectQuestionContainer.defaultProps = {
  answerChoices: [],
  contentType: CONTENT_TYPE,
}

export default SingleSelectQuestionContainer

import { ENDPOINTS, HTTP_METHODS } from 'lib/api/constants'
import { DEFAULT_ERROR } from 'lib/api/lms/constants'
import LmsConnectionsManager from 'lib/LmsConnectionsManager/LmsConnectionsManager'
import {
  actionChannel,
  all,
  call,
  cancel,
  cancelled,
  delay,
  fork,
  put,
  select,
  take,
} from 'redux-saga/effects'
import { createDiagnostic, createErrorFromMessage } from 'utils/api'

import { sagas as cmiSagas } from './cmi'
import { sagas as lmsSagas } from './lms'
import { default as baseTypes } from './types'

// This saga uses an actionChannel to queue all api setValue calls.
// This ensures API calls don't stomp on each other and potentially overwrite newer values
// with older values.
function* apiSetValueAsyncQueue() {
  const apiSetValueRequestChannel = yield actionChannel(
    baseTypes.SET_VALUE_QUEUE,
  )
  let task
  while (true) {
    const {
      data,
      key,
      microLearningEntryId,
      onError,
      onRetry,
      onSuccess,
    } = yield take(apiSetValueRequestChannel)

    if (task) {
      yield cancel(task) // cancel is no-op if the task has already terminated
    }

    task = yield fork(
      handleSetValueRetries,
      { data, key, microLearningEntryId, onError, onRetry, onSuccess },
      { delayTime: 500, maxRetries: 5 },
    )
  }
}

// retries setValue with exp backoff
function* handleSetValueRetries(
  { key, data, microLearningEntryId, onRetry, onError, onSuccess },
  { maxRetries, delayTime },
) {
  const appState = yield select()
  const api = LmsConnectionsManager.getApiConnection(
    microLearningEntryId,
    appState,
  )

  for (let attempt = 0; attempt < maxRetries; attempt++) {
    try {
      if (yield cancelled()) {
        break
      }

      const response = yield call(api.setValue, key, data)
      const { diagnostic } = response || {}
      const { error: responseError } = diagnostic || {}
      const isSuccessful = !responseError

      if (isSuccessful) {
        yield put(onSuccess(diagnostic))
        break
      }

      if (attempt < maxRetries - 1) {
        // Notify to store we are retrying
        yield put(onRetry())
        yield delay(delayTime * maxRetries * attempt)
        continue
      }

      // it's done retrying so notify the error
      yield put(onError(diagnostic))

      break
    } catch (errorMessage) {
      if (attempt < maxRetries - 1) {
        // Notify to store we are retrying
        yield put(onRetry())
        yield delay(delayTime * maxRetries * attempt)
        continue
      }

      // it's done retrying so notify the error
      const error = createErrorFromMessage(errorMessage, DEFAULT_ERROR)
      const diagnostic = createDiagnostic(
        ENDPOINTS.LMS,
        HTTP_METHODS.GET,
        error,
      )

      yield put(onError(diagnostic))
    }
  }
}

export default function* rootSaga() {
  yield all([call(lmsSagas), call(cmiSagas), apiSetValueAsyncQueue()])
}
